import { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';

//MRT Imports
import { MaterialReactTable, useMaterialReactTable, MRT_GlobalFilterTextField, MRT_ShowHideColumnsButton } from 'material-react-table';

//Material UI Imports
import { Box, ListItemIcon, MenuItem, Button, lighten, TextField, Dialog, DialogContent, Autocomplete, DialogTitle, Checkbox } from '@mui/material';

//Icons Imports
import { AccountCircle, Gavel } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive'; // for some reason, importing from above errors
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import MergeIcon from '@mui/icons-material/Merge';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
//Date Picker Imports - these should just be in your Context Provider
import { evaluateSubmissionsWithGPT, mergeCandidates } from '../HiringUtils/api';
import { useHandleSubmissionChange, handleArchiveSubmissions, highlightText } from '../HiringUtils/helperFunctions';
import { statusMap } from '../HiringUtils/StatusConstants';
import Toast from '../../Layout/Toast';

import { useQueryClient } from '@tanstack/react-query';
import CandidateDetailPanel from '../HiringCandidatePanel/CandidateDetailPanel';

const HiringTable = ({ data }) => {
  const [toast, setToast] = useState(null);
  const [open, setOpen] = useState(false);
  const [primaryCandidate, setPrimaryCandidate] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const [multiUpdateOpen, setMultiUpdateOpen] = useState(false);
  const [statusForMultiUpdate, setStatusForMultiUpdate] = useState('');
  const [currentTable, setCurrentTable] = useState(null);

  const handlePrimaryCandidateChange = (event, newValue) => {
    const newPrimaryCandidate = newValue ? newValue : null;
    setPrimaryCandidate(newPrimaryCandidate);
  };

  const handleCandidatesChange = (event, newValue) => {
    setSelectedCandidates(newValue); // Save selected candidates to state
  };

  const handleMergeCandidates = async () => {
    const primaryCandidateId = primaryCandidate?.id;
    const duplicateCandidateIds = selectedCandidates.reduce((ids, candidate) => {
      ids.push(candidate.id);
      return ids;
    }, []);


    await mergeCandidates(primaryCandidateId, duplicateCandidateIds);
    setOpen(false);
    setPrimaryCandidate(null);
    setSelectedCandidates([]);
    setToast({
      title: "Merge Candidates",
      message: "Candidates have been merged",
      isSuccess: true,
    });
  };

  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.data.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );

  // Update the state whenever data.data changes.
  useEffect(() => {
    setValue(
      data.data.reduce((acc, row) => {
        acc[row.id] = row || '';
        return acc;
      }, {}),
    );
  }, [data.data]);


  // Convert the value object to an array for the table.
  const tableData = useMemo(() => Object.values(value), [value]);
  const handleSubmissionChange = useHandleSubmissionChange(setValue);


  const handleMultipleCandidateStatusChange = async (rows) => {
    rows.map(async (row) => {
      const submissionId = row.original.id;
      await handleSubmissionChange(
        {
          submissionId: submissionId,
          newInfo: { status: statusForMultiUpdate },
          row,
        },
      );
    });

    await currentTable.resetRowSelection();
    setStatusForMultiUpdate('');
    setCurrentTable(null);
    setMultiUpdateOpen(false);
    setToast({
      title: 'Success!',
      message: 'Candidate status(es) updated.',
      isSuccess: true,
    });
  };

  const handleGPTEvaluation = async () => {
    setToast({
      title: 'GPT analysis is starting...',
      message: 'Please wait for GPT analysis to finish',
      isSuccess: true,
    });

    let result = await evaluateSubmissionsWithGPT();

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success
      ? `Submission(s) have been processed by chatGPT and statuses have been updated`
      : 'Something went wrong! Unable to process submission(s).';

    setToast({
      title: title,
      message: message,
      isSuccess: success,
    });

    await queryClient.refetchQueries({ queryKey: ['combinedData'] });
  };


  const columns = useMemo(
    () => [
      {
        id: 'candidates', //id used to define `group` column
        header: 'Candidates',

        columns: [
          // ============================ CANDIDATE NAME COLUMN ============================
          {
            accessorFn: (row) => {
              const fullName = `${row.candidate.firstName} ${row.candidate.lastName}`;

              return `${fullName}`.trim();
            },
            id: 'name',
            header: 'Candidate Name',
            enableGlobalFilter: true,
            Cell: ({ cell, table }) => {
              const nameString = cell.getValue();
              const globalFilter = table.getState().globalFilter;
              const submissionCount = cell.row.original.candidate.submissionCount ? `(${cell.row.original.candidate.submissionCount})` : '';

              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{highlightText(`${nameString} ${submissionCount}`, globalFilter)}</span>
                </Box>
              );

            },
          },
          // ============================ CANDIDATE EMAIL COLUMN ============================
          {
            accessorFn: (row) => row?.candidate.email,
            id: 'email',
            header: 'Candidate Email',
            size: 100,
            Cell: ({ cell, table }) => {
              const email = cell.getValue();
              const globalFilter = table.getState().globalFilter;

              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{highlightText(email, globalFilter)}</span>
                </Box>
              );

            },
          },
          // ============================ JOB SOURCE COLUMN ============================
          {
            accessorFn: (row) => row?.jobSourceName,

            id: 'jobSource', //id is still required when using accessorFn instead of accessorKey
            header: 'Job Source',
            size: 100,
            Cell: ({ cell, table }) => {
              const jobSourceString = cell.getValue();
              const globalFilter = table.getState().globalFilter;

              return (

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{highlightText(jobSourceString, globalFilter)}</span>
                </Box>
              );
            },
          },
          // ============================ DATE SUBMITTED COLUMN ============================
          {
            accessorFn: (row) => row.dateSubmitted ? dayjs(row.dateSubmitted) : null,
            id: 'dateSubmitted',
            header: 'Date Submitted',
            filterVariant: 'date-range',

            // Use a custom sorting function or one of MRT's built-ins (e.g. 'datetime')
            sortingFn: (rowA, rowB, columnId) => {
              const dateA = rowA.getValue(columnId)?.valueOf() ?? 0;
              const dateB = rowB.getValue(columnId)?.valueOf() ?? 0;
              return dateA - dateB;
            },

            // Render the highlight
            Cell: ({ cell, table }) => {
              // The raw cell value is a Dayjs object (or null)
              const dayjsVal = cell.getValue();
              const dateString = dayjsVal ? dayjsVal.format('MM/DD/YYYY') : '';

              // Grab the user’s typed search from the global filter
              const globalFilter = table.getState().globalFilter;
              return (
                <span>
                  {highlightText(String(dateString), globalFilter)}
                </span>
              );
            },
          },
          // ============================ CANDIDATE LOCATION COLUMN ============================
          {
            accessorFn: (row) => row.candidate.location,
            id: 'locationId',
            header: 'Location',
            size: 100,
            Cell: ({ cell }) => {
              const globalFilter = table.getState().globalFilter;

              const location = cell.getValue();
              return (

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>{highlightText(location, globalFilter)}</span>
                </Box>
              );
            },
          },
          // ============================ CANDIDATE STATUS COLUMN ============================
          {
            accessorFn: (row) => {
              const status = statusMap.find((s) => s.value === row.status);
              return status.label;
            },
            id: 'statusId',
            header: 'Status',
            size: 100,

            Cell: ({ row }) => {
              const submissionId = row.original.id;
              const status = row.original.status;
              const matchedStatus = statusMap.find((s) => s.value === status);


              return (
                <>
                  <select
                    id="status"
                    name="status"
                    style={{
                      backgroundColor: matchedStatus?.color || 'blue',
                      color: matchedStatus?.textColor || 'white',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px 10px',
                      width: '125px',
                      overflow: 'clip',
                      fontSize: '14px',
                    }}
                    value={status}
                    onChange={(e) => handleSubmissionChange(
                      {
                        submissionId: submissionId,
                        newInfo: { status: e.target.value },
                        row,
                      })
                    }
                  >
                    {statusMap.map((status) => (
                      <option value={status.value} key={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                </>
              );
            },
          },
        ],
      },
    ],
    [tableData, handleSubmissionChange],
  );

  const table = useMaterialReactTable({
    //@ts-ignore
    columns,
    defaultColumn: {
      minSize: 20,
      maxSize: 20,
      size: 10,
    },
    enableHiding: true,
    // data: data.data,
    data: tableData,
    columnFilterDisplayMode: 'popover',
    enableColumnFilters: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    autoResetPageIndex: false,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      columnFilters: [],

      columnVisibility: { removedFromSandbox: false },
      sorting: [{ id: 'dateSubmitted', desc: false }],
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        overflowX: 'auto',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        padding: '0 8px', // Reduce padding between columns in header cells
      },
    },

    renderBottomToolbarCustomActions: () => {
      const filteredOptions = data.allCandidates.filter(
        (candidate) => primaryCandidate?.id !== candidate.id,
      );

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          <Dialog open={open} onClose={() => { setOpen(false); setPrimaryCandidate(null); setSelectedCandidates([]); }}>
            <DialogTitle>Merge Candidates</DialogTitle>
            <DialogContent >
              <div className='flex flex-col justify-center align-center' style={{ height: '100%' }}>
                <Autocomplete
                  onChange={handlePrimaryCandidateChange}
                  value={primaryCandidate}
                  options={data.allCandidates} // Filter out already selected candidates
                  getOptionLabel={(option) => option && option.firstName && option.lastName
                    ? `${option.firstName} ${option.lastName} -  ID:${option.id}`
                    : ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {/* {console.log(option.firstName, option, "HERE")} */}
                      {`${option.firstName} ${option.lastName} -  ID:${option.id}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Primary Candidate" />
                  )}
                  sx={{ width: 500, marginBottom: '1rem', marginTop: '1rem' }}
                />

                <Autocomplete
                  multiple
                  onChange={handleCandidatesChange}
                  value={selectedCandidates}
                  disableCloseOnSelect
                  options={filteredOptions}
                  getOptionLabel={(option) => option && option.firstName && option.lastName
                    ? `${option.firstName} ${option.lastName} -  ID:${option.id}`
                    : ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        style={{ marginRight: 8 }}
                      />
                      {`${option.firstName} ${option.lastName} -  ID:${option.id}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Candidates to Merge" />
                  )}
                  sx={{ width: 500, marginBottom: '1rem' }}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => handleMergeCandidates()}>Merge Candidates</Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog open={multiUpdateOpen} onClose={() => { setMultiUpdateOpen(false); setStatusForMultiUpdate(''); }}>
            <DialogTitle>Update Selected Candidate Status</DialogTitle>
            <DialogContent >
              <h3>Current selected candidates:</h3>
              {table.getSelectedRowModel().rows.map((row, idx) => {

                return (
                  <p key={idx}>{row.original.candidate.firstName} {row.original.candidate.lastName}</p>
                );
              })}


              <select
                id="status"
                name="status"
                style={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  width: '100%',
                  overflow: 'clip',
                  fontSize: '14px',
                  marginTop: '2rem',
                }}
                value={statusForMultiUpdate}
                onChange={(e) => setStatusForMultiUpdate(e.target.value)}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => handleMultipleCandidateStatusChange(table.getSelectedRowModel().rows)}>Confirm update</Button>
              </div>


            </DialogContent>
          </Dialog>
        </Box >
      );
    },

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    muiTableBodyCellProps: () => ({
      sx: {
        cursor: 'pointer',
        padding: '6px', // Reduce padding between columns in body cells
        width: '50px',
      },
    }),
    renderDetailPanel: ({ row }) => <CandidateDetailPanel row={row} data={data} showTab={true} />,

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          table.setEditingRow(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Edit Submission
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            alignContent: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: "35%", justifyContent: 'space-between' }}>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>

                  {/* ==================HEADER SEARCH BAR================== */}
                  <MRT_GlobalFilterTextField table={table} />

                  {/* ===============HEADER HIDE COLS BUTTON=============== */}
                  <MRT_ShowHideColumnsButton table={table} />

                  <button
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}>
                    < MergeIcon
                      titleAccess='Merge candidates'
                      style={{
                        color: 'gray',
                      }} />
                  </button>
                  <button
                    disabled={!table.getSelectedRowModel().rows}
                    style={{
                      cursor: table.getSelectedRowModel().rows ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      handleArchiveSubmissions(table.getSelectedRowModel().rows, table, setToast, queryClient);
                    }}
                  >
                    <ArchiveIcon
                      style={{
                        color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                      }}
                    />
                  </button>

                  <button
                    disabled={!table.getSelectedRowModel().rows}
                    style={{

                      cursor: table.getSelectedRowModel().rows ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      setMultiUpdateOpen(true);
                      setCurrentTable(table);
                    }}>
                    < EditAttributesIcon
                      titleAccess='Edit status'
                      style={{
                        color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                        marginTop: '2px',
                        marginLeft: '3px',
                        fontSize: '36px',
                      }} />
                  </button>
                </div>
              </Box>
            </div>
          </Box >
          <div>
            <button
              style={{
                cursor: "pointer",
                marginRight: 40,
              }}
              onClick={() => {
                handleGPTEvaluation();
              }}
            >
              <Gavel
                titleAccess='Evaluate submissions with GPT'
                style={{
                  color: 'gray',
                }}
              />
            </button>

          </div>
        </Box >
      );
    },
    // ================================== FOOTER ==================================
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <Toast {...toast} show={toast !== null} onDone={() => setToast(null)} />
    </>

  );
};

export default HiringTable;
