import { useState } from 'react';
import { Blocker, MilestoneBlockerCategory } from '../types';
import { Box, IconButton } from '@mui/material';
import { Content, Header, Inner, StyledDrawer, SubDrawerBtn, Title } from './subComponents';
import { ArrowBack } from '@mui/icons-material';
import { MsBlockerUpdateForm } from './MsBlockerUpdateForm';
import MsBlockerActivityLogsDrawer from './MsBlockerActivityLogs';
import { milestoneBlockerCategoryMap } from './utils';

export default function MsBlockerUpdateDrawer({ milestoneBlocker, closeParents, initialIsOpen = false }: { milestoneBlocker: Blocker; closeParents: () => void; initialIsOpen?: boolean }) {
  // Set to open if it is the current milestone
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [isPreview, setIsPreview] = useState(true);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
    setIsPreview(true);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  const buttonTitle = milestoneBlocker.title ?? milestoneBlockerCategoryMap[milestoneBlocker.milestoneBlockerCategory as MilestoneBlockerCategory];

  return (
    <Box>
      <SubDrawerBtn onClick={toggleSelf} text={buttonTitle} color="warning" />
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Title text={"Update Milestone Blocker"} />
            <MsBlockerActivityLogsDrawer milestoneBlocker={milestoneBlocker} closeParents={closeAll} />
          </Header>
          <Content>
            <MsBlockerUpdateForm milestoneBlocker={milestoneBlocker} isPreview={isPreview} setIsPreview={setIsPreview} />
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
