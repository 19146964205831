import type { InvestigationTask } from '../types/investigation';

const now = Date.now();

export const sampleInvestigations: InvestigationTask[] = [
  {
    id: '1',
    status: 'in_progress',
    suiteType: 'scheduled',
    priority: 'urgent',
    application: {
      name: 'Figma',
      environment: 'Staging',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 69,
      investigated: 31,
    },
    assignee: {
      id: 'current-user-id',
      name: 'Current User',
      avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=current-user-id',
      onlineStatus: 'green',
    },
    participants: [
      {
        id: '1',
        name: 'Lorena Sanchez',
        avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Lorena',
      },
    ],
    references: [
      {
        id: '1',
        type: 'suite',
        name: 'Staging Hourly',
        url: '/suites/staging-hourly',
      },
    ],
    timestamps: {
      created: new Date(now - 3 * 24 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 30 * 60 * 1000).toISOString(),
      firstClaimed: new Date(now - 2 * 24 * 60 * 60 * 1000).toISOString(),
      oldestFailure: new Date(now - 4 * 24 * 60 * 60 * 1000).toISOString(),
    },
    overriddenSuites: 3,
    internalResources: [
      {
        id: '1',
        type: 'conversation',
        title: 'Previous Login Issue',
        link: '/conversations/previous-login-issue',
      },
      {
        id: '2',
        type: 'workflow',
        title: 'Login Process',
        link: '/workflows/login-process',
      },
    ],
    teamInfo: {
      qaManager: 'Jordan Sitinas',
      qaLead: 'Brandon Shin',
      csm: 'Alannah Easby',
    },
    issues: [
      {
        id: 'historic-issue-1',
        title: 'Historic Login Failure',
        description: 'Users experienced login failures in a previous suite run.',
        status: 'open',
        type: 'historic-failure',
        suiteInfo: {
          name: 'Staging Daily (Previous)',
          link: '/suites/staging-daily-previous',
        },
        oldestFailureAge: '15 days',
        workflows: [
          {
            id: 'hw1',
            name: 'Historic Login Workflow',
            status: 'failed',
            failureLink: 'https://example.com/failure/hw1',
            startTime: new Date(now - 15 * 24 * 60 * 60 * 1000).toISOString(),
          },
          {
            id: 'hw2',
            name: 'Historic User Profile Workflow',
            status: 'failed',
            failureLink: 'https://example.com/failure/hw2',
            startTime: new Date(now - 14 * 24 * 60 * 60 * 1000).toISOString(),
          },
        ],
      },
      {
        id: 'issue1',
        title: 'Login Failure on Staging',
        description: 'Users are unable to log in to the staging environment due to an authentication error.',
        status: 'open',
        type: 'historic-failure',
        workflows: [
          {
            id: 'wf1',
            name: 'Login Workflow',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf1',
            startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf2',
            name: 'Data Fetch Workflow',
            status: 'running',
            failureLink: 'https://example.com/failure/wf2',
            startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf3',
            name: 'User Profile Workflow',
            status: 'passed',
            failureLink: 'https://example.com/failure/wf3',
            startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf4',
            name: 'Password Reset Workflow',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf4',
            startTime: new Date(Date.now() - 20 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf5',
            name: 'Session Management Workflow',
            status: 'running',
            failureLink: 'https://example.com/failure/wf5',
            startTime: new Date(Date.now() - 10 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf16',
            name: 'Login Bug Reproduction',
            status: 'bug',
            failureLink: 'https://example.com/bug/wf16',
            startTime: new Date(now - 40 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf17',
            name: 'Password Reset Maintenance',
            status: 'maintenance',
            failureLink: 'https://example.com/maintenance/wf17',
            startTime: new Date(now - 35 * 60 * 1000).toISOString(),
          },
        ],
      },
      {
        id: 'issue2',
        title: 'Slow Page Load Times',
        description: 'Users are experiencing slow page load times across the application.',
        status: 'open',
        type: 'historic-failure',
        workflows: [
          {
            id: 'wf18',
            name: 'Data Fetch Workflow',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf18',
            startTime: new Date(now - 30 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf19',
            name: 'User Profile Bug',
            status: 'bug',
            failureLink: 'https://example.com/bug/wf19',
            startTime: new Date(now - 25 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf20',
            name: 'Login Workflow',
            status: 'running',
            startTime: new Date(now - 20 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf21',
            name: 'Notification System Maintenance',
            status: 'maintenance',
            failureLink: 'https://example.com/maintenance/wf21',
            startTime: new Date(now - 15 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf22',
            name: 'Search Functionality',
            status: 'passed',
            startTime: new Date(now - 10 * 60 * 1000).toISOString(),
          },
        ],
      },
      {
        id: 'issue3',
        title: 'Broken Image Upload',
        description: 'Image upload functionality is not working as expected.',
        status: 'resolved',
        type: 'historic-failure',
        workflows: [
          {
            id: 'wf11',
            name: 'Image Upload Workflow',
            status: 'passed',
            failureLink: 'https://example.com/failure/wf11',
            startTime: new Date(Date.now() - 2 * 60 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf12',
            name: 'Image Processing Workflow',
            status: 'passed',
            failureLink: 'https://example.com/failure/wf12',
            startTime: new Date(Date.now() - 110 * 60 * 1000).toISOString(),
          },
        ],
      },
      {
        id: 'issue4',
        title: 'Bug Reproduction: Login Failure',
        description: 'Reproduce the login failure bug across multiple environments.',
        status: 'open',
        type: 'bug',
        workflows: [
          {
            id: 'wf13',
            name: 'Login Bug Reproduction - Staging',
            status: 'bug',
            failureLink: 'https://example.com/bug/wf13',
            startTime: new Date(Date.now() - 60 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf14',
            name: 'Login Bug Reproduction - Production',
            status: 'bug',
            failureLink: 'https://example.com/bug/wf14',
            startTime: new Date(Date.now() - 55 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf15',
            name: 'Login Bug Reproduction - Development',
            status: 'bug',
            failureLink: 'https://example.com/bug/wf15',
            startTime: new Date(Date.now() - 50 * 60 * 1000).toISOString(),
          },
        ],
      },
      {
        id: 'issue5',
        title: 'Failed Cleanup',
        description: 'Cleanup processes failed after test execution, potentially affecting subsequent test runs.',
        status: 'open',
        type: 'clean up failure',
        workflows: [
          {
            id: 'wf23',
            name: 'Database Cleanup',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf23',
            startTime: new Date(now - 55 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf24',
            name: 'File System Cleanup',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf24',
            startTime: new Date(now - 50 * 60 * 1000).toISOString(),
          },
          {
            id: 'wf25',
            name: 'Cache Invalidation',
            status: 'failed',
            failureLink: 'https://example.com/failure/wf25',
            startTime: new Date(now - 45 * 60 * 1000).toISOString(),
          },
        ],
      },
    ],
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        failureLink: 'https://example.com/failure/wf2',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        failureLink: 'https://example.com/failure/wf3',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '2',
    status: 'new',
    suiteType: 'scheduled',
    priority: 'urgent',
    application: {
      name: 'Figma',
      environment: 'Production',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 28,
      investigated: 0,
    },
    assignee: null,
    participants: [],
    references: [
      {
        id: '2',
        type: 'suite',
        name: 'Production Daily',
        url: '/suites/production-daily',
      },
    ],
    timestamps: {
      created: new Date(now - 16 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 16 * 60 * 60 * 1000).toISOString(),
      firstClaimed: undefined,
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Jordan Sitinas',
      qaLead: 'Brandon Shin',
      csm: 'Alannah Easby',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf18',
        name: 'Data Fetch Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf18',
        startTime: new Date(now - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf19',
        name: 'User Profile Bug',
        status: 'bug',
        failureLink: 'https://example.com/bug/wf19',
        startTime: new Date(now - 25 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf20',
        name: 'Login Workflow',
        status: 'running',
        startTime: new Date(now - 20 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf21',
        name: 'Notification System Maintenance',
        status: 'maintenance',
        failureLink: 'https://example.com/maintenance/wf21',
        startTime: new Date(now - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf22',
        name: 'Search Functionality',
        status: 'passed',
        startTime: new Date(now - 10 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '3',
    status: 'in_progress',
    suiteType: 'deployment',
    priority: 'urgent',
    application: {
      name: 'Cycode',
      environment: 'Production',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 15,
      investigated: 5,
    },
    assignee: {
      id: 'other-user-id',
      name: 'Jane Doe',
      avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=other-user-id',
      onlineStatus: 'green',
    },
    participants: [
      {
        id: '2',
        name: 'Jane Doe',
        avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Jane',
      },
    ],
    references: [
      {
        id: '3',
        type: 'suite',
        name: 'Production Deployment',
        url: '/suites/production-deployment',
      },
    ],
    timestamps: {
      created: new Date(now - 30 * 60 * 1000).toISOString(),
      updated: new Date(now - 15 * 60 * 1000).toISOString(),
      firstClaimed: new Date(now - 25 * 60 * 1000).toISOString(),
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Samuel Lalli',
      qaLead: 'Jarrod',
      csm: 'Michael Chang',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  // New tickets
  {
    id: '4',
    status: 'in_progress',
    suiteType: 'scheduled',
    priority: 'urgent',
    application: {
      name: 'Figma',
      environment: 'Production',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 50,
      investigated: 10,
    },
    assignee: {
      id: 'user-3',
      name: 'Alice Johnson',
      avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Alice',
      onlineStatus: 'green',
    },
    participants: [],
    references: [
      {
        id: '4',
        type: 'suite',
        name: 'Production Weekly',
        url: '/suites/production-weekly',
      },
    ],
    timestamps: {
      created: new Date(now - 2 * 24 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 12 * 60 * 60 * 1000).toISOString(),
      firstClaimed: new Date(now - 36 * 60 * 60 * 1000).toISOString(),
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Jordan Sitinas',
      qaLead: 'Brandon Shin',
      csm: 'Alannah Easby',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '5',
    status: 'new',
    suiteType: 'manual',
    priority: 'high',
    application: {
      name: 'Cycode',
      environment: 'Staging',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 20,
      investigated: 0,
    },
    assignee: null,
    participants: [],
    references: [
      {
        id: '5',
        type: 'suite',
        name: 'Staging Manual',
        url: '/suites/staging-manual',
      },
    ],
    timestamps: {
      created: new Date(now - 8 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 8 * 60 * 60 * 1000).toISOString(),
      firstClaimed: undefined,
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Samuel Lalli',
      qaLead: 'Jarrod',
      csm: 'Michael Chang',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '6',
    status: 'in_progress',
    suiteType: 'scheduled',
    priority: 'medium',
    application: {
      name: 'Figma',
      environment: 'Development',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 35,
      investigated: 15,
    },
    assignee: {
      id: 'user-4',
      name: 'Bob Smith',
      avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Bob',
      onlineStatus: 'green',
    },
    participants: [],
    references: [
      {
        id: '6',
        type: 'suite',
        name: 'Development Daily',
        url: '/suites/development-daily',
      },
    ],
    timestamps: {
      created: new Date(now - 5 * 24 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 2 * 24 * 60 * 60 * 1000).toISOString(),
      firstClaimed: new Date(now - 4 * 24 * 60 * 60 * 1000).toISOString(),
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Jordan Sitinas',
      qaLead: 'Brandon Shin',
      csm: 'Alannah Easby',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '7',
    status: 'new',
    suiteType: 'deployment',
    priority: 'low',
    application: {
      name: 'Cycode',
      environment: 'Development',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 5,
      investigated: 0,
    },
    assignee: null,
    participants: [],
    references: [
      {
        id: '7',
        type: 'suite',
        name: 'Development Deployment',
        url: '/suites/development-deployment',
      },
    ],
    timestamps: {
      created: new Date(now - 4 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 4 * 60 * 60 * 1000).toISOString(),
      firstClaimed: undefined,
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Samuel Lalli',
      qaLead: 'Jarrod',
      csm: 'Michael Chang',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
  {
    id: '8',
    status: 'in_progress',
    suiteType: 'scheduled',
    priority: 'high',
    application: {
      name: 'Figma',
      environment: 'Staging',
      icon: 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2KtT5VH0Ggf30EM5znsHz719j68maM.png',
    },
    failures: {
      total: 42,
      investigated: 22,
    },
    assignee: {
      id: 'current-user-id',
      name: 'Current User',
      avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=current-user-id',
      onlineStatus: 'green',
    },
    participants: [],
    references: [
      {
        id: '8',
        type: 'suite',
        name: 'Staging Daily',
        url: '/suites/staging-daily',
      },
    ],
    timestamps: {
      created: new Date(now - 7 * 24 * 60 * 60 * 1000).toISOString(),
      updated: new Date(now - 6 * 60 * 60 * 1000).toISOString(),
      firstClaimed: new Date(now - 6 * 24 * 60 * 60 * 1000).toISOString(),
    },
    internalResources: [],
    teamInfo: {
      qaManager: 'Jordan Sitinas',
      qaLead: 'Brandon Shin',
      csm: 'Alannah Easby',
    },
    summary: 'This is a sample summary of the issue.',
    workflows: [
      {
        id: 'wf1',
        name: 'Login Workflow',
        status: 'failed',
        failureLink: 'https://example.com/failure/wf1',
        startTime: new Date(Date.now() - 30 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf2',
        name: 'Data Fetch Workflow',
        status: 'running',
        startTime: new Date(Date.now() - 15 * 60 * 1000).toISOString(),
      },
      {
        id: 'wf3',
        name: 'Logout Workflow',
        status: 'passed',
        startTime: new Date(Date.now() - 45 * 60 * 1000).toISOString(),
      },
    ],
  },
];
