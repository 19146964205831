import { UtilizationEvents } from '../timeUtilizationTypes';
import { formatTime } from '../timeUtlilzationHelpers';

export default function UtilizationTable({ events }: { events: UtilizationEvents }) {
  const eventDates = events?.slicesByDateAndCustomer
    ?.map((slice) => slice.date)
    .sort((a, b) => {
      const [monthA, dayA, yearA] = a.split('-').map(Number);
      const [monthB, dayB, yearB] = b.split('-').map(Number);
      return new Date(yearA + 2000, monthA - 1, dayA).getTime() - new Date(yearB + 2000, monthB - 1, dayB).getTime();
    });

  // Process data into table rows
  const processData = (events: UtilizationEvents) => {
    const customers: { [key: string]: { total: number; [key: string]: number } } = {};
    const dates: string[] = [];
    const totalPerDay: { [key: string]: string } = {};

    // Aggregate customer data and collect dates
    events?.slicesByDateAndCustomer?.forEach((event) => {
      dates.push(event.date);
      totalPerDay[event.date] = event.totalTime;

      Object.entries(event.slicesByCustomer).forEach((entry) => {
        const [customer, slice] = entry as [string, { durationInMs: number }];
        if (!customers[customer]) {
          customers[customer] = { total: 0 };
        }
        customers[customer][event.date] = slice.durationInMs;
        customers[customer].total += slice.durationInMs;
      });
    });

    return { customers, totalPerDay };
  };

  const { customers, totalPerDay } = processData(events);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full pt-2 align-middle">
              <table className="min-w-full border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Customer
                    </th>
                    {eventDates?.map((date, idx) => (
                      <th
                        key={idx}
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                      >
                        {date}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Total time spent per customer
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(customers)
                    .sort((a, b) => a[0].localeCompare(b[0])) // Sort customers alphabetically
                    .map(([customer, durations]) => (
                      <tr key={customer}>
                        <td className="border-b border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {customer}
                        </td>
                        {eventDates?.map((date) => (
                          <td key={date} className="border-b border-gray-200 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {durations[date] ? formatTime(durations[date]) : '0'}
                          </td>
                        ))}
                        <td className="border-b border-gray-200 whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatTime(durations.total)}</td>
                      </tr>
                    ))}
                  <tr className="bg-white rounded-b-md sticky bottom-0 z-1 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                    <td className="border-t border-gray-200 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                      Total time spent per day
                    </td>
                    {eventDates?.map((date) => (
                      <td className="border-t border-gray-200 whitespace-nowrap px-3 py-4 text-sm text-gray-900" key={date}>
                        {totalPerDay[date]}
                      </td>
                    ))}
                    <td className="border-t border-gray-200 whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {formatTime(Object.values(customers).reduce((sum, { total }) => sum + total, 0))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
