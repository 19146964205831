import type React from 'react';
import { createContext, useContext, useState } from 'react';
import type { InvestigationTask, InternalResource } from '../types/investigation';
import { sampleInvestigations } from '../data/sampleData';

type CommunicationsContextType = {
  investigations: InvestigationTask[];
  setInvestigations: React.Dispatch<React.SetStateAction<InvestigationTask[]>>;
  addInternalResource: (investigationId: string, resource: InternalResource) => void;
  claimInvestigation: (investigationId: string, userId: string) => void;
  unclaimInvestigation: (investigationId: string) => void;
  updateInvestigation: (updatedInvestigation: InvestigationTask) => void;
};

const CommunicationsContext = createContext<CommunicationsContextType | undefined>(undefined);

export function CommunicationsProvider({ children }: { children: React.ReactNode }) {
  const [investigations, setInvestigations] = useState<InvestigationTask[]>(sampleInvestigations);

  const addInternalResource = (investigationId: string, resource: InternalResource) => {
    setInvestigations((prevInvestigations) =>
      prevInvestigations.map((inv) => {
        if (inv.id === investigationId) {
          return {
            ...inv,
            internalResources: [...inv.internalResources, resource],
          };
        }
        return inv;
      }),
    );
  };

  const claimInvestigation = (investigationId: string, userId: string) => {
    setInvestigations((prevInvestigations) =>
      prevInvestigations.map((inv) => {
        if (inv.id === investigationId) {
          return {
            ...inv,
            status: 'in_progress',
            assignee: {
              id: userId,
              name: 'Current User', // This should be replaced with actual user data
              avatar: `https://api.dicebear.com/6.x/avataaars/svg?seed=${userId}`,
              onlineStatus: 'green' as const,
            },
          };
        }
        return inv;
      }),
    );
  };

  const unclaimInvestigation = (investigationId: string) => {
    setInvestigations((prevInvestigations) =>
      prevInvestigations.map((inv) => {
        if (inv.id === investigationId) {
          const { /* assignee, */ ...rest } = inv;
          return {
            ...rest,
            status: 'new',
            assignee: null,
          };
        }
        return inv;
      }),
    );
  };

  const updateInvestigation = (updatedInvestigation: InvestigationTask) => {
    setInvestigations((prevInvestigations) =>
      prevInvestigations.map((inv) =>
        inv.id === updatedInvestigation.id ? { ...updatedInvestigation, issues: updatedInvestigation.issues || [] } : inv,
      ),
    );
  };

  return (
    <CommunicationsContext.Provider
      value={{
        investigations,
        setInvestigations,
        addInternalResource,
        claimInvestigation,
        unclaimInvestigation,
        updateInvestigation,
      }}
    >
      {children}
    </CommunicationsContext.Provider>
  );
}

export function useCommunications() {
  const context = useContext(CommunicationsContext);
  if (context === undefined) {
    throw new Error('useCommunications must be used within a CommunicationsProvider');
  }
  return context;
}
