export const layout = {
  name: 'cose-bilkent',
  // directed: true,
  // rankDir: 'LR',
  padding: 10,
  spacingFactor: 2,
  // rankSep: 150,
  nodeSep: 150,
  animate: true,
  animationDuration: 500,
  fit: true,
  edgeSep: 150,
} as const;
