import type React from 'react';
import { createContext, useContext, useState, useCallback } from 'react';
import type { Workflow, WorkflowStatus } from '../types/investigation';

type WorkflowContextType = {
  workflows: Record<string, Workflow>;
  updateWorkflowStatus: (workflowId: string, newStatus: WorkflowStatus, originalWorkflow?: Workflow) => void;
  retryWorkflow: (workflowId: string) => void;
  retryAllFailedWorkflows: (issueId: string) => void;
  overrideBaseline: (workflowId: string) => void;
};

const WorkflowContext = createContext<WorkflowContextType | undefined>(undefined);

export const WorkflowProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [workflows, setWorkflows] = useState<Record<string, Workflow>>({});

  const updateWorkflowStatus = useCallback((workflowId: string, newStatus: WorkflowStatus, originalWorkflow?: Workflow) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: {
        ...(originalWorkflow || prevWorkflows[workflowId]),
        status: newStatus,
      },
    }));
  }, []);

  const retryWorkflow = useCallback((workflowId: string) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: { ...prevWorkflows[workflowId], status: 'queued', startTime: new Date().toISOString() },
    }));
  }, []);

  const retryAllFailedWorkflows = useCallback((issueId: string) => {
    setWorkflows((prevWorkflows) => {
      console.log(prevWorkflows, issueId);
      return prevWorkflows;
      // const updatedWorkflows = { ...prevWorkflows };
      // Object.keys(updatedWorkflows).forEach((workflowId) => {
      //   if (updatedWorkflows[workflowId].status === "failed") {
      //     updatedWorkflows[workflowId] = {
      //       ...updatedWorkflows[workflowId],
      //       status: "queued",
      //       startTime: new Date().toISOString(),
      //     };
      //   }
      // });
      // return updatedWorkflows;
    });
  }, []);

  const overrideBaseline = useCallback((workflowId: string) => {
    setWorkflows((prevWorkflows) => ({
      ...prevWorkflows,
      [workflowId]: { ...prevWorkflows[workflowId], baselineOverride: true },
    }));
  }, []);

  return (
    <WorkflowContext.Provider value={{ workflows, updateWorkflowStatus, retryWorkflow, retryAllFailedWorkflows, overrideBaseline }}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = () => {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }
  return context;
};
