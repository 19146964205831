import { useRef, useEffect } from 'react';
// import { Button } from '@/components/ui/button';
// import { Textarea } from '@/components/ui/textarea';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Conversation, Message, Thread } from '../data/types';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
//  TODO: Comment back in when feature is ready
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { AlertCircle, MessagesSquare } from 'lucide-react';
import { formatDate } from '../utils/date';
// import { generateSlackUrl } from '../utils/slackUrlGenerator';
import WolfLoader from '@/components/WolfLoader/WolfLoader';
import { SlateEditor } from './SlateEditor';
import { marked } from 'marked';
import { useCommunications } from '@/components/context/CommunicationsContext';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { SiSlack } from '@icons-pack/react-simple-icons';
import { generateSlackUrl } from '../utils/slackUrlGenerator';

type MessageFeedProps = {
  conversation: Conversation;
  // TODO: Comment back in when feature is ready
  // onPinThread: (threadId: string | null) => void;
  // pinnedThread: string | null;
};

export function MessageFeed({ conversation /*onPinThread, pinnedThread */ }: MessageFeedProps) {
  const { isLoading, conversationsLoading, isTabLoading } = useCommunications();

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const filteredMessages = conversation.messages;

  // All items in conversation
  const allItems = [...filteredMessages, ...(conversation.internalNotes || [])].sort(
    (a, b) => new Date(Number(a.timestamp.replace('.', ''))).getTime() - new Date(Number(b.timestamp.replace('.', ''))).getTime(),
  );

  useEffect(() => {
    scrollToBottom();
  }, [conversation.messages, conversation.internalNotes]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const getThreadColor = (threadId: string) => {
    const thread = conversation.threads.find((t: Thread) => t.id === threadId);
    return thread ? thread.color : '#cccccc'; // Default to a light gray if thread not found
  };

  const getInitials = (name: string) => {
    if (name && name.includes(' ')) {
      return name
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase();
    } else if (name && !name.includes(' ')) {
      return name.charAt(0).toUpperCase();
    } else {
      return 'loading...';
    }
  };

  const getAvatarUrl = (user: string) => {
    return `https://api.dicebear.com/6.x/avataaars/svg?seed=${encodeURIComponent(user)}&backgroundColor=b6e3f4,c0aede,d1d4f9`;
  };

  useEffect(() => {
    scrollToBottom();
  }, [allItems]);

  return (
    <div className="flex flex-col h-full bg-gray-50">
      {isLoading.messages || conversationsLoading || isTabLoading ? (
        <div className="flex items-center justify-center h-full">
          <WolfLoader />
        </div>
      ) : (
        <>
          <ScrollArea ref={scrollAreaRef} className="flex-1 p-4 overflow-y-auto" style={{ height: 'calc(100vh - 200px)', maxWidth: '100%' }}>
            <>
              {allItems.map((item) => {
                if ('sender' in item) {
                  // This is a regular message
                  const message = item as Message;
                  const threadColor = getThreadColor(message.thread);
                  const isInternal = message.sender === 'internal';
                  //  TODO: Comment back in when feature is ready
                  // const isExternal = message.sender === 'external';
                  const isSystem = message.sender === 'system';
                  const uniqueId = `${message.timestamp}-${Math.random().toString(36).substring(7)}`;

                  if (isSystem) {
                    return (
                      <div key={`${message.timestamp}-${Math.random().toString(36).substring(7)}`} className="flex items-center justify-center my-4">
                        <div className="bg-gray-100 text-gray-700 px-4 py-2 rounded-full flex items-center max-w-[85%]">
                          <AlertCircle className="w-4 h-4 mr-2 text-blue-500" />
                          <ParsedMessage message={message} />
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={uniqueId}
                      className="flex items-start mb-4 ${isInternal ? 'justify-end' : 'justify-start'} w-full relative"
                      data-thread-id={message.thread}
                    >
                      <div
                        className={`max-w-[85%] p-3 pr-8 rounded-lg shadow-sm relative group ${
                          isInternal ? 'bg-blue-100 text-blue-900 ml-auto' : 'bg-white text-gray-800'
                        }`}
                        style={{ borderLeft: `4px solid ${threadColor}` }}
                      >
                        <div className="flex items-center mb-1 space-x-2">
                          <Avatar className="w-6 h-6 flex-shrink-0">
                            <AvatarImage src={message?.avatar || getAvatarUrl(message?.user)} alt={message?.avatar || message?.user} />
                            <AvatarFallback>{getInitials(message?.user)}</AvatarFallback>
                          </Avatar>
                          <span className="font-semibold text-sm text-gray-700 truncate max-w-[150px]">{message.user}</span>
                          <span className="text-xs text-gray-500 whitespace-nowrap">{formatDate(message.timestamp)}</span>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <button
                                className="rounded-md hover:text-gray-600 transition-opacity"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
                              </button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end" className="w-[160px]">
                              <DropdownMenuItem className="cursor-pointer" onClick={() => window.open(generateSlackUrl(message), '_blank')}>
                                {conversation?.platform === 'slack' && <SiSlack className="mr-2 h-4 w-4" />}
                                {conversation?.platform === 'teams' && <MessagesSquare className="mr-2 h-4 w-4" />}
                                Open in {conversation?.platform.charAt(0).toUpperCase() + conversation?.platform.slice(1)}
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                        <ParsedMessage message={message} />
                      </div>
                    </div>
                  );
                }
                // TODO: Comment back in when feature is ready
                //  else {
                //   // This is an internal note
                //   const note = item as InternalNote;
                //   return (
                //     <div key={note.id} className="flex items-start mb-4 justify-end w-full">
                //       <div
                //         className="max-w-[85%] p-3 rounded-lg shadow-sm bg-yellow-100 text-yellow-800"
                //         style={{ borderRight: '4px solid #FFA000' }}
                //       >
                //         <div className="flex items-center mb-1 space-x-2">
                //           <Avatar className="w-6 h-6 flex-shrink-0">
                //             <AvatarImage src={getAvatarUrl(note.user)} alt={note.user} />
                //             <AvatarFallback>{getInitials(note.user)}</AvatarFallback>
                //           </Avatar>
                //           <span className="font-semibold text-sm text-yellow-800 truncate max-w-[150px]">{note.user}</span>
                //           <span className="text-xs text-yellow-600 whitespace-nowrap">{formatDate(note.timestamp)}</span>
                //           <Badge variant="outline" className="text-xs bg-yellow-50 text-yellow-600 border-yellow-200">
                //             Internal Note
                //           </Badge>
                //         </div>
                //         <p className="text-sm font-semibold mb-1">{note.title}</p>
                //         <p className="text-sm break-words">{note.content}</p>
                //       </div>
                //     </div>
                //   );
                // }
              })}
            </>
          </ScrollArea>
        </>
      )}
      {conversation?.platform === 'slack' && (
        <div className="p-4 border-t border-gray-200 bg-white">
          <SlateEditor conversation={conversation} />
          <div className="flex justify-end items-center space-x-2">
            {/* <Select value={selectedThreadId || undefined} onValueChange={handleThreadChange}>
            // TODO: Comment back in when feature is ready
              <SelectTrigger className="w-[250px] flex items-center">
                {selectedThreadId && (
                  <div className="flex items-center w-full">
                    <div
                      className="w-6 h-6 rounded-full mr-2 border border-gray-300 flex-shrink-0"
                      style={{ backgroundColor: getThreadColor(selectedThreadId) }}
                    ></div>
                    <span className="truncate">{selectedThreadId}</span>
                  </div>
                )}
                {!selectedThreadId && <SelectValue placeholder="Select thread" />}
              </SelectTrigger>
              <SelectContent>
                {conversation.threads.map((thread) => (
                  <SelectItem key={thread.id} value={String(thread.id)}>
                    <div className="flex items-center w-full">
                      <div className="w-6 h-6 rounded-full mr-2 border border-gray-300 flex-shrink-0" style={{ backgroundColor: thread.color }}></div>
                      <span className="truncate">{thread.id}</span>
                      {pinnedThread === String(thread.id) && <Pin className="w-4 h-4 ml-2 text-blue-500 flex-shrink-0" />}
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select> */}
          </div>
        </div>
      )}
    </div>
  );
}

function ParsedMessage({ message }: { message: Message }) {
  const generateContent = (message: Message) => {
    const processMarkdown = (text: string) => {
      // Temporarily replace @ mentions and Slack links to prevent markdown parsing
      const mentions: string[] = [];
      const slackLinks: string[] = [];

      // Replace @ mentions with placeholders - updated regex for new format
      text = text.replace(/<@([^>]+)>/g, (match) => {
        mentions.push(match);
        return `@@MENTION${mentions.length - 1}@@`;
      });

      // Replace Slack links with placeholders
      text = text.replace(/<([^|>]+)\|([^>]+)>/g, (match) => {
        slackLinks.push(match);
        return `@@SLACKLINK${slackLinks.length - 1}@@`;
      });

      // Process markdown
      let htmlContent = marked(text, { breaks: true });

      // Restore @ mentions with bold formatting
      mentions.forEach((mention, i) => {
        const username = mention.match(/<@([^>]+)>/)?.[1] || '';
        htmlContent = htmlContent.replace(`@@MENTION${i}@@`, `<strong>@${username}</strong>`);
      });

      // Restore Slack links
      slackLinks.forEach((link, i) => {
        const url = link.match(/<([^|>]+)\|([^>]+)>/)?.[1] || '';
        const text = link.match(/<([^|>]+)\|([^>]+)>/)?.[2] || '';
        htmlContent = htmlContent.replace(
          `@@SLACKLINK${i}@@`,
          `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-600 hover:underline">${text}</a>`,
        );
      });

      return htmlContent;
    };

    const processedContent = processMarkdown(message.messageContent);
    return <span className="text-sm markdown-content" dangerouslySetInnerHTML={{ __html: processedContent }} />;
  };

  return generateContent(message);
}
