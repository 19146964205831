import dayjs from 'dayjs';
import { plural } from '@/components/InvestigationBoard/helpers';
import { QAWTask } from '@/types';
import { BlockerListItemType, SortOption, TaskWithBlocker } from '../types';

export function getUniqueBlockersFromTasks(tasks: QAWTask[]) {
  const blockerMap: Record<number, BlockerListItemType> = {};
  const childTasks = tasks.flatMap((task) => task.childTasks || []);
  const childTasksWithBlockers = childTasks?.filter((task) => task.status === 'blocked' && task.blocker) as TaskWithBlocker[];

  childTasksWithBlockers.forEach((task) => {
    const mappedBlocker = blockerMap[task.blocker.id];
    const taskTestCount = task.workflow?.stepCount || 0;

    if (!mappedBlocker) {
      blockerMap[task.blocker.id] = {
        ...task.blocker,
        taskIds: [task.id],
        taskType: task.type as NonNullable<QAWTask['type']>,
        affectedTestCount: +taskTestCount,
        ageInDays: dayjs().diff(dayjs(task.blocker.createdAt), 'day'),
        daysUntilFollowUp: Math.floor(dayjs(task.blocker.nextFollowUpDate).diff(dayjs(), 'day', true)),
      };
    } else {
      mappedBlocker.taskIds.push(task.id);
      mappedBlocker.affectedTestCount += +taskTestCount;
    }
  });

  return Object.values(blockerMap);
}

export function getSortedBlockers(blockers: BlockerListItemType[], sortValue: SortOption, direction: 'asc' | 'desc') {
  return [...blockers].sort((a, b) => {
    let comparison = 0;
    switch (sortValue) {
      case 'creatorName':
        comparison = a.creator.name.localeCompare(b.creator.name);
        break;
      case 'testCount':
        comparison = a.affectedTestCount - b.affectedTestCount;
        break;
      case 'taskCount':
        comparison = a.taskIds.length - b.taskIds.length;
        break;
      case 'age':
        comparison = dayjs(b.createdAt).diff(dayjs(a.createdAt), 'day');
        break;
      case 'nextFollowUpDate':
        // Handle null values
        if (!a.nextFollowUpDate && !b.nextFollowUpDate) comparison = 0;
        if (!a.nextFollowUpDate) comparison = -1;
        if (!b.nextFollowUpDate) comparison = 1;

        if (a.nextFollowUpDate && b.nextFollowUpDate) comparison = dayjs(a.nextFollowUpDate).diff(dayjs(b.nextFollowUpDate), 'day');
        break;
    }
    return direction === 'asc' ? comparison : -comparison;
  });
}

export function getFilteredBlockers(blockers: BlockerListItemType[], typeFilters: string[], assigneeFilters: string[]) {
  return blockers.filter((blocker) => typeFilters.includes(blocker.taskType) && assigneeFilters.includes(blocker.creator.qawId));
}

export function getBlockerLabels(blocker: BlockerListItemType) {
  const [firstName, lastName] = blocker.creator.name.split(' ');
  const shortName = `${firstName} ${lastName[0]}.`;

  const followUpDaysAgoStr =
    blocker.daysUntilFollowUp === 0
      ? 'Due today'
      : !blocker.daysUntilFollowUp
      ? 'No follow up date'
      : blocker.daysUntilFollowUp > 0
      ? `Due in ${blocker.daysUntilFollowUp} day${plural(blocker.daysUntilFollowUp)}`
      : `Due ${Math.abs(blocker.daysUntilFollowUp)} day${plural(Math.abs(blocker.daysUntilFollowUp))} ago`;

  return {
    taskType: blocker.taskType === 'testCreation' ? 'Creation' : 'Maintenance',
    testCount: `${blocker.affectedTestCount} test${plural(blocker.affectedTestCount)}`,
    taskCount: `${blocker.taskIds.length} task${plural(blocker.taskIds.length)}`,
    assignee: shortName,
    age: `Age: ${blocker.ageInDays} day${plural(blocker.ageInDays)}`,
    followUpStatus: `${followUpDaysAgoStr}`,
  };
}

export function getChipColor(value: number, greenRange: number[], yellowRange: number[]) {
  if (value >= greenRange[0] && value <= greenRange[1]) return 'success';
  if (value >= yellowRange[0] && value <= yellowRange[1]) return 'warning';
  return 'error';
}
