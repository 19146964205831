import type React from "react";
import { useState, useEffect } from "react";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp, ExternalLink, Check, CheckCircle } from "lucide-react";
import type { Workflow } from "../types/investigation";

interface FailedCleanupCardProps {
  title: string
  description: string
  workflows: Workflow[]
  onResolve: () => void
  isResolved: boolean
}

export const FailedCleanupCard: React.FC<FailedCleanupCardProps> = ({
  title,
  description,
  workflows,
  onResolve,
  isResolved,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [reviewedWorkflows, setReviewedWorkflows] = useState<Set<string>>(new Set());
  const [allReviewed, setAllReviewed] = useState(isResolved);
  const [isPulsing, setIsPulsing] = useState(false);
  const [isResolving, setIsResolving] = useState(false);

  useEffect(() => {
    if (isResolved) {
      setAllReviewed(true);
      setReviewedWorkflows(new Set(workflows.map((wf) => wf.id)));
    }
  }, [isResolved, workflows]);

  useEffect(() => {
    if (reviewedWorkflows.size === workflows.length && !allReviewed) {
      setAllReviewed(true);
      setIsResolving(true);
      setIsPulsing(true);
      setTimeout(() => {
        setIsPulsing(false);
        onResolve();
      }, 2000);
    }
  }, [reviewedWorkflows, workflows, allReviewed, onResolve]);

  const handleReview = (workflowId: string) => {
    setReviewedWorkflows((prev) => {
      const newSet = new Set(prev);
      newSet.add(workflowId);
      return newSet;
    });
  };

  return (
    <Card className={`mb-2 ${isPulsing ? "animate-pulse" : ""}`}>
      <CardHeader className="py-3 px-4">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm font-medium flex items-center">
            {(isResolving || isPulsing || allReviewed) && <CheckCircle className="w-4 h-4 text-green-500 mr-2" />}
            {title}
          </CardTitle>
          <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)} className="h-6 px-1">
            {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </Button>
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent className="py-1 px-3">
          <p className="text-xs text-gray-600 mb-1">{description}</p>
          <div className="space-y-0.5">
            {workflows.map((workflow) => (
              <div key={workflow.id} className="flex items-center justify-between py-0.5 text-xs">
                <div className="flex items-center space-x-1.5">
                  <div className="rounded-full p-0.5 bg-orange-200">
                    <ExternalLink className="w-3 h-3 text-gray-800" />
                  </div>
                  <span className="font-medium truncate max-w-[120px]">{workflow.name}</span>
                  <a href={workflow.failureLink} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                    <ExternalLink size={10} />
                  </a>
                </div>
                {reviewedWorkflows.has(workflow.id) ? (
                  <div className="rounded-full p-1 bg-green-500">
                    <Check className="w-3 h-3 text-white" />
                  </div>
                ) : (
                  <Button
                    variant="outline"
                    size="sm"
                    className="h-5 px-1 py-0 text-xs"
                    onClick={() => handleReview(workflow.id)}
                  >
                    Reviewed
                  </Button>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

