import React, { createContext, useContext, useEffect, useState } from 'react';
import { Customer, Conversation, Message, QATeam, SavedViewState } from '../MessageHQ/data/types';
import { useQueries, useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { sendGetRequest, sendPostRequest } from '@/utils/network';
import { useSocket } from './SocketContext';
import QAWTask from '@/types';
import { CommunicationsContextType, MutationContext, ToastData, TQueryFnData, SlackSocketData } from './CommunicationsContextTypes';

const CommunicationsContext = createContext<CommunicationsContextType | undefined>(undefined);

export function CommunicationsProvider({ children }: { children: React.ReactNode }) {
  // =====================
  // === Initialize ===
  // =====================
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  // =====================
  // === Local Storage ===
  // =====================
  const currentUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : null;
  const secureValue = currentUser?.secure;
  const savedViewState: SavedViewState | null = localStorage.getItem('savedViewState')
    ? JSON.parse(localStorage.getItem('savedViewState') as string)
    : null;
  const defaultTeamId = currentUser?.teamId && (currentUser?.isQAE || currentUser?.isLead) ? currentUser?.teamId : 14;

  // =====================
  // === React States ===
  // =====================
  const [selectedQaTeamId, setSelectedQaTeamId] = useState<number | null>(savedViewState?.qaTeamId ? savedViewState?.qaTeamId : defaultTeamId); // Select alpacas as default first team
  const [selectAllCustomers, setSelectAllCustomers] = useState<boolean>(() => {
    if (selectedQaTeamId && savedViewState?.customerId?.[selectedQaTeamId]?.customerId) {
      return false; // If there's a specific customer saved, don't select all
    } else {
      return true; // Default to All Customers
    }
  });
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(() => {
    if (selectedQaTeamId && savedViewState?.customerId?.[selectedQaTeamId]?.customerId) {
      return savedViewState.customerId[selectedQaTeamId].customerId;
    } else {
      return null; // Default to null when All Customers is selected
    }
  });
  const [selectedConversationId, setSelectedConversationId] = useState<number | null | undefined>(null);
  const [toast, setToast] = useState<ToastData | null>(null);

  // Loading states
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [isTabLoading, setIsTabLoading] = useState(false);

  // =====================
  // === Query Hooks ===
  // =====================
  const [customers, qaTeams] = useQueries({
    queries: [
      {
        queryKey: ['customers', selectedQaTeamId],
        queryFn: () => sendGetRequest('/client-teams/get-clients-for-message-hq'),
        enabled: !!currentUser, // Only fetch data when logged in
      },
      {
        queryKey: ['qaTeamsMessageHQ'],
        queryFn: () => sendGetRequest('/client-teams/get-teams'),
        enabled: !!currentUser,
      },
    ],
  });

  // Set up refetch interval for messages
  const messages = useQuery({
    queryKey: ['get-messages-by-qa-team', selectedQaTeamId],
    queryFn: () =>
      sendPostRequest('/client-messages/get-messages-by-qa-team', {
        qaTeamId: selectedQaTeamId,
      }),
    enabled: !!currentUser,
    refetchInterval: 1000 * 60 * 3, // 3 minutes
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  // =====================
  // === Mutations =======
  // =====================
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const { mutate: sendSlackMessageMutation } = useMutation({
    mutationFn: async (message: Message) => {
      if (!sendingInProgress) {
        setSendingInProgress(true);
        return await sendPostRequest('/client-messages/send-slack-message', message);
      }
      return Promise.reject(new Error('Message sending in progress'));
    },
    onMutate: async (outboundMessage) => {
      if (!sendingInProgress) {
        // Snapshot the previous value
        const previousMessages = queryClient.getQueryData(['get-messages-by-qa-team', selectedQaTeamId]);

        if (secureValue && outboundMessage.messageContent !== '') {
          // Optimistically update the cache if there is a secure value in local storage
          queryClient.setQueryData(['get-messages-by-qa-team', selectedQaTeamId], (oldData: TQueryFnData | undefined) => {
            if (oldData) {
              const newData = {
                ...oldData,
                data: [outboundMessage, ...(oldData?.data || [])],
              };
              return newData;
            } else {
              messages.refetch();
            }
          });
          return { previousMessages } as MutationContext;
        }
      } else {
        setToast({
          title: 'Slow down there buckaroo!',
          message: 'You are sending messages too quickly. Please refresh the page to ensure records are accurate.',
          show: true,
          isSuccess: false,
          onDone: () => {
            setToast(null);
          },
          content: <></>,
        });
      }
    },
    onSettled: () => {
      setSendingInProgress(false);
    },
  });

  type TaskMutationResponse = {
    success: boolean;
    data: { task: QAWTask };
    jsonData: { task: QAWTask };
    error?: unknown;
  };

  type TaskMutationVariables = {
    endpoint: string;
    task: QAWTask;
  };

  const [isPending, setIsPending] = useState<{ [key: string]: { [key: string]: boolean } }>({});

  const { mutate: taskMutation } = useMutation<TaskMutationResponse, Error, TaskMutationVariables>({
    mutationFn: async ({ endpoint, task }) => {
      setIsPending((prev) => ({ ...prev, [String(task.id)]: { [endpoint]: true } }));
      const response = await sendPostRequest(endpoint, { task, taskId: task?.id });
      if (!response?.success) {
        console.error(response?.error as string);
      }
      return response as TaskMutationResponse;
    },
    onSettled: async (data, error, { endpoint }) => {
      try {
        await messages.refetch();
        if (error) {
          console.error(error.message);
        }
      } finally {
        if (data?.jsonData?.task?.id) {
          setIsPending((prev) => ({ ...prev, [String(data.jsonData.task.id)]: { [endpoint]: false } }));
        }
      }
    },
  });

  const allowedActions = ['/done', '/claim', '/unclaim', '/done'];

  function suiteAction(task: QAWTask, action: string) {
    if (!allowedActions.includes(action)) {
      throw new Error(`Action ${action} is not allowed. Allowed actions: ${allowedActions.join(',')}`);
    }
    taskMutation({ endpoint: action, task });
  }

  // ==========================================
  // === Query Data Manipulation/Retrieval ===
  // ==========================================

  // Structure conversations from messages
  const structureConversations = (messages: Message[]) => {
    const allConversations = messages.reduce((acc, message) => {
      const { platform, customerId, channelName, thread } = message;

      const getConversationId = () => {
        return platform === 'slack' ? Number(thread) : message.channelId;
      };
      const conversationId = getConversationId();
      const conversation = acc.find((conv) => conv.id === conversationId);

      if (conversation) {
        conversation.messages.push(message);
      } else {
        acc.push({
          id: conversationId,
          messages: [message],
          customerId,
          channelName,
          isInternal: false,
          completed: false,
          title: '',
          description: '',
          threads: [{ id: thread, color: '', completed: false }],
          summary: '',
          claimedStatus: '',
          participants: [],
          internalResources: [],
          internalNotes: [],
          platform,
        });
      }
      return acc;
    }, [] as Conversation[]);

    // Determine whether converstions are completed based on message attached message task status
    allConversations.forEach((conv: Conversation) => {
      const allMessages = conv.messages;

      const messageTaskMap = new Map();

      allMessages.forEach((msg: Message) => {
        if (msg.messageTask) {
          messageTaskMap.set(msg.messageTask.status, [...(messageTaskMap.get(msg.messageTask.status) || []), msg]);
        } else {
          messageTaskMap.set('noMessageTask', [...(messageTaskMap.get('noMessageTask') || []), msg]);
        }
      });

      const messageTasksDone = messageTaskMap.get('done')?.length;
      const messageTasksIgnore = messageTaskMap.get('ignore')?.length;
      const noMessageTasks = messageTaskMap.get('noMessageTask')?.length;
      const messageTasksInProgress = messageTaskMap.get('inProgress')?.length;
      const messageTasksToDo = messageTaskMap.get('toDo')?.length;

      // If message tasks are done, the conversation is completed
      if (messageTasksDone && !messageTasksInProgress && !messageTasksToDo) {
        conv.completed = true;
        // Handle conversations with no message tasks (some messages have no message tasks)
      } else if (noMessageTasks && !messageTasksInProgress && !messageTasksToDo && !messageTasksDone) {
        conv.completed = true;
        // Handle conversations where all messages have been ignored
      } else if (messageTasksIgnore && !messageTasksInProgress && !messageTasksToDo) {
        conv.completed = true;
      } else {
        // If there are message tasks with status inProgress or toDo, the conversation is not completed
        conv.completed = false;
      }

      // Determine claimed status
      const mostRecentMessageExternal = allMessages.find(
        (msg: Message) => msg.sender === 'external' && msg.messageTask && msg.messageTask?.assignedTo?.id,
      );

      if (mostRecentMessageExternal?.messageTask && mostRecentMessageExternal.messageTask.assignedTo?.id) {
        conv.claimedStatus = `${mostRecentMessageExternal.messageTask.assignedTo?.name}`;
      } else {
        conv.claimedStatus = '';
      }
    });
    return allConversations;
  };

  const conversations = structureConversations(messages.data?.data || []);
  const filteredCustomers = customers.data?.data?.filter((customer: Customer) => customer?.qaTeam?.id === selectedQaTeamId);
  const filteredQaTeams = qaTeams.data?.data?.filter((qaTeam: QATeam) => qaTeam?.active || qaTeam?.id === 11);
  if (filteredQaTeams?.length && filteredQaTeams[0]?.id !== 11) {
    filteredQaTeams.push({
      id: 11,
      name: 'Test Team',
      imageUrl: 'https://cdn-icons-png.flaticon.com/512/3524/3524335.png',
    });
  }

  const getConversationsForCustomer = (customerId: number) => {
    return conversations.filter((conv) => conv.customerId === customerId);
  };

  const getConversation = (conversationId: number) => {
    return conversations.find((conv) => conv.id === conversationId);
  };

  const getCustomerById = (customerId: number) => {
    const foundCustomer = customers.data?.data?.find((customer: Customer) => customer.id === customerId);
    return foundCustomer;
  };

  const getQaTeamById = (qaTeamId: number) => {
    const foundQaTeam = qaTeams.data?.data?.find((qaTeam: QATeam) => qaTeam.id === qaTeamId);
    return foundQaTeam;
  };

  // =====================
  // === Socket Handler ===
  // =====================
  const handleSlackMessage = async (socketData: SlackSocketData) => {
    // Only updates messages of selected QA team customers
    if (socketData.qaTeamId === selectedQaTeamId) {
      // First, check if we already have the data
      const existingData: TQueryFnData | undefined = queryClient.getQueryData(['get-messages-by-qa-team', selectedQaTeamId]);

      if (existingData) {
        const latestMessageInExistingData = structureConversations(existingData?.data || [])[0]?.messages[0];

        // Determine if the message is an outbound duplicate based on last message in the current conversation to avoid duplicate message from optimistic update
        const determineIfMessageIsOutboundDuplicate = () => {
          if (latestMessageInExistingData) {
            return (
              latestMessageInExistingData.channelId === Number(socketData?.channelId) &&
              latestMessageInExistingData.user === socketData?.user &&
              latestMessageInExistingData.messageContent === socketData?.messageContent &&
              latestMessageInExistingData.platform === socketData?.platform &&
              latestMessageInExistingData.sender === socketData?.sender
            );
          }
          // Return false if no existing messages
          return false;
        };

        // If the message is not the outbound message, update the query data and we didn't refetch messages. This allows optimistic sending of messages
        if (!determineIfMessageIsOutboundDuplicate()) {
          queryClient.setQueryData(['get-messages-by-qa-team', selectedQaTeamId], (oldData: TQueryFnData | undefined) => {
            const newMessage: Message = {
              id: Number(socketData.id),
              messageContent: socketData.messageContent,
              user: socketData.user,
              timestamp: socketData.timestamp,
              thread: socketData.thread,
              platform: socketData.platform,
              channelId: Number(socketData.channelId),
              channelName: socketData.channelName,
              sender: socketData.sender,
              customerId: socketData.customerId,
              platformChannelId: socketData.platformChannelId,
              avatar: socketData.avatar,
              messageTask: socketData.messageTask,
            };

            //  Handle incoming messages sent by current user
            const newData = {
              ...oldData,
              data: [newMessage, ...(oldData?.data || [])],
            };
            return newData;
          });
        }
      } else {
        await messages.refetch();
      }
    }
  };

  useEffect(() => {
    if (socket && currentUser && selectedQaTeamId) {
      socket.on('slack-message', handleSlackMessage);
      return () => {
        socket.off('slack-message');
      };
    }
  }, [socket, currentUser, selectedQaTeamId]);

  // TODO: Uncomment when feature is ready
  // const addMessage = (conversationId: string, threadId: string, message: Omit<Message, 'id'>) => {
  // setConversations((prevConversations) => {
  //   return prevConversations.map((conv) => {
  //     if (conv.id === conversationId) {
  //       return {
  //         ...conv,
  //         messages: [...conv.messages, { ...message, id: Date.now().toString(), threadId }],
  //       };
  //     }
  //     return conv;
  //   });
  // });
  // };

  if (customers.isError || messages.isError || qaTeams.isError)
    return <span>Error: {customers.error?.message || messages.error?.message || qaTeams.error?.message}</span>;

  return (
    <CommunicationsContext.Provider
      value={{
        customers: filteredCustomers || [],
        selectAllCustomers,
        setSelectAllCustomers,
        conversations,
        qaTeams: filteredQaTeams || [],
        selectedCustomerId: Number(selectedCustomerId),
        setSelectedCustomerId,
        selectedQaTeamId: Number(selectedQaTeamId),
        setSelectedQaTeamId,
        isLoading: { messages: messages.isLoading, customers: customers.isLoading, qaTeams: qaTeams.isLoading },
        getConversationsForCustomer,
        getConversation,
        getCustomerById,
        getQaTeamById,
        selectedConversationId: selectedConversationId || null,
        setSelectedConversationId,
        sendSlackMessageMutation,
        toast,
        setToast,
        suiteAction,
        isPending,
        conversationsLoading,
        setConversationsLoading,
        isTabLoading,
        setIsTabLoading,
      }}
    >
      {children}
    </CommunicationsContext.Provider>
  );
}

export function useCommunications() {
  const context = useContext(CommunicationsContext);
  if (context === undefined) {
    throw new Error('useCommunications must be used within a CommunicationsProvider');
  }
  return context;
}
