// React imports
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';


// Material UI Imports
import { Box } from '@mui/material';

// Data imports
import EditableCell from '../HiringUtils/EditableCell';
import { getAllJobSources } from '../HiringUtils/api';
import { useHandleSubmissionChange, useHandleCandidateInfoChange } from '../HiringUtils/helperFunctions';
import MarkdownPreviewer from '../HiringCandidatePanel/NotesSection/MarkdownPreviewer';
import ChatGPTDecision from '../HiringCandidatePanel/NotesSection/ChatGPTDecision';

const initialNotes = `### Candidate Notes:
- Location:  
- Previous Experience:  
- Referrer:  
- Interviewed Before:  
- Phone Presence:  
- Extra time given:  

##### Other Info:
- Rating:  

### [Question 1]()
-  

### [Question 2]()
-  

### [Question 3]()
-  
- [ ] Checked to see if arrays were empty  
`;

const FinalRoundDetailPanel = ({ row }) => {
  const { data: jobSources, isLoading, isFetching } = useQuery({
    queryKey: ['allJobSources'],
    queryFn: getAllJobSources,

  });

  // Initialize form state with existing candidate data
  const initialFormState = useMemo(() => ({
    firstName: row.original.candidate.firstName || '',
    lastName: row.original.candidate.lastName || '',
    email: row.original.candidate.email || '',
    location: row.original.candidate.location || '',
    refereeName: row.original.refereeName || '',
    jobSource: row.original.jobSourceName || '',
    instituteName: row.original.instituteName || '',
    takeHomeVersion: row.original.takeHomeVersion || '',
    finalRoundDate: row.original.finalRoundDate || '',
    level: row.original.level || '',
    interviewer: row.original.interviewer || '',
    status: row.original.status || '',
    stipendInfo: row.original.candidate.stipendInfo || '',
    notes: row.original.notes || '',
  }), [row.original]);

  const [formState, setFormState] = useState(initialFormState);
  const handleCandidateInfoChange = useHandleCandidateInfoChange();


  const handleSubmissionChange = useHandleSubmissionChange(setFormState);



  // Define form fields dynamically
  const formFields = useMemo(() => [
    { label: 'Job Source', name: 'jobSource', type: 'select', options: !isLoading && !isFetching ? jobSources.map((j) => j.sourceName) : [] },
    { label: 'Referee Name', name: 'refereeName', type: 'input' },
    { label: 'Institute Name', name: 'instituteName', type: 'input' },
    { label: 'Take-Home Version', name: 'takeHomeVersion', type: 'select', options: ["V2", "V3"] },
    { label: 'Level', name: 'level', type: 'select', options: ['Passed', 'Close', 'Ran out of Time', 'Waste of Time'] },
    { label: 'Interviewer', name: 'interviewer', type: 'select', options: ["Amanda", "Jonathan", "Laura"] },
    { label: 'Stipend Info', name: 'stipendInfo', type: 'input' },
  ], [jobSources]);

  // Handle change of data for specific fields (jobSource and stipendInfo)
  const fieldHandlers = {
    stipendInfo: (row, newValue) => {
      return handleCandidateInfoChange(
        {
          candidate: {
            id: row.original.candidateId,
            stipendInfo: newValue,
          },
        },
      );
    },
    jobSource: (row, newValue) => {
      const jobSourceId = jobSources.findIndex((j) => j.sourceName === newValue) + 1;
      return handleSubmissionChange(
        {
          submissionId: row.original.id,
          newInfo: jobSourceId,
          row,
        },
      );
    },
  };

  // Default handler for fields without specific handlers
  const defaultHandler = (row, newValue, fieldName) => {
    return handleSubmissionChange(
      {
        submissionId: row.original.id,
        newInfo: { [fieldName]: newValue },
        row,
      },
    );
  };

  // Handle saving the new info
  const handleSave = (row, newValue, fieldName) => {
    const handler = fieldHandlers[fieldName] || defaultHandler;
    handler(row, newValue, fieldName);
  };

  return (
    <Box
      className="panelContainer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        width: '100%',
        height: '100%',
        position: 'sticky',
        backgroundColor: 'rgb(17, 24, 39)',
        '@media (max-width: 1320px) or (max-height: 870px)': {
          width: '90%',
        },
      }}
    >
      <Box
        className="panelTopContainer"
        sx={{
          display: 'flex',
          boxShadow: 6,
          margin: 0,
          borderRadius: 1,
          width: '100%',
          overflowY: 'auto', // Added for scrollability
        }}
      >
        <section
          className="flex flex-wrap flex-col justify-center px-4 pt-2"
          style={{ width: '30%' }}
        >
          {formFields.map((field) => {

            if (field.type === "textarea") {
              return (
                <div className="flex flex-col inputs mb-4" key={field.name}>
                  <label className="font-semibold mb-1 text-white" htmlFor={field.name}>
                    {field.label}
                  </label>
                  <EditableCell
                    initialValue={formState[field.name]}
                    onSave={(newValue) => {
                      handleSave(row, newValue, field.name);
                    }}
                    placeholder={formState[field.name] ? formState[field.name] : "—"}
                    type={field.type}
                    options={field.options || []}
                    style={{
                      padding: '4px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f9f9f9',
                    }}
                    rows={10}
                  />
                </div>
              );
            }

            return (
              <div className="flex flex-col inputs mb-4" key={field.name}>
                <label className="font-semibold mb-1 text-white" htmlFor={field.name}>
                  {field.label}
                </label>
                <EditableCell
                  initialValue={formState[field.name]}
                  onSave={(newValue) => {
                    handleSave(row, newValue, field.name);
                  }}
                  placeholder={formState[field.name] ? formState[field.name] : "—"}
                  type={field.type}
                  options={field.options || []}
                  style={{
                    padding: '4px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    backgroundColor: '#f9f9f9',
                  }}
                />
              </div>
            );

          })}
        </section>
        <section
          className="flex flex-col p-4"
          style={{ width: '70%' }}
        >
          <ChatGPTDecision row={row.original} />
        </section>
      </Box>
      <Box className=" panelBottomContainer px-4 border-r border-gray-700 bg-gray-900 overflow-auto" >
        <div>
          <MarkdownPreviewer notes={row.original.notes ? row.original.notes : initialNotes} row={row.original} />
        </div>
      </Box>
    </Box>
  );
};

export default FinalRoundDetailPanel;
