import { sendPostRequest } from '../../utils/network';
import PageError from '../../utils/pageErrorHandler';

// Function to fetch metrics for customers
export async function fetchMetricsForCustomers(customerIds, startDate, endDate, groupByTrigger) {
  try {
    const resultData = await sendPostRequest('/client-summaries/fetchMetricsForCustomers', {
      customerIds,
      startTime: startDate,
      endTime: endDate,
      groupByTrigger: groupByTrigger,
    });

    const data = resultData?.data;
    if (data) {
      return data;
    }

    if (resultData?.error?.response?.status === 404) {
      throw new PageError('No metrics found for the selected customers.', { statusText: 'Data Not Found' });
    } else {
      throw new PageError('Sorry, something went wrong. Please notify #qa-dragons if you need help.', {
        statusText: 'Unknown Error',
      });
    }
  } catch (error) {
    if (error instanceof PageError) {
      throw error;
    } else {
      throw new Error('Sorry, something went wrong. Please notify #qa-dragons if you need help.');
    }
  }
}

// Get Leads, CSMs, Managerm, and their customers
export async function getLeadsManagersCsmsCustomers() {
  try {
    const resultData = await sendPostRequest('/client-summaries/fetch-leads-managers-csms-customers');
    const data = resultData?.data;

    if (data) {
      return data;
    }

    if (resultData?.error?.response?.status === 404) {
      throw new PageError('No leads, managers, or CSMs found.', { statusText: 'Data Not Found' });
    } else {
      throw new PageError('Sorry, something went wrong. Please notify #qa-dragons if you need help.', {
        statusText: 'Unknown Error',
      });
    }
  } catch (error) {
    if (error instanceof PageError) {
      throw error;
    } else {
      throw new Error('Sorry, something went wrong. Please notify #qa-dragons if you need help.');
    }
  }
}

// Function to fetch blockers for customers
export async function fetchBlockersForCustomers(customerIds) {
  if (!customerIds || !Array.isArray(customerIds)) {
    throw new Error('customerIds must be an array');
  }

  try {
    const resultData = await sendPostRequest('/client-summaries/fetch-blockers-by-customer-ids', { customerIds });

    const data = resultData?.data;
    if (data) {
      return data;
    }

    if (resultData?.error?.response?.status === 404) {
      throw new PageError('No blockers found for the selected customers.', { statusText: 'Data Not Found' });
    } else {
      throw new PageError('Sorry, something went wrong. Please notify #qa-dragons if you need help.', {
        statusText: 'Unknown Error',
      });
    }
  } catch (error) {
    if (error instanceof PageError) {
      throw error;
    } else {
      throw new Error('Sorry, something went wrong. Please notify #qa-dragons if you need help.');
    }
  }
}
