import type React from 'react';
import { useState } from 'react';
import { useCommunications } from '../contexts/CommunicationsContext';
import { IssueCard } from './IssueCard';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ResourceSection } from './ResourceSection';
import { AddItemModal } from './AddItemModal';
import { InternalLinks } from './InternalLinks';
import { MessageSquare, GitBranch, Play, Bug, Link, ExternalLink, Users, Edit, Plus, Trash2 } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
// import { Progress } from '@/components/ui/progress';
import { Button } from '../../ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Card, CardContent } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { WorkflowProvider } from '../contexts/WorkflowContext';
import type { InvestigationTask } from '../types/investigation';
type InvestigationNotesProps = {
  investigationId: string;
  onComplete: (investigation: InvestigationTask) => void;
  investigations: InvestigationTask[];
};

export const InvestigationNotes: React.FC<InvestigationNotesProps> = ({ investigationId, onComplete, investigations }) => {
  const { /**addInternalResource, */ claimInvestigation, unclaimInvestigation } = useCommunications();
  const investigation = investigations.find((inv) => inv.id === investigationId);
  const [notes, setNotes] = useState<string>(investigation?.notes || '');
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [networkScore] = useState(52); // This should be dynamic in a real application

  const queuedWorkflows = investigation?.workflows.filter((wf) => wf.status === 'queued').length || 0;
  const runningWorkflows = investigation?.workflows.filter((wf) => wf.status === 'running').length || 0;

  if (!investigation) {
    return <div className="flex-1 p-4">Investigation not found</div>;
  }

  const formatRelativeTime = (timestamp: string | undefined) => {
    if (!timestamp) return 'N/A';
    const diff = Date.now() - new Date(timestamp).getTime();
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (minutes > 0) return `${minutes} minutes ago`;
    return 'Just now';
  };

  const handleAddItem = (item: { title: string; type: string; link: string }) => {
    // addInternalResource(investigationId, {
    //   id: Date.now().toString(),
    //   ...item,
    // } as any);
    console.log('Adding item:', item);
  };

  const getResourcesByType = (type: string) => {
    return investigation.internalResources.filter((r) => r.type === type);
  };

  const handleClaim = () => {
    claimInvestigation(investigationId, 'current-user-id'); // Replace with actual user ID
  };

  const handleUnclaim = () => {
    unclaimInvestigation(investigationId);
  };

  const handleSaveNotes = () => {
    // TODO: Implement actual save functionality
    if (notes.trim()) {
      setIsEditingNotes(false);
    } else {
      setNotes('');
      setIsEditingNotes(false);
    }
  };

  const handleDeleteNotes = () => {
    setNotes('');
    setIsEditingNotes(false);
  };

  const handleRetry = (issueId: string, workflowId: string) => {
    const updatedIssues = investigation?.issues?.map((issue) => {
      if (issue.id === issueId) {
        const updatedWorkflows = issue.workflows.map((workflow) => {
          if (workflow.id === workflowId) {
            return { ...workflow, status: 'running', startTime: new Date().toISOString() };
          }
          return workflow;
        });
        return { ...issue, workflows: updatedWorkflows };
      }
      return issue;
    });

    // Update the investigation with the new issues
    const updatedInvestigation = { ...investigation, issues: updatedIssues };
    // You would typically update this in your state management system
    console.log('Updated investigation:', updatedInvestigation);
  };

  const handleRetryAll = (issueId: string) => {
    const updatedIssues = investigation?.issues?.map((issue) => {
      if (issue.id === issueId) {
        const updatedWorkflows = issue.workflows.map((workflow) => {
          if (workflow.status === 'failed') {
            return { ...workflow, status: 'running', startTime: new Date().toISOString() };
          }
          return workflow;
        });
        return { ...issue, workflows: updatedWorkflows };
      }
      return issue;
    });

    // Update the investigation with the new issues
    const updatedInvestigation = { ...investigation, issues: updatedIssues };
    // You would typically update this in your state management system
    console.log('Updated investigation after retry all:', updatedInvestigation);
  };

  const handleOverrideBaseline = (issueId: string, workflowId: string) => {
    const updatedIssues = investigation?.issues?.map((issue) => {
      if (issue.id === issueId) {
        const updatedWorkflows = issue.workflows.map((workflow) => {
          if (workflow.id === workflowId) {
            return { ...workflow, baselineOverride: true };
          }
          return workflow;
        });
        return { ...issue, workflows: updatedWorkflows };
      }
      return issue;
    });

    // Update the investigation with the new issues
    const updatedInvestigation = { ...investigation, issues: updatedIssues };
    // You would typically update this in your state management system
    console.log('Updated investigation after baseline override:', updatedInvestigation);
  };

  const handleCompleteInvestigation = () => {
    const updatedInvestigation = {
      ...investigation,
      status: 'completed',
      assignee: investigation.assignee,
      completedAt: new Date().toISOString(),
      lastCompletedBy: investigation.assignee?.name || 'Unknown',
    };
    onComplete(updatedInvestigation as InvestigationTask);
  };

  // const allIssuesResolved = investigation.issues?.every((issue) => issue.status === 'resolved') ?? false;
  const progressPercentage = Math.round((investigation.failures.investigated / investigation.failures.total) * 100);
  const queuedAndRunningPercentage =
    queuedWorkflows + runningWorkflows > 0 ? Math.round(((queuedWorkflows + runningWorkflows) / investigation.workflows.length) * 100) : 0;

  return (
    <WorkflowProvider>
      <div className="flex h-screen overflow-hidden">
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="bg-white p-4 border-b border-gray-200 shadow-sm">
            <div className="flex justify-between items-start mb-2">
              <div>
                <h2 className="text-xl font-bold text-gray-800 flex items-center">
                  <a
                    href={`https://external-platform.com/app/${investigation.application.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {investigation.application.environment}
                  </a>
                  <ExternalLink className="ml-2 h-4 w-4 text-gray-500" />
                </h2>
                <div className="text-sm text-gray-600 mt-1 flex items-center">
                  <a
                    href={`https://external-platform.com/env/${investigation.application.environment}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {investigation.references[0].name}
                  </a>
                  <ExternalLink className="ml-1 h-3 w-3 text-gray-500" />
                  <Badge variant="outline" className="ml-2">
                    {investigation.suiteType}
                  </Badge>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                {investigation.assignee ? (
                  <div className="flex items-center">
                    <img
                      src={investigation.assignee.avatar || '/placeholder.svg'}
                      alt={investigation.assignee.name}
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    <span className="text-sm text-gray-600 mr-2">{investigation.assignee.name}</span>
                    <Button variant="outline" size="sm" onClick={handleUnclaim}>
                      Unclaim
                    </Button>
                  </div>
                ) : (
                  <Button size="sm" onClick={handleClaim}>
                    Claim
                  </Button>
                )}
                <Badge variant={investigation.priority === 'high' ? 'destructive' : 'secondary'}>{investigation.priority}</Badge>
              </div>
            </div>
            <div className="mt-2 mb-4">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium text-gray-700">Investigation Progress</span>
                <span className="text-sm font-medium text-gray-700">
                  {investigation.failures.investigated} / {investigation.failures.total} ({progressPercentage}% Complete)
                </span>
              </div>
              <div className="w-full h-2 bg-gray-400 rounded-full overflow-hidden border border-gray-400">
                <div className="h-full bg-gray-600" style={{ width: `${progressPercentage}%` }} />
                <div className="h-full bg-gray-500 -mt-2" style={{ width: `${queuedAndRunningPercentage}%` }} />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <div className="w-1/3 flex items-center space-x-4">
                  <span className="text-sm font-medium text-gray-700">Network Score:</span>
                  <span className="text-sm font-medium text-red-600">{networkScore}%</span>
                </div>
                <div className="w-2/3 h-2 flex rounded-full overflow-hidden">
                  {Array.from({ length: 50 }).map((_, index) => {
                    const colors = ['bg-green-500', 'bg-yellow-400', 'bg-red-500', 'bg-blue-500', 'bg-purple-500'];
                    const randomColor = Math.random() < 0.52 ? colors[0] : colors[Math.floor(Math.random() * 4) + 1];
                    return <div key={index} className={`w-[2%] ${randomColor}`} />;
                  })}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center text-xs text-gray-600">
              <div>
                First Claimed: <span className="font-semibold">{formatRelativeTime(investigation.timestamps.firstClaimed)}</span>
              </div>
              <div>
                Suite Age: <span className="font-semibold">{formatRelativeTime(investigation.timestamps.created)}</span>
              </div>
              {investigation.timestamps.oldestFailure && (
                <div>
                  Oldest Failure: <span className="font-semibold">{formatRelativeTime(investigation.timestamps.oldestFailure)}</span>
                </div>
              )}
            </div>
          </div>
          <ScrollArea className="flex-1">
            <div className="p-4 space-y-4">
              {(notes || isEditingNotes) && (
                <Card className="mb-4">
                  <CardContent className="p-4">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold">Investigation Notes</h3>
                    </div>
                    {isEditingNotes ? (
                      <>
                        <Textarea
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          className="w-full h-32 mb-2"
                          placeholder="Enter investigation notes here..."
                        />
                        <div className="flex justify-between">
                          <Button onClick={handleSaveNotes}>Save Notes</Button>
                          <Button variant="destructive" onClick={handleDeleteNotes}>
                            <Trash2 className="w-4 h-4 mr-2" />
                            Delete Notes
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <p className="text-sm text-gray-700 whitespace-pre-wrap">{notes}</p>
                      </div>
                    )}
                  </CardContent>
                </Card>
              )}
              <div>
                <IssueCard
                  issues={investigation.issues || []}
                  onRetry={handleRetry}
                  onRetryAll={handleRetryAll}
                  onOverrideBaseline={handleOverrideBaseline}
                  networkScore={networkScore}
                  onAllIssuesResolved={handleCompleteInvestigation}
                />
              </div>
            </div>
          </ScrollArea>
        </div>
        <div className="w-80 border-l border-gray-200 bg-white overflow-hidden flex flex-col">
          <Card className="rounded-none border-x-0 border-t-0">
            <CardContent className="p-3">
              <h3 className="text-sm font-semibold mb-2">Team Information</h3>
              <div className="flex items-center mb-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center mr-2">
                        <Users className="w-4 h-4 text-white" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        {investigation.failures.investigated}/{investigation.failures.total} investigated
                      </p>
                      {queuedWorkflows > 0 && <p>{queuedWorkflows} queued</p>}
                      {runningWorkflows > 0 && <p>{runningWorkflows} running</p>}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <Badge variant="secondary" className="text-xs">
                  {investigation.application.name}
                </Badge>
              </div>
              <div className="space-y-1 text-sm">
                <p>
                  <span className="font-medium">QA Manager:</span> {investigation.teamInfo.qaManager}
                </p>
                <p>
                  <span className="font-medium">QA Lead:</span> {investigation.teamInfo.qaLead}
                </p>
                <p>
                  <span className="font-medium">CSM:</span> {investigation.teamInfo.csm}
                </p>
              </div>
            </CardContent>
          </Card>
          <ScrollArea className="flex-1">
            <div className="p-3 space-y-4">
              <div className="flex items-center justify-between mb-4">
                <AddItemModal onAddItem={handleAddItem} />
                <Button variant="outline" size="sm" onClick={() => setIsEditingNotes(true)} className="flex items-center">
                  {notes ? (
                    <>
                      <Edit className="w-4 h-4 mr-2" />
                      Edit Notes
                    </>
                  ) : (
                    <>
                      <Plus className="w-4 h-4 mr-2" />
                      Add Notes
                    </>
                  )}
                </Button>
              </div>
              <InternalLinks />
              <ResourceSection
                title="Related Conversations"
                icon={<MessageSquare className="w-4 h-4" />}
                resources={getResourcesByType('conversation')}
              />
              <ResourceSection title="Related Workflows" icon={<GitBranch className="w-4 h-4" />} resources={getResourcesByType('workflow')} />
              <ResourceSection title="Related Runs" icon={<Play className="w-4 h-4" />} resources={getResourcesByType('run')} />
              <ResourceSection title="Related Bugs" icon={<Bug className="w-4 h-4" />} resources={getResourcesByType('bug')} />
              <ResourceSection title="Other Resources" icon={<Link className="w-4 h-4" />} resources={getResourcesByType('other')} />
            </div>
          </ScrollArea>
        </div>
      </div>
    </WorkflowProvider>
  );
};
