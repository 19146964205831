'use client';

import { useState, useEffect, useMemo } from 'react';
import { ConversationList } from './ConversationList';
import { InvestigationNotes } from './InvestigationNotes';
import { CommunicationsProvider, useCommunications } from '../contexts/CommunicationsContext';
import { WorkflowProvider, useWorkflow } from '../contexts/WorkflowContext';
import type { InvestigationTask } from '../types/investigation';

function HQMainPage() {
  const [selectedInvestigationId, setSelectedInvestigationId] = useState<string | null>(null);
  const { investigations, updateInvestigation } = useCommunications();
  const { updateWorkflowStatus } = useWorkflow();
  const [activeTab, setActiveTab] = useState<'ongoing' | 'completed'>('ongoing');

  // Ensure all investigations have an issues array
  const updatedInvestigations = useMemo(
    () =>
      investigations.map((inv) => ({
        ...inv,
        issues: inv.issues || [],
      })),
    [investigations],
  );

  useEffect(() => {
    if (selectedInvestigationId) {
      const investigation = updatedInvestigations.find((inv) => inv.id === selectedInvestigationId);
      if (investigation) {
        investigation.issues.forEach((issue) => {
          issue.workflows.forEach((workflow) => {
            updateWorkflowStatus(workflow.id, workflow.status, workflow);
          });
        });
      }
    }
  }, [selectedInvestigationId, updatedInvestigations, updateWorkflowStatus]);

  const handleCompleteInvestigation = (updatedInvestigation: InvestigationTask) => {
    updateInvestigation(updatedInvestigation);
    setSelectedInvestigationId(null);
    setActiveTab('completed');
  };

  return (
    <div className="flex h-screen">
      <ConversationList
        onSelectInvestigation={setSelectedInvestigationId}
        selectedInvestigationId={selectedInvestigationId}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        investigations={updatedInvestigations}
      />
      <div className="flex-1 overflow-hidden">
        {selectedInvestigationId && (
          <InvestigationNotes
            investigationId={selectedInvestigationId}
            onComplete={handleCompleteInvestigation}
            investigations={updatedInvestigations}
          />
        )}
      </div>
    </div>
  );
}

export function HQMain() {
  return (
    <CommunicationsProvider>
      <WorkflowProvider>
        <HQMainPage />
      </WorkflowProvider>
    </CommunicationsProvider>
  );
}
