import { ConversationList } from './ConversationList';
import { useCommunications } from '../../context/CommunicationsContext';
import { ConversationDetails } from './ConversationDetails';
import Toast from '../../Layout/Toast';

function MessageHQMainPage() {
  const { selectedCustomerId, selectedConversationId, setSelectedConversationId, toast, setToast, setConversationsLoading, getConversation } =
    useCommunications();

  const handleSelectConversation = (conversationId: number, state: 'inProgress' | 'completed') => {
    setConversationsLoading(true);
    document.body.style.cursor = 'wait'; // Set cursor to loading state

    requestAnimationFrame(() => {
      setSelectedConversationId(conversationId);
      if (selectedCustomerId) {
        const savedViewState = localStorage.getItem('savedViewState') ? JSON.parse(localStorage.getItem('savedViewState') as string) : {};

        localStorage.setItem(
          'savedViewState',
          JSON.stringify({
            ...savedViewState,
            activeTab: state,
            conversationByCustomer: {
              ...savedViewState?.conversationByCustomer,
              [`${selectedCustomerId}`]: {
                ...savedViewState?.conversationByCustomer?.[selectedCustomerId],
                [`${state}`]: { conversationId },
              },
            },
          }),
        );
      }

      // Get the conversation data
      const conversation = getConversation(Number(conversationId));
      if (conversation) {
        setConversationsLoading(false);
        document.body.style.cursor = 'default'; // Reset cursor
      }
    });
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Toast
        title={toast?.title ?? ''}
        message={toast?.message ?? ''}
        show={toast !== null}
        onDone={() => setToast(null)}
        isSuccess={toast?.isSuccess}
        content={toast?.content || <></>}
      />
      <ConversationList
        customerId={selectedCustomerId}
        onSelectConversation={handleSelectConversation}
        selectedConversationId={selectedConversationId}
      />
      {selectedConversationId && <ConversationDetails conversationId={selectedConversationId} />}
    </div>
  );
}

export function MessageHQMain() {
  return <MessageHQMainPage />;
}
