import { useRef, useEffect, useState } from "react";
import { Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper, Typography } from "@mui/material";

const ChatGPTDecision = ({ row }) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);

  // Calculate the height of the decision box
  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight; // Grab the height dynamically
      setContainerHeight(parseInt(height, 10));
    }
  }, []);

  // Format ChatGPT's suggestion on the candidate
  const formatJSON = (inputString) => {
    const formatted = {};

    // Parse the string as JSON
    const parsedData = JSON.parse(inputString);

    Object.entries(parsedData).forEach(([key, value]) => {
      formatted[key] = value;
    });

    return formatted;
  };

    return (
        <TableContainer className="gptDecisionTable" component={Paper} sx={{ height: '100%', width: '100%' }} ref={containerRef}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} align="center">
                            <Typography style={{ fontWeight: 'bold' }}>
                                ChatGPT's Suggestion on {row.candidate.firstName}'s Submission
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ width: '50px' }}>
                            <Typography variant="body1">Decision</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" align="left">{row.aiNotes ? formatJSON(row.aiNotes).decision : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ padding: `calc((${containerHeight}px / 3) / 2) 16px` }}>
                            <Typography variant="body1">Reason</Typography>
                        </TableCell>
                        <TableCell sx={{ padding: `calc((${containerHeight}px / 3) / 2) 16px` }}>
                            <Typography variant="body2" align="left">{row.aiNotes ? formatJSON(row.aiNotes).reason : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1">Rating</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" align="left">{row.aiNotes ? formatJSON(row.aiNotes).rating : "N/A"}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ChatGPTDecision;
