import type React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { ExternalLink, RefreshCw, ChevronDown, ChevronUp, ChevronRight, AlertCircle, Clock, CheckCircle, Bug, Wrench, Loader } from 'lucide-react';
import type { Issue, Workflow, WorkflowStatus } from '../types/investigation';
import { BugReproductionCard } from './BugReproductionCard';
import { FailedCleanupCard } from './FailedCleanupCard';
import { ConcurrencyReviewCard } from './ConcurrencyReviewCard';
import { useWorkflow } from '../contexts/WorkflowContext';

// const formatDuration = (startTime: string): string => {
//   const start = new Date(startTime).getTime();
//   const now = Date.now();
//   const durationInSeconds = Math.floor((now - start) / 1000);
//   const minutes = Math.floor(durationInSeconds / 60);
//   const seconds = durationInSeconds % 60;
//   return `${minutes}:${seconds.toString().padStart(2, '0')}`;
// };

const StatusIcon: React.FC<{ status: WorkflowStatus }> = ({ status }) => {
  const getStatusIcon = (status: WorkflowStatus) => {
    switch (status) {
      case 'failed':
        return <AlertCircle className="w-3 h-3 text-white" />;
      case 'queued':
        return <Clock className="w-3 h-3 text-gray-800" />;
      case 'running':
        return <Loader className="w-3 h-3 text-gray-800 animate-[spin_5s_ease-in-out_infinite]" />;
      case 'passed':
        return <CheckCircle className="w-3 h-3 text-white" />;
      case 'bug':
        return <Bug className="w-3 h-3 text-gray-800" />;
      case 'maintenance':
        return <Wrench className="w-3 h-3 text-white" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status: WorkflowStatus) => {
    switch (status) {
      case 'failed':
        return 'bg-black';
      case 'queued':
        return 'bg-gray-300';
      case 'running':
        return 'bg-yellow-200';
      case 'passed':
        return 'bg-green-500';
      case 'bug':
        return 'bg-red-200';
      case 'maintenance':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  };

  return <div className={`rounded-full p-0.5 ${getStatusColor(status)}`}>{getStatusIcon(status)}</div>;
};

const StatusSummaryBar: React.FC<{ workflows: Workflow[] }> = ({ workflows }) => {
  const statusOrder: WorkflowStatus[] = ['failed', 'queued', 'running', 'bug', 'maintenance', 'passed'];
  const statusCounts = workflows.reduce((acc, workflow) => {
    acc[workflow.status] = (acc[workflow.status] || 0) + 1;
    return acc;
  }, {} as Record<WorkflowStatus, number>);

  const total = workflows.length;

  const getStatusColor = (status: WorkflowStatus) => {
    switch (status) {
      case 'failed':
        return 'bg-black';
      case 'queued':
        return 'bg-gray-300';
      case 'running':
        return 'bg-yellow-200';
      case 'bug':
        return 'bg-red-500';
      case 'maintenance':
        return 'bg-blue-500';
      case 'passed':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const tooltipContent = statusOrder
    .filter((status) => statusCounts[status] > 0)
    .map((status) => `${status}: ${statusCounts[status]}`)
    .join(', ');

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="flex h-4 w-20 rounded-full overflow-hidden">
            {statusOrder.map(
              (status) =>
                statusCounts[status] > 0 && (
                  <div
                    key={status}
                    className={`${getStatusColor(status)} transition-all duration-300 ease-in-out`}
                    style={{ width: `${(statusCounts[status] / total) * 100}%` }}
                  />
                ),
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltipContent}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

interface IssueCardProps {
  issues: Issue[];
  onRetry: (issueId: string, workflowId: string) => void;
  onRetryAll: (issueId: string) => void;
  onOverrideBaseline: (issueId: string, workflowId: string) => void;
  networkScore: number;
  onAllIssuesResolved: () => void;
}

const WorkflowItem: React.FC<{
  workflow: Workflow;
  onStatusChange: (workflowId: string, newStatus: WorkflowStatus) => void;
}> = ({ workflow, onStatusChange }) => {
  const [localStatus, setLocalStatus] = useState<WorkflowStatus>(workflow.status);
  // Commented out for future timer-based animations
  // const [duration, setDuration] = useState('0:00');
  // const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    setLocalStatus(workflow.status);
    // if (workflow.status === 'queued' || workflow.status === 'running') {
    //   setStartTime(Date.now());
    // } else {
    //   setStartTime(null);
    // }
  }, [workflow.status]);

  /* Timer-based status updates - commented out for now
  useEffect(() => {
    let timer: number | null = null;
    let statusTimer: number | null = null;

    const updateDuration = () => {
      if (startTime) {
        const now = Date.now();
        const durationInSeconds = Math.floor((now - startTime) / 1000);
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        setDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
      }
    };

    if (localStatus === 'queued' || localStatus === 'running') {
      timer = window.setInterval(updateDuration, 1000);

      if (localStatus === 'queued') {
        statusTimer = window.setTimeout(() => {
          setLocalStatus('running');
          onStatusChange(workflow.id, 'running');
          setStartTime(Date.now());
        }, Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000);
      }
    }

    return () => {
      if (timer) window.clearInterval(timer);
      if (statusTimer) window.clearTimeout(statusTimer);
    };
  }, [localStatus, startTime, workflow.id, onStatusChange]);
  */

  const handleRetry = () => {
    if (localStatus === 'failed') {
      setLocalStatus('queued');
      onStatusChange(workflow.id, 'queued');
    }
  };

  return (
    <div className="flex items-center justify-between py-0.5 text-xs">
      <div className="flex items-center space-x-1.5">
        <StatusIcon status={localStatus} />
        <span className="font-medium truncate max-w-[120px]">{workflow.name}</span>
        {workflow.failureLink && (
          <a href={workflow.failureLink} target="_blank" rel="noopener noreferrer" className="text-blue-500">
            <ExternalLink size={10} />
          </a>
        )}
      </div>
      {localStatus === 'failed' && (
        <Button variant="ghost" size="sm" onClick={handleRetry} className="h-6 px-2 py-0">
          <RefreshCw className="h-3 w-3" />
        </Button>
      )}
    </div>
  );
};

interface IssueSummaryProps {
  issue: Issue;
  setIssue: (updater: (prevIssue: Issue) => Issue) => void;
  onRetry: (issueId: string, workflowId: string) => void;
  onRetryAll: () => void;
  onOverrideBaseline: (workflowId: string) => void;
  onResolve: (issueId: string) => void;
}

const IssueSummary: React.FC<IssueSummaryProps> = ({ issue, setIssue, onRetry, onRetryAll, onResolve }) => {
  // const { workflows, updateWorkflowStatus } = useWorkflow();
  const [isExpanded, setIsExpanded] = useState(issue.status === 'open');
  const [showAllWorkflows, setShowAllWorkflows] = useState(false);
  const [showResolvedWorkflows, setShowResolvedWorkflows] = useState(false);
  const [resolvingIssues, setResolvingIssues] = useState<Set<string>>(new Set());

  // const isFailureInvestigation = issue.title === 'Login Failure on Staging' || issue.title === 'Slow Page Load Times';

  const allWorkflowsResolved = useMemo(() => issue.workflows.every((wf) => ['passed', 'bug', 'maintenance'].includes(wf.status)), [issue.workflows]);

  useEffect(() => {
    if (allWorkflowsResolved && !resolvingIssues.has(issue.id)) {
      setResolvingIssues((prev) => new Set(prev).add(issue.id));
      setTimeout(() => {
        onResolve(issue.id);
        setResolvingIssues((prev) => {
          const newSet = new Set(prev);
          newSet.delete(issue.id);
          return newSet;
        });
      }, 2000);
    }
  }, [allWorkflowsResolved, issue.id, onResolve, resolvingIssues]);

  const sortedWorkflows = useMemo(() => {
    return [...issue.workflows].sort((a, b) => {
      const order = { failed: 0, queued: 1, running: 2, passed: 3, bug: 4, maintenance: 5 };
      return order[a.status] - order[b.status];
    });
  }, [issue.workflows]);

  const activeWorkflows = useMemo(() => {
    return sortedWorkflows.filter((wf) => !['passed', 'bug', 'maintenance'].includes(wf.status));
  }, [sortedWorkflows]);

  const resolvedWorkflows = useMemo(() => {
    return sortedWorkflows.filter((wf) => ['passed', 'bug', 'maintenance'].includes(wf.status));
  }, [sortedWorkflows]);

  const visibleWorkflows = useMemo(() => {
    return activeWorkflows.filter((wf) => ['failed', 'queued', 'running'].includes(wf.status));
  }, [activeWorkflows]);

  const hiddenWorkflows = useMemo(() => {
    return activeWorkflows.filter((wf) => !['failed', 'queued', 'running'].includes(wf.status));
  }, [activeWorkflows]);

  const passedCount = useMemo(() => resolvedWorkflows.filter((wf) => wf.status === 'passed').length, [resolvedWorkflows]);
  const bugCount = useMemo(() => resolvedWorkflows.filter((wf) => wf.status === 'bug').length, [resolvedWorkflows]);
  const maintenanceCount = useMemo(() => resolvedWorkflows.filter((wf) => wf.status === 'maintenance').length, [resolvedWorkflows]);

  const handleStatusChange = useCallback(
    (workflowId: string, newStatus: WorkflowStatus) => {
      setIssue((prevIssue) => {
        const updatedWorkflows = prevIssue.workflows.map((w) => (w.id === workflowId ? { ...w, status: newStatus } : w));

        const allResolved = updatedWorkflows.every((wf) => ['passed', 'bug', 'maintenance'].includes(wf.status));

        if (allResolved) {
          onResolve(prevIssue.id);
        }

        return {
          ...prevIssue,
          workflows: updatedWorkflows,
        };
      });

      if (newStatus === 'queued') {
        onRetry(issue.id, workflowId);
      }
    },
    [setIssue, onRetry, onResolve],
  );

  const handleRetryAll = useCallback(() => {
    issue.workflows.forEach((workflow) => {
      if (workflow.status === 'failed') {
        handleStatusChange(workflow.id, 'queued');
      }
    });
    onRetryAll();
  }, [issue.workflows, handleStatusChange, onRetryAll]);

  useEffect(() => {
    const queuedWorkflows = issue.workflows.filter((wf) => wf.status === 'queued');
    queuedWorkflows.forEach((workflow) => {
      const delay = Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000;
      setTimeout(() => {
        handleStatusChange(workflow.id, 'running');
      }, delay);
    });
  }, [issue.workflows, handleStatusChange]);

  return (
    <Card
      className={`mb-2 ${issue.status === 'open' && allWorkflowsResolved ? 'animate-pulse' : ''} ${
        issue.type === 'historic-failure' ? 'bg-red-200' : ''
      }`}
    >
      <CardHeader className="py-3 px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {issue.type === 'historic-failure' && (
              <Badge variant="secondary" className="bg-red-500 text-white">
                Historic
              </Badge>
            )}
            <CardTitle className="text-sm font-medium flex items-center">
              {(issue.status === 'resolved' || resolvingIssues.has(issue.id)) && <CheckCircle className="w-4 h-4 text-green-500 mr-2" />}
              {issue.title}
            </CardTitle>
          </div>
          <div className="flex items-center space-x-2">
            <StatusSummaryBar workflows={issue.workflows} />
            {visibleWorkflows.some((wf) => wf.status === 'failed') && (
              <Button variant="outline" size="sm" onClick={handleRetryAll} className="h-6 text-xs px-2">
                Retry All
              </Button>
            )}
            <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)} className="h-6 px-1">
              {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </Button>
          </div>
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent className="py-1 px-3">
          <p className="text-xs text-gray-600 mb-1">{issue.description}</p>
          {issue.type === 'historic-failure' && issue.suiteInfo && (
            <div className="text-xs text-gray-600 mb-2">
              <a href={issue.suiteInfo.link} className="text-blue-500 hover:underline">
                {issue.suiteInfo.name}
              </a>
              {issue.oldestFailureAge && <span className="ml-2">Oldest failure: {issue.oldestFailureAge}</span>}
            </div>
          )}
          <div className="space-y-0.5">
            {visibleWorkflows.map((workflow) => (
              <WorkflowItem key={workflow.id} workflow={workflow} onStatusChange={handleStatusChange} />
            ))}
            {hiddenWorkflows.length > 0 && (
              <div className="flex items-center justify-between py-0.5 text-xs">
                <span className="text-gray-500">
                  {hiddenWorkflows.length} hidden workflow{hiddenWorkflows.length !== 1 ? 's' : ''}
                </span>
                <Button variant="ghost" size="sm" onClick={() => setShowAllWorkflows(!showAllWorkflows)} className="h-5 px-1 py-0 text-xs">
                  {showAllWorkflows ? 'Hide' : 'Show all'} <ChevronRight size={10} className="ml-1" />
                </Button>
              </div>
            )}
            {showAllWorkflows &&
              hiddenWorkflows.map((workflow) => <WorkflowItem key={workflow.id} workflow={workflow} onStatusChange={handleStatusChange} />)}
            {resolvedWorkflows.length > 0 && (
              <div className="flex items-center justify-between py-0.5 text-xs mt-2">
                <span className="text-gray-500">
                  {resolvedWorkflows.length} resolved workflow{resolvedWorkflows.length !== 1 ? 's' : ''}
                  {passedCount > 0 && ` (${passedCount} passed`}
                  {bugCount > 0 && `${passedCount > 0 ? ', ' : '('}${bugCount} bug${bugCount !== 1 ? 's' : ''}`}
                  {maintenanceCount > 0 && `${passedCount > 0 || bugCount > 0 ? ', ' : '('}${maintenanceCount} maintenance`}
                  {(passedCount > 0 || bugCount > 0 || maintenanceCount > 0) && ')'}
                </span>
                <Button variant="ghost" size="sm" onClick={() => setShowResolvedWorkflows(!showResolvedWorkflows)} className="h-5 px-1 py-0 text-xs">
                  {showResolvedWorkflows ? 'Hide' : 'Show'} <ChevronRight size={10} className="ml-1" />
                </Button>
              </div>
            )}
            {showResolvedWorkflows &&
              resolvedWorkflows.map((workflow) => <WorkflowItem key={workflow.id} workflow={workflow} onStatusChange={handleStatusChange} />)}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

interface IssueCardProps {
  issues: Issue[];
  onRetry: (issueId: string, workflowId: string) => void;
  onRetryAll: (issueId: string) => void;
  onOverrideBaseline: (issueId: string, workflowId: string) => void;
  networkScore: number;
  onAllIssuesResolved: () => void;
}

export const IssueCard: React.FC<IssueCardProps> = ({ issues, onRetry, onRetryAll, onOverrideBaseline, networkScore, onAllIssuesResolved }) => {
  const { /** workflows, updateWorkflowStatus, retryWorkflow, */ retryAllFailedWorkflows, overrideBaseline } = useWorkflow();
  const [updatedIssues, setUpdatedIssues] = useState<Issue[]>(() => {
    const baseIssues = issues.filter((issue) => issue.type !== 'concurrency-review');
    const concurrencyReviewIssue = issues.find((issue) => issue.type === 'concurrency-review');

    if (networkScore < 90 || concurrencyReviewIssue) {
      return [
        ...baseIssues,
        concurrencyReviewIssue || {
          id: 'concurrency-review',
          type: 'concurrency-review' as const,
          status: 'open',
          title: 'Review Concurrency Limits',
          description: 'Review and adjust concurrency limits',
          workflows: [],
        },
      ];
    }
    return baseIssues;
  });

  const [activeTab, setActiveTab] = useState<'open' | 'resolved'>('open');
  const [resolvingIssues, setResolvingIssues] = useState<Set<string>>(new Set());

  useEffect(() => {
    setUpdatedIssues((prevIssues) => {
      const baseIssues = issues.filter((issue) => issue.type !== 'concurrency-review');
      const concurrencyReviewIssue = issues.find((issue) => issue.type === 'concurrency-review');

      if (networkScore < 90 || concurrencyReviewIssue) {
        return [
          ...baseIssues,
          concurrencyReviewIssue || {
            id: 'concurrency-review',
            type: 'concurrency-review' as const,
            status: 'open',
            title: 'Review Concurrency Limits',
            description: 'Review and adjust concurrency limits',
            workflows: [],
          },
        ];
      }
      console.log(prevIssues);
      return baseIssues;
    });
  }, [issues, networkScore]);

  useEffect(() => {
    const allResolved = updatedIssues.every((issue) => issue.status === 'resolved');
    if (allResolved) {
      onAllIssuesResolved();
    }
  }, [updatedIssues, onAllIssuesResolved]);

  // const handleRetry = (issueId: string, workflowId: string) => {
  //   retryWorkflow(workflowId);
  //   onRetry(issueId, workflowId);
  // };

  const handleRetryAll = useCallback(
    (issueId: string) => {
      console.log('handleRetryAll', issueId);
    },
    [retryAllFailedWorkflows, onRetryAll],
  );

  const handleResolveIssue = useCallback((issueId: string) => {
    setResolvingIssues((prev) => new Set(prev).add(issueId));

    setTimeout(() => {
      setUpdatedIssues((prevIssues) =>
        prevIssues.map((issue) => {
          if (issue.id === issueId) {
            const updatedWorkflows = issue.workflows?.map((workflow) => ({
              ...workflow,
              status: 'passed' as WorkflowStatus,
            }));
            return { ...issue, status: 'resolved', workflows: updatedWorkflows };
          }
          return issue;
        }),
      );
      setResolvingIssues((prev) => {
        const newSet = new Set(prev);
        newSet.delete(issueId);
        return newSet;
      });
    }, 2000);
  }, []);

  const handleOverrideBaseline = useCallback(
    (issueId: string, workflowId: string) => {
      console.log('handleOverrideBaseline', issueId, workflowId);
    },
    [overrideBaseline, onOverrideBaseline],
  );

  const handleResolveBugWorkflow = useCallback((issueId: string, workflowId: string) => {
    setUpdatedIssues((prevIssues) =>
      prevIssues.map((issue) => {
        if (issue.id === issueId) {
          const updatedWorkflows = issue.workflows?.map((workflow) =>
            workflow.id === workflowId ? { ...workflow, status: 'passed' as WorkflowStatus } : workflow,
          );
          const allResolved = updatedWorkflows?.every((wf) => wf.status === 'passed' || wf.baselineOverride);

          if (allResolved) {
            setResolvingIssues((prev) => new Set(prev).add(issueId));
            setTimeout(() => {
              setUpdatedIssues((prevIssues) => prevIssues.map((i) => (i.id === issueId ? { ...i, status: 'resolved' } : i)));
              setResolvingIssues((prev) => {
                const newSet = new Set(prev);
                newSet.delete(issueId);
                return newSet;
              });
            }, 2000);
          }

          return {
            ...issue,
            workflows: updatedWorkflows,
          };
        }
        return issue;
      }),
    );
  }, []);

  const sortedIssues = useMemo(() => {
    return [...updatedIssues].sort((a, b) => {
      if (a.type === 'historic-failure') return -1;
      if (b.type === 'historic-failure') return 1;
      return 0;
    });
  }, [updatedIssues]);

  const { openIssues, resolvedIssues } = useMemo(() => {
    return {
      openIssues: sortedIssues.filter((issue) => issue.status === 'open'),
      resolvedIssues: sortedIssues.filter((issue) => issue.status === 'resolved'),
    };
  }, [sortedIssues]);

  const handleSetIssue = useCallback((issueId: string, updater: (prevIssue: Issue) => Issue) => {
    setUpdatedIssues((prevIssues) => {
      const newIssues = prevIssues.map((issue) => (issue.id === issueId ? updater(issue) : issue));
      return newIssues;
    });
  }, []);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-2">
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'open' | 'resolved')} className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="open">
              Open Issues ({openIssues.length}){resolvingIssues.size > 0 && ` (${resolvingIssues.size} resolving)`}
            </TabsTrigger>
            <TabsTrigger value="resolved">Resolved Issues ({resolvedIssues.length})</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'open' | 'resolved')} className="w-full">
        <TabsContent value="open">
          {sortedIssues
            .filter((issue) => issue.status === 'open')
            .map((issue) => (
              <div key={issue.id} className={resolvingIssues.has(issue.id) ? 'animate-pulse' : ''}>
                {issue.type === 'historic-failure' ? (
                  <IssueSummary
                    key={issue.id}
                    issue={issue}
                    setIssue={(updater) => handleSetIssue(issue.id, updater)}
                    onRetry={(workflowId) => onRetry(issue.id, workflowId)}
                    onRetryAll={() => handleRetryAll(issue.id)}
                    onOverrideBaseline={(workflowId) => handleOverrideBaseline(issue.id, workflowId)}
                    onResolve={handleResolveIssue}
                  />
                ) : issue.type === 'concurrency-review' ? (
                  <ConcurrencyReviewCard title={issue.title} onResolve={() => handleResolveIssue(issue.id)} isResolved={false} />
                ) : issue.type === 'clean up failure' ? (
                  <FailedCleanupCard
                    key={issue.id}
                    title={issue.title}
                    description={issue.description || ''}
                    workflows={issue.workflows || []}
                    onResolve={() => handleResolveIssue(issue.id)}
                    isResolved={issue.status === 'resolved'}
                  />
                ) : issue.type === 'bug' ? (
                  <BugReproductionCard
                    title={issue.title}
                    description={issue.description || ''}
                    workflows={issue.workflows || []}
                    onOverrideBaseline={(workflowId) => handleOverrideBaseline(issue.id, workflowId)}
                    onResolve={(workflowId) => handleResolveBugWorkflow(issue.id, workflowId)}
                    onAllResolved={() => handleResolveIssue(issue.id)}
                  />
                ) : (
                  <IssueSummary
                    key={issue.id}
                    issue={issue}
                    setIssue={(updater) => handleSetIssue(issue.id, updater)}
                    onRetry={(workflowId) => onRetry(issue.id, workflowId)}
                    onRetryAll={() => handleRetryAll(issue.id)}
                    onOverrideBaseline={(workflowId) => handleOverrideBaseline(issue.id, workflowId)}
                    onResolve={handleResolveIssue}
                  />
                )}
              </div>
            ))}
        </TabsContent>
        <TabsContent value="resolved">
          {sortedIssues
            .filter((issue) => issue.status === 'resolved')
            .map((issue) =>
              issue.type === 'concurrency-review' ? (
                <ConcurrencyReviewCard
                  key={issue.id}
                  title={issue.title}
                  onResolve={() => {
                    console.log('Resolve concurrency review');
                  }}
                  isResolved={true}
                />
              ) : issue.type === 'clean up failure' ? (
                <FailedCleanupCard
                  key={issue.id}
                  title={issue.title}
                  description={issue.description || ''}
                  workflows={issue.workflows || []}
                  onResolve={() => {
                    console.log('Resolve clean up failure');
                  }}
                  isResolved={issue.status === 'resolved'}
                />
              ) : issue.type === 'bug' ? (
                <BugReproductionCard
                  key={issue.id}
                  title={issue.title}
                  description={issue.description || ''}
                  workflows={issue.workflows || []}
                  onOverrideBaseline={() => {
                    console.log('Override baseline');
                  }}
                  onResolve={() => {
                    console.log('Resolve bug workflow');
                  }}
                  onAllResolved={() => {
                    console.log('Resolve issue summary');
                  }}
                />
              ) : (
                <IssueSummary
                  key={issue.id}
                  issue={issue}
                  setIssue={(updater) => handleSetIssue(issue.id, updater)}
                  onRetry={(workflowId) => onRetry(issue.id, workflowId)}
                  onRetryAll={() => handleRetryAll(issue.id)}
                  onOverrideBaseline={(workflowId) => handleOverrideBaseline(issue.id, workflowId)}
                  onResolve={() => {
                    console.log('Resolve issue summary');
                  }}
                />
              ),
            )}
        </TabsContent>
      </Tabs>
    </div>
  );
};
