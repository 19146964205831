import type React from "react";
import { useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Settings } from "lucide-react";

type TeamMember = {
  name: string
  role: string
}

export const TeamConfigSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([
    { name: "John Doe", role: "QA Manager" },
    { name: "Jane Smith", role: "QA Lead" },
    { name: "Mike Johnson", role: "CSM" },
  ]);

  const [newMember, setNewMember] = useState<TeamMember>({ name: "", role: "" });

  const handleAddMember = () => {
    if (newMember.name && newMember.role) {
      setTeamMembers([...teamMembers, newMember]);
      setNewMember({ name: "", role: "" });
    }
  };

  const handleRemoveMember = (index: number) => {
    const updatedMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(updatedMembers);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8 p-0">
          <Settings className="h-4 w-4" />
          <span className="sr-only">Open team configuration</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="top" className="w-full">
        <SheetHeader>
          <SheetTitle>Team Configuration</SheetTitle>
        </SheetHeader>
        <div className="mt-4 space-y-4">
          <div className="grid grid-cols-3 gap-4">
            {teamMembers.map((member, index) => (
              <div key={index} className="flex items-center justify-between p-2 border rounded">
                <div>
                  <p className="font-medium">{member.name}</p>
                  <p className="text-sm text-gray-500">{member.role}</p>
                </div>
                <Button variant="ghost" size="sm" onClick={() => handleRemoveMember(index)}>
                  Remove
                </Button>
              </div>
            ))}
          </div>
          <div className="flex space-x-2">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                value={newMember.name}
                onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                placeholder="Enter name"
              />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="role">Role</Label>
              <Input
                type="text"
                id="role"
                value={newMember.role}
                onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                placeholder="Enter role"
              />
            </div>
            <Button className="mt-auto" onClick={handleAddMember}>
              Add Member
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

