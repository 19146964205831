// React imports
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// Material UI imports
import { Box, CardMedia, Tabs, Tab, Typography } from '@mui/material';

// File imports
import MonacoEditor from './MonacoEditor/MonacoEditor';
import EmailEditor from './EmailSection/EmailEditor';
import { getCandidateEmails } from '../HiringUtils/api';

// Date imports
import * as dateFns from 'date-fns';
import ChatGPTDecision from './NotesSection/ChatGPTDecision';
import MarkdownPreviewer from './NotesSection/MarkdownPreviewer';


const CandidateDetailPanel = ({ row, data, idx, showTab, height }) => {
  const [tabValue, setTabValue] = useState(idx ? idx : 0);
  const [submissionValue, setSubmissionValue] = useState(idx ? idx : 0);
  const [currRow, setCurrRow] = useState(row.original);
  const { id, candidateId, candidate: { email, submissionCount } } = currRow;
  let candidateMultiSubmissions, sortedSubmissions;

  if (submissionCount) {
    candidateMultiSubmissions = data.allSubmissions.filter((submission) => submission.candidateId === candidateId);

    sortedSubmissions = [...candidateMultiSubmissions].sort(
      (a, b) => new Date(b.dateSubmitted) - new Date(a.dateSubmitted),
    );
  }

  // Fetch additional data for the row
  const { data: emailData, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['rowDetails', id],
    queryFn: () => getCandidateEmails(email),
    enabled: !!id, // Only run the query if id is available
    staleTime: 5 * 60 * 1000,
  });

  const handleTabChange = (event, selectedTab) => {
    setTabValue(idx ? idx : selectedTab);
  };

  const handleSubmissionChange = (event, selectedTab) => {
    setCurrRow(sortedSubmissions[idx ? idx : selectedTab]);
    setSubmissionValue(idx ? idx : selectedTab);
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {submissionCount ?
        (
          <Box
            sx={{
              height: '60vh',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                padding: '12px 16px',
                marginRight: '8px',
              }}
            >
              Submissions
            </div>
            <Tabs
              orientation="vertical"
              value={submissionValue}
              onChange={handleSubmissionChange}
              aria-label="detail-panel-tabs"
              variant="fullWidth"
            >
              {sortedSubmissions.map((submission, idx) => {
                return (
                  <Tab key={idx} label={dateFns.format(submission.dateSubmitted, "MM/dd/yyyy")} />
                );
              })}
            </Tabs>
          </Box>
        ) : ""
      }

      <Box
        className="panelContainer"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 6,
          margin: 0,
          borderRadius: 1,
          height: '70vh',
          width: '100%',
          boxSizing: 'borderBox',
          position: 'relative',
          '@media (max-width: 1320px) or (max-height: 870px)': {
            width: '90%', // Set a smaller width for screens less than 1320px
            height: '60vh',
          },
        }}
      >
        {showTab ?
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="detail-panel-tabs"
            variant="fullWidth"
          >
            <Tab label="Editor" />
            <Tab label="Extra Info" />
            <Tab label="Notes" />
          </Tabs>
          : ""
        }
        {tabValue === 0 && (
          <Box
            className="editorContainer"
            value={tabValue}
            index={0}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: height ? height : 'calc(100% - 48px)',  // 48px accounts for the tab bar
              paddingBottom: '2px',
            }}
          >
            {/* =============================== MONACO EDITOR SECTION =============================== */}
            <Box
              className="monacoContainer"
              sx={{
                boxShadow: 4,
                zIndex: 10,
                marginLeft: '2px',
                width: '100%',
                overflow: 'clip',
                position: 'relative',
              }}
            >
              <MonacoEditor
                fileUrl={
                  currRow.submissionFilePath
                }
                candidateName={{ firstName: currRow.candidate.firstName, lastName: currRow.candidate.lastName }}
              />
            </Box>
            {/* ==================================== EMAIL SECTION ==================================== */}
            <Box
              className="emailContainer"
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <EmailEditor
                candidateInfo={currRow.candidate}
                emailData={emailData}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                error={error}
              />
            </Box>
          </Box>
        )}

        {tabValue === 1 && (
          <Box
            className="extraInfoEmailContainer"
            value={tabValue}
            index={1}
            sx={{
              display: 'flex',
              paddingBottom: '2px',
              height: height ? height : 'calc(100% - 48px)', // 48px accounts for the tab bar
            }}
          >
            {/* =============================== CANDIDATE EXTRA INFO SECTION =============================== */}
            <div
              className='extraInfo flex flex-col p-4 border-r border-gray-700 bg-gray-900 w-[25%] text-white overflow-y-auto'
            >
              <Typography
                variant='h6'
              >
                Candidate Extra Information:
              </Typography>
              <div>
                <div className="p-4 text-gray">
                  <b>First applied:</b> {dateFns.format(new Date(currRow.candidate.firstApplied), "MM/dd/yyyy")}
                </div>
                <div className="p-4 text-gray">
                  <b>Cooldown dates:</b>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                      <b>Take-home:</b> {currRow.candidate.takeHomeCooldown
                        ? dateFns.format(new Date(currRow.candidate.takeHomeCooldown), "MM/dd/yyyy")
                        : 'N/A'}
                    </li>
                    <li>
                      <b>Final-round:</b> {currRow.candidate.finalRoundCooldown
                        ? dateFns.format(new Date(currRow.candidate.finalRoundCooldown), "MM/dd/yyyy")
                        : 'N/A'}
                    </li>
                  </ul>
                </div>
                <div className="p-4 text-gray">
                  <b>Referer:</b> {currRow.refereeName ? currRow.refereeName : "N/A"}
                </div>
                <div className="p-4 text-gray">
                  <b>Institute:</b> {currRow.instituteName ? currRow.instituteName : "N/A"}
                </div>
                {
                  currRow.finalRoundTeam &&
                  <div className="p-4 text-gray">
                    <b>Final Round Team:</b>
                    <div>
                      <a
                        href={`https://app.qawolf.com/${currRow.finalRoundTeam}`}
                        target='_blank'
                        rel="noopener noreferrer"
                        style={{
                          color: '#4544E5',
                          textDecoration: 'underline',
                        }}
                      >Candidate Team</a>

                    </div>
                  </div>
                }
                {
                  currRow.finalRoundDate && (
                    <div>
                      <div className="p-4 text-gray">
                        <b>Interview Level:</b> {currRow.level ? currRow.level : "N/A"}
                      </div>
                      <div className="p-4 text-gray">
                        <b>Interviewer:</b> {currRow.interviewer ? currRow.interviewer : "N/A"}
                      </div>
                      <div className="p-4 text-gray">
                        <b>Stipend Info:</b> 
                        <div>
                          {currRow.candidate.stipendInfo ? currRow.candidate.stipendInfo : "N/A"}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            {/* =============================== LOOM SECTION =============================== */}
            <CardMedia
              component="iframe"
              src={currRow.submissionVideo.replace('share', 'embed')}
              title="title"
              className={'.MuiCardMedia-media'}
              sx={{
                boxShadow: 4,
                width: '100%',
                display: 'flex',
              }}
            />
          </Box>
        )}

        {tabValue === 2 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: '2px',
              height: '100%',
              backgroundColor: 'rgb(17, 24, 39)',
            }}
          >
            <Box
              className="panelTopContainer"
              sx={{
                display: 'flex',
                boxShadow: 6,
                margin: 0,
                borderRadius: 1,
                width: '40%',
                height: '90%',
                paddingLeft: '1rem',
                overflowY: 'auto', // Added for scrollability
              }}
            >
              <ChatGPTDecision row={currRow} />
            </Box>
            <Box
              className=" panelBottomContainer px-4 border-r border-gray-700 bg-gray-900 overflow-auto"
              sx={{
                width: '60%',
                height: '90%',
              }}
            >
              <div className='h-full'>
                <MarkdownPreviewer
                  notes={currRow.notes ? currRow.notes : '*Candidate does not have any notes yet.*'}
                  row={currRow}
                  style={{
                    height: "545px",
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '1rem',
                    fontFamily: 'Arial, sans-serif',
                    width: '100%',
                    color: 'black',
                    '@media (max-width: 1320px) or (max-height: 870px)': {
                      width: '90%', // Set a smaller width for screens less than 1320px
                      maxHeight: '375px',
                    },
                  }}
                />
              </div>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CandidateDetailPanel;
