import { Box, Paper, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Checkbox, IconButton, Chip, Avatar } from '@mui/material';
import { AddCircleOutline, BuildCircleOutlined, Close, North, South } from '@mui/icons-material';
import { Dispatch, SetStateAction, useState, SyntheticEvent } from 'react';
import _ from 'lodash';
import { BlockerListItemType, BlockerListItemTypeWithLabel, SortOption } from '../types';
import { getBlockerLabels, getFilteredBlockers, getSortedBlockers } from '../utils/helpers';
import { DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION } from '../utils/constants';

interface SortAndFilterPanelProps {
  initialBlockers: BlockerListItemType[];
  setBlockers: Dispatch<SetStateAction<BlockerListItemType[]>>;
}

function SortAndFilterPanel({ initialBlockers, setBlockers }: SortAndFilterPanelProps) {
  const uniqueTypeBlockers: BlockerListItemTypeWithLabel[] = _.uniqBy(initialBlockers, 'taskType').map((b) => ({
    ...b,
    label: getBlockerLabels(b).taskType,
  }));
  const uniqueAssigneeBlockers: BlockerListItemTypeWithLabel[] = _.uniqBy(initialBlockers, 'creator.qawId').map((b) => ({
    ...b,
    label: getBlockerLabels(b).assignee,
  }));
  const initialTypeFilters = uniqueTypeBlockers.map((b) => b.taskType);
  const initialAssigneeFilters = uniqueAssigneeBlockers.map((b) => b.creator.qawId);

  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>(initialTypeFilters);
  const [selectedAssigneeFilters, setSelectedAssigneeFilters] = useState<string[]>(initialAssigneeFilters);
  const [sortBy, setSortBy] = useState<SortOption>(DEFAULT_SORT_BY);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(DEFAULT_SORT_DIRECTION);

  const handleTypeFilterChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    setSelectedTypeFilters(selectedValues);
    setBlockers(getFilteredBlockers(initialBlockers, selectedValues, selectedAssigneeFilters));
  };

  const handleAssigneeFilterChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    setSelectedAssigneeFilters(selectedValues);
    setBlockers(getFilteredBlockers(initialBlockers, selectedTypeFilters, selectedValues));
  };

  const handleChipDelete = (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    const { value, chipType } = event.currentTarget.dataset;
    if (chipType === 'type') {
      const newFilters = selectedTypeFilters.filter((t) => t !== value);
      setSelectedTypeFilters(newFilters);
      setBlockers(getFilteredBlockers(initialBlockers, newFilters, selectedAssigneeFilters));
    } else if (chipType === 'assignee') {
      const newFilters = selectedAssigneeFilters.filter((a) => a !== value);
      setSelectedAssigneeFilters(newFilters);
      setBlockers(getFilteredBlockers(initialBlockers, selectedTypeFilters, newFilters));
    }
  };

  const handleSort = (event: SelectChangeEvent<SortOption>) => {
    const value = event.target.value as SortOption;
    setSortBy(value);
    setBlockers((prev) => getSortedBlockers(prev, value, sortDirection));
  };

  const toggleSortDirection = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    setBlockers((prev) => getSortedBlockers(prev, sortBy, newDirection));
  };

  return (
    <Paper
      variant="outlined"
      sx={{ padding: 1.5, mb: 1, display: 'flex', flexDirection: 'column', gap: 1.5, position: 'sticky', top: 0, zIndex: 1000 }}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Box display="flex" alignItems="center" width="100%" gap={0.5}>
          <IconButton size="small" onClick={toggleSortDirection} disabled={!initialBlockers.length}>
            {sortDirection === 'asc' ? <North fontSize="small" /> : <South fontSize="small" />}
          </IconButton>
          <FormControl disabled={!initialBlockers.length} fullWidth>
            <InputLabel id="blocker-sort-label" size="small">
              Sort By
            </InputLabel>
            <Select labelId="blocker-sort-label" size="small" value={sortBy} onChange={handleSort} label="Sort By">
              <MenuItem value="nextFollowUpDate">Follow Up Date</MenuItem>
              <MenuItem value="age">Age</MenuItem>
              <MenuItem value="creatorName">Assignee</MenuItem>
              <MenuItem value="testCount">Test Count</MenuItem>
              <MenuItem value="taskCount">Task Count</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControl disabled={!initialBlockers.length} fullWidth>
          <InputLabel id="blocker-type-filter-label" size="small">
            Task Type
          </InputLabel>
          <Select
            label="Task Type"
            labelId="blocker-type-filter-label"
            size="small"
            multiple
            value={selectedTypeFilters}
            onChange={handleTypeFilterChange}
            renderValue={(selected) => `${selected.length} selected`}
          >
            {uniqueTypeBlockers.map((blocker) => (
              <MenuItem key={blocker.taskType} value={blocker.taskType}>
                <Checkbox checked={selectedTypeFilters.includes(blocker.taskType)} />
                <Chip
                  variant="outlined"
                  key={blocker.taskType}
                  label={blocker.label}
                  icon={blocker.taskType === 'testCreation' ? <AddCircleOutline color="success" /> : <BuildCircleOutlined color="primary" />}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl disabled={!initialBlockers.length} fullWidth>
          <InputLabel id="blocker-assignee-filter-label" size="small">
            Assignee
          </InputLabel>
          <Select
            label="Assignee"
            labelId="blocker-assignee-filter-label"
            size="small"
            multiple
            value={selectedAssigneeFilters}
            onChange={handleAssigneeFilterChange}
            renderValue={(selected) => `${selected.length} selected`}
          >
            {uniqueAssigneeBlockers.map((blocker) => (
              <MenuItem key={blocker.creator.qawId} value={blocker.creator.qawId}>
                <Checkbox checked={selectedAssigneeFilters.includes(blocker.creator.qawId)} />
                <Chip
                  variant="outlined"
                  key={blocker.creator.qawId}
                  label={blocker.label}
                  avatar={<Avatar sx={{ height: 18, width: 18 }} src={blocker.creator.avatar48} />}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        {uniqueTypeBlockers
          .filter((b) => selectedTypeFilters.includes(b.taskType))
          .map((b) => (
            <Chip
              deleteIcon={<Close data-chip-type="type" data-value={b.taskType} />}
              onDelete={handleChipDelete}
              variant="outlined"
              key={b.taskType}
              label={b.label}
              icon={b.taskType === 'testCreation' ? <AddCircleOutline color="success" /> : <BuildCircleOutlined color="primary" />}
            />
          ))}
        {uniqueAssigneeBlockers
          .filter((b) => selectedAssigneeFilters.includes(b.creator.qawId))
          .map((b) => (
            <Chip
              deleteIcon={<Close data-chip-type="assignee" data-value={b.creator.qawId} />}
              onDelete={handleChipDelete}
              variant="outlined"
              key={b.creator.qawId}
              label={b.label}
              avatar={<Avatar sx={{ height: 18, width: 18 }} src={b.creator.avatar48} />}
            />
          ))}
      </Box>
    </Paper>
  );
}

export default SortAndFilterPanel;
