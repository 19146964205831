import { Box } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { QAWTask } from '@/types';
import { getSortedBlockers, getUniqueBlockersFromTasks } from './utils/helpers';
import { DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION } from './utils/constants';
import BlockerListItem from './components/BlockerListItem';
import SortAndFilterPanel from './components/SortAndFilterPanel';

function TaskBlockersView({ tasksQuery }: { tasksQuery: UseQueryResult<QAWTask[]> }) {
  const searchParams = new URLSearchParams(window.location.search);
  const blockerIdFromUrl = Number(searchParams.get('blockerId'));

  // Get initial filtered and sorted blockers
  const tasks = tasksQuery.data || [];
  const uniqueBlockers = getUniqueBlockersFromTasks(tasks);
  let initialSortedBlockers = getSortedBlockers(uniqueBlockers, DEFAULT_SORT_BY, DEFAULT_SORT_DIRECTION);

  // If a blockerId is provided, set the initial sorted blockers to the blocker with the matching id
  if (blockerIdFromUrl) {
    const matchingBlocker = initialSortedBlockers.find((blocker) => blocker.id === blockerIdFromUrl);
    if (matchingBlocker) {
      initialSortedBlockers = [matchingBlocker, ...initialSortedBlockers.filter((blocker) => blocker.id !== matchingBlocker.id)];
    }
  }

  const [blockers, setBlockers] = useState(initialSortedBlockers);
  const [expandedId, setExpandedId] = useState<number | null>(blockerIdFromUrl || null);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <SortAndFilterPanel initialBlockers={uniqueBlockers} setBlockers={setBlockers} />
      <Box>
        {blockers.map((blocker) => (
          <BlockerListItem key={blocker.id} blocker={blocker} tasksQuery={tasksQuery} setBlockers={setBlockers} expandedId={expandedId} setExpandedId={setExpandedId} />
        ))}
      </Box>
    </Box>
  );
}

export default TaskBlockersView;
