import type React from "react";
import { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check, CheckCircle } from "lucide-react";

interface ConcurrencyReviewCardProps {
  title: string
  onResolve: () => void
  isResolved: boolean
}

export const ConcurrencyReviewCard: React.FC<ConcurrencyReviewCardProps> = ({ title, onResolve, isResolved }) => {
  const [reviewed, setReviewed] = useState(isResolved);
  const [isPulsing, setIsPulsing] = useState(false);
  const [isResolving, setIsResolving] = useState(false);

  useEffect(() => {
    if (reviewed && !isResolved) {
      setIsResolving(true);
      setIsPulsing(true);
      const timer = setTimeout(() => {
        setIsPulsing(false);
        onResolve();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [reviewed, isResolved, onResolve]);

  const handleReview = () => {
    if (!isResolved) {
      setReviewed(true);
    }
  };

  return (
    <Card className={`mb-2 ${isPulsing ? "animate-pulse" : ""}`}>
      <CardHeader className="py-3 px-4">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm font-medium flex items-center">
            {(isResolving || isPulsing || isResolved) && <CheckCircle className="w-4 h-4 text-green-500 mr-2" />}
            {title}
          </CardTitle>
          {!reviewed && !isResolved ? (
            <Button variant="outline" size="sm" onClick={handleReview} className="h-6 px-2 py-0 text-xs">
              Review
            </Button>
          ) : (
            <div className="flex items-center text-green-600">
              <Check className="w-4 h-4 mr-1" />
              <span className="text-xs">Reviewed</span>
            </div>
          )}
        </div>
      </CardHeader>
    </Card>
  );
};

