import { useState } from 'react';
import { Blocker, User } from '../types';
import { Avatar, Box, Button, Card, Divider, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import { Content, Header, Inner, StyledDrawer, Title } from './subComponents';
import { ArrowBack, History } from '@mui/icons-material';
import dayjs from 'dayjs';
import { getDescription } from './utils';
import _ from 'lodash';
import MDEditor from '@uiw/react-md-editor';
import { useQueryClient } from '@tanstack/react-query';

export default function MsBlockerActivityLogsDrawer({ milestoneBlocker, closeParents }: { milestoneBlocker: Blocker; closeParents: () => void }) {
  // Set to open if it is the current milestone
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  return (
    <Box>
      <Tooltip title="View Activity Logs">
        <span>
          <IconButton onClick={toggleSelf}>
            <History />
          </IconButton>
        </span>
      </Tooltip>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner sx={{ height: '100vh' }}>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Title text={`Blocker Activity Logs`} />
          </Header>
          <Content sx={{ height: '96vh', overflowY: 'auto', mt: 2 }}>
            <MsBlockerActivityLogsList milestoneBlocker={milestoneBlocker} />
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}

export function MsBlockerActivityLogsList({ milestoneBlocker }: { milestoneBlocker: Blocker }) {
  const queryClient = useQueryClient();
  const qawUsers = queryClient.getQueryData<User[]>(['getAllQAWUsers']);

  const logsGroupedByMinute = Object.values(
    _.groupBy(milestoneBlocker.blockerActivityLogs, (log) => dayjs(log.createdAt).format('YYYY-MM-DDTHH:mm:ss')),
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {logsGroupedByMinute.map((logs) => (
        <Card key={logs[0].id} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box display={'flex'} alignItems={'start'} gap={2} sx={{ p: 2 }}>
            <Avatar src={logs[0].user?.avatar48} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="body2">{logs[0].user?.name}</Typography>
              <Typography variant="caption">{dayjs(logs[0].createdAt).format('MMM D, YYYY h:mm A')}</Typography>
            </Box>
          </Box>
          <Divider />
          <List>
            {logs.map((log, index) => {
              const { primary, secondary } = getDescription(log.type, log.to, log.from, qawUsers ?? []);
              return typeof secondary === 'string' ? (
                <ListItem key={log.id} alignItems="flex-start" divider={index !== logs.length - 1}>
                  <ListItemText primary={primary} secondary={secondary} />
                </ListItem>
              ) : (
                <MDEDescription key={log.id} primary={primary} from={secondary.from} to={secondary.to} />
              );
            })}
          </List>
        </Card>
      ))}
    </Box>
  );
}

function MDEDescription({ primary, from, to }: { primary: string; from: string; to: string }) {
  const [displayText, setDisplayText] = useState(to);
  const toggleText = () => {
    setDisplayText((prev) => (prev === to ? from : to));
  };
  return (
    <Box sx={{ position: 'relative', flexDirection: 'column', p:2 }}>
      <Typography sx={{ mb: 1 }}>{primary}</Typography>
      <MDEditor value={displayText} data-color-mode="light" hideToolbar preview="preview" height={150} color="red" />
      <Button onClick={toggleText} sx={{ position: 'absolute', top: 10, right: 15 }} variant="outlined" disableRipple size="small">
        {displayText === to ? 'Show Original' : 'Show Edited'}
      </Button>
    </Box>
  );
}
