import { Box, Button, IconButton, Typography, Tooltip } from '@mui/material';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CloseRounded } from '@mui/icons-material';
import { sendGetRequest } from '../../../../utils/tanstackNetwork';
import { Content, Header, Inner, StyledDrawer, MsBtnSkeleton, Title } from './subComponents';
import { MsDrawer } from './MsDrawer';
import { CreationDrawer } from './CreationDrawer';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientSummaryTableRow, Milestone } from '../types';
import { MsCreationForm } from './MsCreationForm';
import MsBlockerUpdateDrawer from './MsBlockerUpdateDrawer';
import MsBlockerCreationDrawer from './MsBlockerCreationDrawer';
import MsBlockerPastDrawer from './MsBlockerPastDrawer';
import dayjs from 'dayjs';

export default function MainDrawer({ client }: { client: ClientSummaryTableRow }) {
  // Id for direct link to client milestones and milestone blockers
  const [searchParams] = useSearchParams();
  const milestoneClientId = searchParams.get('milestoneClientId');
  const milestoneBlockerId = searchParams.get('milestoneBlockerId');

  const [isOpen, setIsOpen] = useState(client.id === milestoneClientId);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeSelf = () => {
    setIsOpen(false);
  };

  const mStones: UseQueryResult<Milestone[]> = useQuery({
    queryKey: ['clientSummary', client.id],
    queryFn: () => sendGetRequest(`/client-summaries/${client.id}`),
    select: (data) =>
      data?.milestones?.map((ms: Milestone, i: number) => ({
        ...ms,
        previousMilestone: data?.milestones[i - 1],
        nextMilestone: data?.milestones[i + 1],
      })) || [],
    enabled: isOpen,
  });

  let activeMilestoneName = client.activeMilestone ? client.activeMilestone.name : client.activeMilestoneBlockers.length > 0 ? 'Blocked' : 'N/A';
  const fullMilestoneName = activeMilestoneName;
  if (activeMilestoneName.length > 10) activeMilestoneName = activeMilestoneName.slice(0, 7) + '...';

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <Tooltip title={fullMilestoneName}>
        <span style={{ width: '100%', maxWidth: '10rem' }}>
          <Button
            onClick={toggleSelf}
            variant="contained"
            sx={{ width: '100%', maxWidth: '10rem' }}
            disableRipple
            color={client.activeMilestoneBlockers.length > 0 ? 'error' : 'primary'}
          >
            {activeMilestoneName}
          </Button>
        </span>
      </Tooltip>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeSelf} variant="temporary">
        <Inner>
          <Header>
            <IconButton onClick={closeSelf} disableRipple>
              <CloseRounded />
            </IconButton>
            <Title text={`${client.name} Milestones`} />
          </Header>
          <Content>
            {mStones.isError && <Typography variant="caption">{mStones.error.message}</Typography>}
            {mStones.isLoading && (
              <Box sx={{ mt: '1em' }}>
                {Array.from({ length: 3 }).map((_, i) => (
                  <MsBtnSkeleton key={i} />
                ))}
              </Box>
            )}
            {mStones.isSuccess && (
              <Box sx={{ mt: '1em' }}>
                {/* ----------------------------------------- Milestones ----------------------------------------- */}
                {mStones.data?.sort((a, b) => dayjs(a.startDate).diff(dayjs(b.startDate))).map((milestone) => {
                  return <MsDrawer milestone={milestone} closeParents={closeSelf} key={milestone.id} client={client} />;
                })}

                {/* ----------------------------------------- Create Milestone ----------------------------------------- */}
                {client.activeMilestoneBlockers.length === 0 && (
                  <CreationDrawer text="Create Milestone" closeParents={closeSelf}>
                    <MsCreationForm client={client} milestones={mStones.data} />
                  </CreationDrawer>
                )}

                {/* ----------------------------------------- Create Milestone Blocker ----------------------------------------- */}
                {!client.activeMilestone && <MsBlockerCreationDrawer client={client} closeParents={closeSelf} />}

                {/* ----------------------------------------- Active Milestone Blockers ----------------------------------------- */}
                {client.activeMilestoneBlockers.length > 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: '1em' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', pl: '3em', width: '100%' }}>
                      <Typography variant="h6">Active Milestone Blockers</Typography>
                    </Box>
                    {client.activeMilestoneBlockers .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt))).map((blocker, i) => {
                      const initialIsOpen = i === 0 || Number(milestoneBlockerId) === blocker.id;
                      return (
                        <MsBlockerUpdateDrawer milestoneBlocker={blocker} closeParents={closeSelf} key={blocker.id} initialIsOpen={initialIsOpen} />
                      );
                    })}
                  </Box>
                )}

                {/* ----------------------------------------- Past Milestone Blockers ----------------------------------------- */}
                {client.pastMilestoneBlockers.length > 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: '1em' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', pl: '3em', width: '100%' }}>
                      <Typography variant="h6">Past Milestone Blockers</Typography>
                    </Box>
                    {/* Sort by creation date descending */}
                    {client.pastMilestoneBlockers
                      .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
                      .map((blocker) => {
                        const initialIsOpen = Number(milestoneBlockerId) === blocker.id;
                        return (
                          <MsBlockerPastDrawer milestoneBlocker={blocker} closeParents={closeSelf} key={blocker.id} initialIsOpen={initialIsOpen} />
                        );
                      })}
                  </Box>
                )}
              </Box>
            )}
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
