import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Chip, Tooltip, Typography } from '@mui/material';
import { AddCircleOutline, BuildCircleOutlined, Edit, ExpandMore } from '@mui/icons-material';
import { BlockerListItemType } from '../types';
import { getBlockerLabels, getChipColor } from '../utils/helpers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import BlockerEditorModal from '@/components/shared/BlockerEditorModal';
import QAWTask from '@/types';
import { UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import React from 'react';

function BlockerListItem({
  blocker,
  tasksQuery,
  setBlockers,
  expandedId,
  setExpandedId,
}: {
  blocker: BlockerListItemType;
  tasksQuery: UseQueryResult<QAWTask[]>;
  setBlockers: Dispatch<SetStateAction<BlockerListItemType[]>>;
  expandedId: number | null;
  setExpandedId: Dispatch<SetStateAction<number | null>>;
}) {
  const expanded = expandedId === blocker.id;
  const [labels, setLabels] = useState(getBlockerLabels(blocker));
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    // Find the cached blocker in the tasksQuery data
    const taskWithUpdatedBlocker = tasksQuery.data?.flatMap((t) => t.childTasks).find((t) => t?.blocker?.id === blocker.id);
    const cachedBlocker = taskWithUpdatedBlocker?.blocker;

    if (cachedBlocker) {
      // Update the blocker with the cached blocker's values (daysUntilFollowUp may need to be updated)
      const updatedBlocker = {
        ...blocker,
        ...cachedBlocker,
        daysUntilFollowUp: Math.floor(dayjs(cachedBlocker.nextFollowUpDate).diff(dayjs(), 'day', true)),
      };

      // Update the blockers array and the blocker labels
      setBlockers((prev) => prev.map((b) => (b.id === blocker.id ? updatedBlocker : b)));
      setLabels(getBlockerLabels(updatedBlocker));
    }
  }, [tasksQuery.data]);

  const handleOpen = () => {
    setExpandedId((prev) => (prev === blocker.id ? null : blocker.id));
  };

  const handleEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsEditModalOpen(true);
  };

  return (
    <Accordion key={blocker.id} disableGutters expanded={expanded} onChange={handleOpen}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" flexDirection="column" gap={1} width="100%">
          <Box display="flex" gap={1.5} alignItems="center" width="100%">
            <Typography variant="h6" fontWeight="bold" noWrap={!expanded} textOverflow="ellipsis" width={600}>
              {blocker.title}
            </Typography>
          </Box>
          <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
            <Tooltip title={`Task Type: ${blocker.taskType === 'testCreation' ? 'Test Creation' : 'Test Maintenance'}`}>
              {blocker.taskType === 'testCreation' ? <AddCircleOutline color="success" /> : <BuildCircleOutlined color="primary" />}
            </Tooltip>
            <Tooltip title={`Assigned to ${blocker.creator.name}`}>
              <Avatar src={blocker.creator.avatar48} sx={{ height: 22, width: 22, flexShrink: 0 }} />
            </Tooltip>
            <Chip size="small" label={labels.testCount} color={getChipColor(blocker.affectedTestCount, [0, 5], [6, 10])} />
            <Chip size="small" label={labels.taskCount} color={getChipColor(blocker.taskIds.length, [0, 5], [5, 10])} />
            <Tooltip title={`Created ${dayjs(blocker.createdAt).format('MMM D, YYYY')}`}>
              <Chip size="small" label={labels.age} color={getChipColor(blocker.ageInDays, [0, 14], [15, 30])} />
            </Tooltip>
            <Tooltip title={`Follow up due ${dayjs(blocker.nextFollowUpDate).format('MMM D, YYYY')}`}>
              <Chip
                size="small"
                label={labels.followUpStatus}
                color={typeof blocker.daysUntilFollowUp === 'number' ? getChipColor(blocker.daysUntilFollowUp, [8, Infinity], [0, 7]) : 'error'}
              />
            </Tooltip>
            {expanded && <Chip size="small" onClick={handleEditClick} color="info" icon={<Edit sx={{ height: 15, width: 15 }} />} label="Edit" />}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <MDEditor
          value={blocker.description}
          preview="preview"
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              // Makes links open in new tab and allow Slack app communication
              a: ({ children, ...props }) => (
                <a {...props} target="_blank" rel="opener">
                  {children}
                </a>
              ),
            },
          }}
          height={170}
          data-color-mode="light"
          hideToolbar
        />
      </AccordionDetails>
      <BlockerEditorModal blocker={blocker} open={isEditModalOpen} setOpen={setIsEditModalOpen} />
    </Accordion>
  );
}

export default BlockerListItem;
