import { Button } from '@/components/ui/button';
import type { InternalResource } from '../types/investigation';
import type React from 'react';

type ResourceSectionProps = {
  title: string;
  icon: React.ReactNode;
  resources: InternalResource[];
};

export function ResourceSection({ title, icon, resources }: ResourceSectionProps) {
  if (resources.length === 0) return null;

  return (
    <div>
      <h4 className="text-sm font-semibold mb-2 flex items-center">
        {icon}
        <span className="ml-2">{title}</span>
      </h4>
      <div className="grid grid-cols-1 gap-2">
        {resources.map((resource) => (
          <Button key={resource.id} variant="outline" className="justify-start text-gray-700 hover:text-gray-900" asChild>
            <a href={resource.link} target="_blank" rel="noopener noreferrer">
              <span className="truncate">{resource.title}</span>
            </a>
          </Button>
        ))}
      </div>
    </div>
  );
}
