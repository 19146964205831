import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";

export function InternalLinks() {
  return (
    <div className="space-y-2">
      <Button variant="outline" className="w-full justify-start" asChild>
        <a href="https://talk-wolf-flow-explorer.internal" target="_blank" rel="noopener noreferrer">
          Talk Wolf Flow Explorer
          <ExternalLink className="ml-2 h-4 w-4" />
        </a>
      </Button>
      <Button variant="outline" className="w-full justify-start" asChild>
        <a href="https://network-report.internal" target="_blank" rel="noopener noreferrer">
          Network Report
          <ExternalLink className="ml-2 h-4 w-4" />
        </a>
      </Button>
      <Button variant="outline" className="w-full justify-start" asChild>
        <a href="https://ai-summary-graph.internal" target="_blank" rel="noopener noreferrer">
          AI Summary Graph
          <ExternalLink className="ml-2 h-4 w-4" />
        </a>
      </Button>
    </div>
  );
}

