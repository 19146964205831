/* eslint-disable eol-last */
import { updateCandidateSubmission, updateCandidateInfo, archiveSubmissions, unarchiveSingleSubmission } from '../HiringUtils/api';
import { archivedSubmissionStatusMap } from './StatusConstants';
import { useMutation, useQueryClient } from '@tanstack/react-query';


// Handle submission changes for a specific row
export function useHandleSubmissionChange(setValue) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ submissionId, newInfo, row }) => {
      await updateCandidateSubmission({
        submission: { id: submissionId, ...newInfo },
      });


      if (archivedSubmissionStatusMap.includes(newInfo.status)) {
        const submissionsToBeArchived = row.original.id;
        await archiveSubmissions({ ids: [submissionsToBeArchived] });
      } if (!archivedSubmissionStatusMap.includes(newInfo.status)) {
        const submissionsToBeUnarchived = row.original.id;
        await unarchiveSingleSubmission({ id: submissionsToBeUnarchived });

      }

      // Handle archiving logic here...
      return { submissionId, newInfo };
    },
    onMutate: async ({ submissionId, newInfo }) => {
      await queryClient.cancelQueries(['combinedData']);
      const previousCombinedData = queryClient.getQueryData(['combinedData']);

      queryClient.setQueryData(['combinedData'], (oldData) => {
        if (!oldData || !oldData.activeSubmissions) return oldData;
        return {
          ...oldData,
          activeSubmissions: oldData.activeSubmissions.map((submission) =>
            submission.id === submissionId
              ? { ...submission, ...newInfo }
              : submission,
          ),
        };
      });



      // Optionally update local state (if needed)
      setValue((prev) => ({
        ...prev,
        [submissionId]: {
          ...prev[submissionId],
          ...newInfo,
        },
      }));

      return { previousCombinedData };
    },
    onError: (error, variables, context) => {
      if (context?.previousCombinedData) {
        queryClient.setQueryData(['combinedData'], context.previousCombinedData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['combinedData']);
    },
  });

  return mutation.mutate;
}


// Handle submission changes for a specific row
export function useHandleCandidateInfoChange() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    {
      mutationKey: ['singleCandidateMutation'],
      mutationFn: async (newCandidateData) => {

        await updateCandidateInfo(newCandidateData);
      },
      onError: (error, variables, context) => {
        // Rollback on error
        if (context?.previousCandidate) {
          queryClient.setQueryData(context.previousCandidate);
        }
        console.error('Error updating candidate info', error.message);

      },
      onSuccess: () => {
        // Optionally update state or show success feedback
        console.log('Candidate updated successfully');
      },
      onSettled: () => {
        queryClient.invalidateQueries(['combinedData']);
      },
    },
  );
  return mutation.mutate;
}

/*
 * Separates a camelCase or PascalCase string into individual words 
 * and capitalizes the first letter of each word.
 *
 * @param {string} str - The input string in camelCase or PascalCase.
 * @returns {string} - The separated and capitalized string with spaces.
 */
export function separateAndCapitalizeWords(str) {
  if (typeof str !== 'string') {
    throw new TypeError('Input must be a string');
  }

  // Insert space before uppercase letters that follow lowercase letters or numbers
  let separated = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

  // Insert space before sequences where a single uppercase letter is followed by another uppercase and then a lowercase letter
  separated = separated.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

  // Capitalize the first letter of each word
  separated = separated.replace(/\b\w/g, (char) => char.toUpperCase());

  return separated;
}


export const CustomTooltip = ({ active, payload, label, totalCount, finalRound, filters }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip" style={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid #ccc',
        padding: '10px',
      }}>
        <p className="label underline italic font-semibold">{separateAndCapitalizeWords(label)}</p>
        <div className="custom-tooltip">
          {filters.source === "All" ?
            payload
              .filter((entry) => entry.value !== 0 && entry.dataKey)
              .map((entry, index) => (
                <p key={index} style={{ color: entry.color }}>
                  {finalRound ?
                    `${entry.dataKey}: ${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} interviews`
                    :
                    `${entry.dataKey}: ${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} submissions`
                  }
                </p>
              ))
            :
            payload
              .filter((entry) => entry.value !== 0 && entry.dataKey)
              .map((entry, index) => (
                <p key={index} style={{ color: entry.color }}>
                  {finalRound ?
                    `${((entry.value / totalCount) * 100).toFixed(0)}% of ${entry.dataKey} interviews`
                    :
                    `${((entry.value / totalCount) * 100).toFixed(0)}% of ${filters.source} submissions`
                  }
                </p>
              ))
          }
        </div>

      </div>
    );
  }

  return null;
};

export const handleArchiveSubmissions = async (rows, table, setToastData, queryClient) => {
  let submissionsToBeArchived = rows.map((row) => row.original.id);
  let result = await archiveSubmissions({ ids: submissionsToBeArchived });

  let { success } = result;

  if (!success) {
    console.log(result);
  }

  let title = success ? 'Success!' : 'Ruh-roh';
  let message = success
    ? `Submission(s) archived.`
    : 'Something went wrong! Unable to archive submission(s).';

  setToastData({ title, message, isSuccess: success });

  await queryClient.refetchQueries({ queryKey: ['combinedData'] });
  await table.resetRowSelection();
};

export function highlightText(text = '', searchValue = '') {
  if (!searchValue) return text;

  // Case-insensitive split
  const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));

  return parts.map((part, i) =>
    part.toLowerCase() === searchValue.toLowerCase() ? (
      <mark key={i}>{part}</mark>
    ) : (
      part
    ),
  );
}