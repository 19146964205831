import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  InputLabel,
  IconButton,
  Select,
  Snackbar,
  Tooltip,
  Avatar,
  MenuItem,
} from '@mui/material';
import { NoInputDatePicker } from './subComponents';
import dayjs, { Dayjs } from 'dayjs';
import { FormEvent, useState } from 'react';
import { styles } from './utils';
import { Blocker, ClientSummaryTableRow, MilestoneBlockerCategory, User } from '../types';
import { Edit, Preview } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendPutRequest } from '@/utils/tanstackNetwork';
import { milestoneBlockerCategoryMap } from './utils';

const today = dayjs();

type MilestoneBlockerUpdateReqBody = {
  description: string;
  nextFollowUpDate: Dayjs;
  assigneeId: string;
  isResolved: boolean;
  milestoneBlockerCategory: MilestoneBlockerCategory;
};

function hasFormChanged(prev: Blocker, curr: MilestoneBlockerUpdateReqBody) {
  return (
    prev.milestoneBlockerCategory !== curr.milestoneBlockerCategory ||
    prev.description !== curr.description ||
    !dayjs(prev.nextFollowUpDate).isSame(curr.nextFollowUpDate) ||
    !!prev.deletedAt !== !!curr.isResolved ||
    prev.creatorId !== curr.assigneeId
  );
}

export function MsBlockerUpdateForm({
  milestoneBlocker,
  isPreview,
  setIsPreview,
}: {
  milestoneBlocker: Blocker;
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
}) {
  const queryClient = useQueryClient();

  const qawUsers = queryClient.getQueryData<User[]>(['getAllQAWUsers']);

  const [showResMsg, setShowResMsg] = useState(false);
  const [values, setValues] = useState({
    description: milestoneBlocker.description,
    nextFollowUpDate: dayjs(milestoneBlocker.nextFollowUpDate),
    assigneeId: milestoneBlocker.creatorId,
    isResolved: false,
    milestoneBlockerCategory: milestoneBlocker?.milestoneBlockerCategory ?? 'other',
  });
  const [errors, setErrors] = useState({
    description: '',
    nextFollowUpDate: '',
    assigneeId: '',
    milestoneBlockerCategory: '',
  });

  const mutation = useMutation({
    mutationKey: ['update-milestone-blocker'],
    mutationFn: (body: MilestoneBlockerUpdateReqBody) => sendPutRequest(`/milestones/${milestoneBlocker.id}/update-blocker`, body),
    onSettled: () => {
      setShowResMsg(true);
    },
    onSuccess: (resData: Blocker) => {
      // Update the cached client summaries if the blocker was not resolved
      if (!resData.deletedAt) {
        queryClient.setQueriesData(
          {
            queryKey: ['clientSummaries'],
            exact: false,
          },
          (prev?: ClientSummaryTableRow[]) => updateClientSummaryCache(resData, prev),
        );
      }

      // Set preview back to true
      setIsPreview(true);
    },
  });

  const updateValues = (key: string, val: string | Dayjs | boolean) => {
    setValues((prev) => ({ ...prev, [key]: val }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrors({
      description: values.description.length > 0 ? '' : 'Description is required',
      nextFollowUpDate: values.nextFollowUpDate ? '' : 'Next follow up date is required',
      assigneeId: values.assigneeId ? '' : 'Assignee is required',
      milestoneBlockerCategory: values.milestoneBlockerCategory ? '' : 'Blocker category is required',
    });

    if (errors.description || errors.nextFollowUpDate || errors.assigneeId || errors.milestoneBlockerCategory) {
      return;
    }

    mutation.mutate({
      description: values.description,
      nextFollowUpDate: values.nextFollowUpDate,
      isResolved: values.isResolved,
      assigneeId: values.assigneeId,
      milestoneBlockerCategory: values?.milestoneBlockerCategory ?? 'other',
    });
  };

  return (
    <Box sx={{ p: '1em 4em 1em 4em', display: 'flex', flexDirection: 'column', gap: 2, ...styles }} component="form" onSubmit={handleSubmit}>
      <FormControl>
        <InputLabel id="milestone-blocker-category-label">Blocker Category</InputLabel>
        <Select
          labelId="milestone-blocker-category-label"
          label="Blocker Category"
          value={values.milestoneBlockerCategory}
          onChange={(e) => updateValues('milestoneBlockerCategory', e.target.value)}
        >
          {Object.entries(milestoneBlockerCategoryMap).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ position: 'relative', width: '100%', height: 'max-content' }}>
        <MDEditor
          value={values.description}
          onChange={(val?: string) => {
            updateValues('description', val ?? '');
          }}
          preview={isPreview ? 'preview' : 'edit'}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
            components: {
              // Makes links open in new tab and allow Slack app communication
              a: ({ children, ...props }) => (
                <a {...props} target="_blank" rel="opener">
                  {children}
                </a>
              ),
            },
          }}
          height={350}
          data-color-mode="light"
          textareaProps={{
            placeholder: 'Enter details about the blocking issue and include links to follow up threads, etc.',
          }}
          style={{
            boxSizing: 'border-box',
            outline: errors.description ? '1px solid red' : 'none',
          }}
          hideToolbar={isPreview}
        />
        <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
          <Tooltip title={isPreview ? 'Edit' : 'Preview'}>
            <span>
              <IconButton onClick={() => setIsPreview(!isPreview)} disableRipple>
                {isPreview ? <Edit sx={{ fontSize: '1.2em' }} /> : <Preview sx={{ fontSize: '1.2em' }} />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      <NoInputDatePicker
        label="Next Follow Up Date"
        minDate={today}
        value={values.nextFollowUpDate}
        onChange={(val: Dayjs) => updateValues('nextFollowUpDate', val)}
        required
        helperText={errors.nextFollowUpDate}
        error={!!errors.nextFollowUpDate}
        slotProps={{ textField: { sx: { width: '100%' } } }}
      />

      <FormControl>
        <InputLabel id="assignee-label">Assignee</InputLabel>
        <Select
          size="small"
          labelId="assignee-label"
          label="Assignee"
          value={values.assigneeId}
          onChange={(e) => updateValues('assigneeId', e.target.value)}
          renderValue={(val) => {
            const user = qawUsers?.find((user) => user.qawId === val);
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar src={user?.avatar48} />
                <ListItemText primary={user?.name} />
              </Box>
            );
          }}
        >
          {qawUsers?.map((user) => (
            <MenuItem key={user.qawId} value={user.qawId} sx={{ flexDirection: 'row', gap: 1 }}>
              <Avatar src={user.avatar48} />
              <ListItemText primary={user.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Checkbox checked={values.isResolved} onChange={(e) => updateValues('isResolved', e.target.checked)} />}
        label="Mark as resolved"
      />

      <Button type="submit" variant="contained" disabled={mutation.isPending || !hasFormChanged(milestoneBlocker, values)}>
        Update Blocker
      </Button>

      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => {
          setShowResMsg(false);
          // If the blocker was resolved, update the client summary cache
          if (mutation.data && mutation.data.deletedAt) {
            queryClient.setQueriesData(
              {
                queryKey: ['clientSummaries'],
                exact: false,
              },
              (prev?: ClientSummaryTableRow[]) => updateClientSummaryCache(mutation.data, prev),
            );
          }
        }}
        message={
          !mutation.isError
            ? '🎉 Updated milestone blocker successfully!'
            : "⛔️ Couldn't update milestone blocker, please reach out in #dragons for assistance."
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}

function updateClientSummaryCache(updatedBlocker: Blocker, prev?: ClientSummaryTableRow[]) {
  if (!prev) return;
  return prev.map((clientSummary) => {
    // Find the client summary that matches the milestone blocker's customer id
    if (clientSummary.id === updatedBlocker.customerId) {
      // If the milestone blocker is resolved, remove it from the active milestone blockers and add it to the past milestone blockers
      if (updatedBlocker.deletedAt) {
        return {
          ...clientSummary,
          activeMilestoneBlockers: clientSummary.activeMilestoneBlockers.filter((blocker) => blocker.id !== updatedBlocker.id),
          pastMilestoneBlockers: [...clientSummary.pastMilestoneBlockers, updatedBlocker],
        };
      }
      // If the milestone blocker is not resolved, update it
      return {
        ...clientSummary,
        activeMilestoneBlockers: clientSummary.activeMilestoneBlockers.map((blocker) =>
          blocker.id === updatedBlocker.id ? updatedBlocker : blocker,
        ),
      } as ClientSummaryTableRow;
    }
    // If the client summary does not match the milestone blocker's customer id, return the client summary unchanged
    return clientSummary;
  });
}
