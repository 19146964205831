import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';

export function getSince(diff) {
  if (diff === 0) return `0 minutes ago`;

  if (diff === 1) return `1 minute ago`;

  if (diff < 60) return `${diff} minutes ago`;

  if (diff === 60) return `1 hour ago`;

  if (diff < 1440) return `${Math.floor(diff / 60)} hours ago`;

  if (diff < 2880) return `1 day ago`;

  return `${Math.floor(diff / 1440)} days ago`;
}

export function getElapsed(date) {
  const diff = differenceInMinutes(new Date(), date);
  return getSince(diff);
}

export function plural(n) {
  if (n === 1) return '';
  else return 's';
}

export function thisOrThese(n, capitalize = false) {
  if (n === 1) return capitalize ? 'This' : 'this';
  else return capitalize ? 'These' : 'these';
}
export function isOrAre(n) {
  if (n === 1) return 'is';
  else return 'are';
}

export function hasOrHave(n) {
  if (n === 1) return 'has';
  else return 'have';
}

export function capitalize(text) {
  if (!text.length) return text;

  return text[0].toUpperCase() + text.slice(1);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function getBackgroundColor(parsedInfo) {
  if (parsedInfo.priority === 'high') {
    if (parsedInfo.type === 'generic') {
      if (parsedInfo.subType === 'message') return 'bg-purple-300';
      if (parsedInfo.subType === 'projectPlan') return 'bg-green-200';
      return 'bg-orange-200';
    }

    if (parsedInfo.type === 'promotion') {
      return 'bg-yellow-300';
    }

    return 'bg-red-300';
  }

  if (parsedInfo.isCurrentUser) {
    return 'bg-blue-100';
  }

  if (parsedInfo.priority === 'medium') {
    if (parsedInfo.type === 'generic') {
      if (parsedInfo.subType === 'message') return 'bg-purple-200';
      if (parsedInfo.subType === 'projectPlan') return 'bg-green-200';
      return 'bg-orange-200';
    }

    if (parsedInfo.type === 'promotion') {
      return 'bg-yellow-200';
    }

    return 'bg-red-200';
  }

  if (parsedInfo.type === 'generic') {
    if (parsedInfo.subType === 'message') return 'bg-purple-100';
    if (parsedInfo.subType === 'projectPlan') return 'bg-green-100';
    return 'bg-orange-100';
  }

  if (parsedInfo.type === 'promotion') {
    return 'bg-yellow-200';
  }

  return 'bg-gray-100';
}

/**
 * Calculates triage statistics based on the provided parameters.
 * @param {Array} teamsToShow - The teams to show.
 * @param {import('../../types').QAWTask[]} allTriageTasks - The cards.
 * @param {import('../../types').QAWTask[]} toDoTriageTasks - The cards to show.
 * @param {import('../../types').QAWTask[]} inProgressTriageTasks - The cards to show.
 * @param {import('../../types').QAWTask[]} messageTasks - The message tasks.
 * @returns {{stats: Array, groupsSummary: object}} - The triage statistics.
 */
export function getTriageStats(teamsToShow, allTriageTasks, toDoTriageTasks, inProgressTriageTasks, messageTasks) {
  let todoFailures =
    toDoTriageTasks
      .map((x) => x.suite.statusCounts.fail - x.suite.statusCounts.bug - x.suite.statusCounts.maintenance)
      .reduce((x, y) => {
        return x + y;
      }, 0) || 0;

  let inProgressFailures = inProgressTriageTasks
    .map((x) => x.suite.statusCounts.fail - x.suite.statusCounts.bug - x.suite.statusCounts.maintenance)
    .reduce((x, y) => {
      return x + y;
    }, 0);

  let stats = [
    { name: 'Unclaimed Suites:', stat: toDoTriageTasks.length },
    { name: 'Unclaimed Failures:', stat: todoFailures },
    { name: 'In Progress Suites:', stat: inProgressTriageTasks.length },
    { name: 'In Progress Failures:', stat: inProgressFailures },
  ];

  if (teamsToShow.some((x) => x.active === false)) {
    //  There are hidden teams, show hidden counters.
    let hiddenToDoTasks = allTriageTasks.filter((x) => x.status === 'toDo');
    let hiddenInProgressTasks = allTriageTasks.filter((x) => x.status === 'inProgress');

    let hiddenTodoFailures = hiddenToDoTasks
      .map((x) => x.suite.statusCounts.fail - x.suite.statusCounts.bug - x.suite.statusCounts.maintenance)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    let hiddenInProgressFailures = hiddenInProgressTasks
      .map((x) => x.suite.statusCounts.fail - x.suite.statusCounts.bug - x.suite.statusCounts.maintenance)
      .reduce((x, y) => {
        return x + y;
      }, 0);

    stats = [
      {
        name: 'Unclaimed Suites',
        stat: `${toDoTriageTasks.length} (${hiddenToDoTasks.length - toDoTriageTasks.length} filtered)`,
      },
      {
        name: 'Unclaimed Failures',
        stat: `${todoFailures} (${hiddenTodoFailures - todoFailures} filtered)`,
      },
      {
        name: 'In Progress Suites',
        stat: `${inProgressTriageTasks.length} (${hiddenInProgressTasks.length - inProgressTriageTasks.length} filtered)`,
      },
      {
        name: 'In Progress Failures',
        stat: `${inProgressFailures} (${hiddenInProgressFailures - inProgressFailures} filtered)`,
      },
    ];
  }

  let simplifiedCards = allTriageTasks.map((x) => {
    return {
      id: x.id,
      ownerId: x.qaLead.email,
      numberOfFailures: x.suite?.statusCounts?.fail - x.suite?.statusCounts?.bug,
    };
  });
  let groups = _.groupBy(simplifiedCards, 'ownerId');

  let groupsSummary = {};
  Object.keys(groups).forEach(function (key) {
    let group = groups[key];
    let suiteCount = group.length;
    let failureCount =
      group
        .map((g) => g.numberOfFailures)
        .reduce((x, y) => {
          return x + y;
        }, 0) || 0;

    groupsSummary[key] = { suiteCount, failureCount };
  });

  let messageGroups = _.groupBy(
    messageTasks.map((x) => {
      let lead = teamsToShow.find((y) => y.qawId === x.qaLead.id);
      return { ownerId: lead?.id || 'erice@qawolf.com' };
    }),
    'ownerId',
  );
  Object.keys(messageGroups).forEach(function (key) {
    if (!groupsSummary[key]) {
      groupsSummary[key] = { messages: 0 };
    }

    groupsSummary[key].messages = messageGroups[key].length;
  });

  return {
    stats,
    groupsSummary,
  };
}
