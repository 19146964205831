import type React from 'react';
import { useState, useMemo } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useCommunications } from '../contexts/CommunicationsContext';
import { Badge } from '@/components/ui/badge';
import { ChevronDown, ChevronUp, Layers, Clock, MessageSquare, GitBranch, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { TeamConfigSheet } from './TeamConfigSheet';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import type { InvestigationTask } from '../types/investigation';

interface MessageTask {
  id: string;
  timestamp: string;
  relativeTime: string;
  author: {
    name: string;
    avatar?: string;
  };
  preview: string;
  link: string;
  interactions: {
    replies: number;
    threads: number;
  };
  customer: string;
  requiresReply: boolean;
  claimed: boolean;
  claimedBy?: string;
}

const messageTasks: MessageTask[] = [
  {
    id: 'msg-3',
    timestamp: 'Jan 20, 7:00 PM',
    relativeTime: '2d ago',
    author: {
      name: 'Josh',
      avatar: '/placeholder.svg',
    },
    preview: 'Hey @Joonil Kim. Just wanted to let you know that this bug has been resolved. Thanks!',
    link: 'https://app....',
    interactions: {
      replies: 12,
      threads: 3,
    },
    customer: 'Motion',
    requiresReply: true,
    claimed: true,
    claimedBy: 'current-user-id',
  },
  {
    id: 'msg-1',
    timestamp: 'Jan 22, 7:00 AM',
    relativeTime: '23m ago',
    author: {
      name: 'Kendrick Caranicas',
      avatar: '/placeholder.svg',
    },
    preview: 'hey team, some fails here',
    link: 'https://app....',
    interactions: {
      replies: 2,
      threads: 1,
    },
    customer: 'Regal',
    requiresReply: true,
    claimed: false,
  },
  {
    id: 'msg-2',
    timestamp: 'Jan 22, 5:00 PM',
    relativeTime: '2h ago',
    author: {
      name: 'Surendra Shekhawat',
      avatar: '/placeholder.svg',
    },
    preview: 'Hi, Could you please let me know if all the test cases are updated with QAWolf Status.',
    link: 'https://app....',
    interactions: {
      replies: 3,
      threads: 1,
    },
    customer: 'Motoinsight',
    requiresReply: true,
    claimed: false,
  },
];

const getRelativeTime = (timestamp: string) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) return 'just now';
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
  return `${Math.floor(diffInSeconds / 86400)}d ago`;
};

interface User {
  id: string;
  name: string;
  avatar: string;
  onlineStatus: 'red' | 'yellow' | 'green';
}

// Sample user data
const sampleUsers: User[] = [
  { id: 'user1', name: 'Alice', avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Alice', onlineStatus: 'red' },
  { id: 'user2', name: 'Bob', avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Bob', onlineStatus: 'yellow' },
  {
    id: 'user3',
    name: 'Charlie',
    avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Charlie',
    onlineStatus: 'green',
  },
  {
    id: 'user4',
    name: 'David',
    avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=David',
    onlineStatus: 'green',
  },
  { id: 'user5', name: 'Eve', avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Eve', onlineStatus: 'green' },
  {
    id: 'user6',
    name: 'Frank',
    avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Frank',
    onlineStatus: 'green',
  },
  {
    id: 'user7',
    name: 'Grace',
    avatar: 'https://api.dicebear.com/6.x/avataaars/svg?seed=Grace',
    onlineStatus: 'green',
  },
];

const UserAggregator: React.FC<{ users: User[]; maxDisplay?: number }> = ({ users, maxDisplay = 4 }) => {
  const sortedUsers = [...users].sort((a, b) => {
    const order = { red: 0, yellow: 1, green: 2 };
    return order[a.onlineStatus] - order[b.onlineStatus];
  });

  const displayedUsers = sortedUsers.slice(0, maxDisplay);
  const remainingUsers = sortedUsers.length - maxDisplay;

  return (
    <div className="flex items-center">
      {displayedUsers.map((user) => (
        <TooltipProvider key={user.id}>
          <Tooltip>
            <TooltipTrigger>
              <div className="relative -ml-2 first:ml-0">
                <Avatar
                  className={`w-6 h-6 border-2 border-background overflow-visible
                ${user.onlineStatus === 'red' ? 'bg-red-200 animate-[pulse_2s_ease-in-out_infinite]' : ''}`}
                >
                  <AvatarImage src={user.avatar} alt={user.name} />
                  <AvatarFallback>{user.name.charAt(0)}</AvatarFallback>
                  <span
                    className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border border-background
                    ${user.onlineStatus === 'red' ? 'bg-red-500' : user.onlineStatus === 'yellow' ? 'bg-yellow-500' : 'bg-green-500'}`}
                  />
                </Avatar>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>{`${user.name} last seen 5 minutes ago.`}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
      {remainingUsers > 0 && <span className="text-xs text-muted-foreground ml-1">+{remainingUsers} more</span>}
    </div>
  );
};

interface ConversationListProps {
  onSelectInvestigation: (id: string) => void;
  selectedInvestigationId: string | null;
  activeTab: 'ongoing' | 'completed';
  setActiveTab: (tab: 'ongoing' | 'completed') => void;
  investigations: InvestigationTask[];
}

export function ConversationList({ onSelectInvestigation, selectedInvestigationId, activeTab, setActiveTab, investigations }: ConversationListProps) {
  const { claimInvestigation } = useCommunications();
  const [isOtherTasksExpanded, setIsOtherTasksExpanded] = useState(false);

  const sortedInvestigations = useMemo(() => {
    const ongoing = investigations.filter((inv) => inv.status === 'new' || inv.status === 'in_progress');
    const completed = investigations.filter((inv) => inv.status === 'completed');

    const sortByPriority = (a: InvestigationTask, b: InvestigationTask) => {
      const priorityOrder = { urgent: 0, high: 1, medium: 2, low: 3, unprioritized: 4 };
      return priorityOrder[a.priority] - priorityOrder[b.priority];
    };

    const currentUserTasks = ongoing.filter((inv) => inv.assignee && inv.assignee.id === 'current-user-id').sort(sortByPriority);
    const otherUserTasks = ongoing.filter((inv) => inv.assignee && inv.assignee.id !== 'current-user-id').sort(sortByPriority);
    const unclaimedTasks = ongoing.filter((inv) => !inv.assignee).sort(sortByPriority);

    return {
      ongoing: { currentUserTasks, otherUserTasks, unclaimedTasks },
      completed: completed.sort((a, b) => {
        const bTime = b.timestamps?.updated || b.timestamps.created;
        const aTime = a.timestamps?.updated || a.timestamps.created;
        return new Date(bTime).getTime() - new Date(aTime).getTime();
      }),
    };
  }, [investigations]);

  const renderPriorityBadge = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return (
          <Badge variant="default" className="bg-black text-white text-xs px-1 py-0">
            Urgent
          </Badge>
        );
      case 'high':
        return (
          <Badge variant="destructive" className="bg-red-500 text-white text-xs px-1 py-0">
            High
          </Badge>
        );
      default:
        return null; // No badge for default priority
    }
  };

  const handleClaim = (investigationId: string) => {
    claimInvestigation(investigationId, 'current-user-id');
    onSelectInvestigation(investigationId);
  };

  const renderInvestigationItem = (investigation: InvestigationTask) => {
    const progressPercentage = Math.round((investigation.failures.investigated / investigation.failures.total) * 100);
    const ageToShow = investigation.timestamps.oldestFailure ? investigation.timestamps.oldestFailure : investigation.timestamps.created;
    const relativeAge = getRelativeTime(ageToShow);
    const showStackIcon = !!investigation.timestamps.oldestFailure;

    const queuedWorkflows = investigation.workflows.filter((wf) => wf.status === 'queued').length;
    const runningWorkflows = investigation.workflows.filter((wf) => wf.status === 'running').length;
    const queuedAndRunning = investigation.workflows.filter((wf) => wf.status === 'queued' || wf.status === 'running').length;
    const queuedAndRunningPercentage = Math.round((queuedAndRunning / investigation.workflows.length) * 100);

    return (
      <div
        key={investigation.id}
        className={`w-full text-left p-3 transition-colors ${
          investigation.id === selectedInvestigationId
            ? 'bg-accent text-accent-foreground'
            : investigation.assignee && investigation.assignee.id !== 'current-user-id'
            ? 'bg-muted text-foreground hover:bg-accent hover:text-accent-foreground'
            : 'bg-background text-foreground hover:bg-accent hover:text-accent-foreground'
        }`}
        onClick={() => onSelectInvestigation(investigation.id)}
      >
        <div className="flex justify-between items-start mb-1">
          <div className="flex items-center">
            <h3 className="font-semibold text-sm mr-2">{investigation.application.name}</h3>
            {renderPriorityBadge(investigation.priority)}
          </div>
          <div className="flex items-center text-xs">
            <span>{investigation.application.environment}</span>
          </div>
        </div>
        <div className="flex justify-between items-center text-xs mb-1">
          <span className="text-muted-foreground truncate max-w-[60%]">{investigation.references[0].name}</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="flex items-center">
                <div className="w-16 h-2 bg-gray-400 rounded-full overflow-hidden mr-2 border border-gray-400">
                  <div className="h-full bg-gray-600" style={{ width: `${progressPercentage}%` }} />
                  <div className="h-full bg-gray-500 -mt-2" style={{ width: `${queuedAndRunningPercentage}%` }} />
                </div>
                <span>{progressPercentage}%</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {investigation.failures.investigated}/{investigation.failures.total} investigated
                </p>
                {queuedWorkflows > 0 && <p>{queuedWorkflows} queued</p>}
                {runningWorkflows > 0 && <p>{runningWorkflows} running</p>}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex justify-between items-center text-xs">
          <div className="flex items-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className={`flex items-center ${showStackIcon ? 'text-red-500' : ''}`}>
                    <Clock className="w-3 h-3 mr-1" />
                    <span>{relativeAge}</span>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Suite Age: {getRelativeTime(investigation.timestamps.created)}</p>
                  {showStackIcon && (
                    <p>Oldest Failure: {investigation.timestamps.oldestFailure && getRelativeTime(investigation.timestamps.oldestFailure)}</p>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {showStackIcon && investigation.status !== 'completed' && (
              <div className="flex items-center text-red-500 ml-2">
                <Layers className="w-3 h-3 mr-1" />
                <span>History</span>
              </div>
            )}
          </div>
          {investigation.status === 'completed' ? (
            <span>Completed by: {investigation.lastCompletedBy}</span>
          ) : investigation.assignee ? (
            <div className="flex items-center">
              <div className="relative">
                <Avatar className="w-4 h-4 mr-1">
                  <AvatarImage src={investigation.assignee.avatar || '/placeholder.svg'} alt={investigation.assignee.name} />
                  <AvatarFallback>{investigation.assignee.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <span
                  className={`absolute bottom-0 right-0 w-1.5 h-1.5 rounded-full border border-background
                    ${
                      investigation.assignee.onlineStatus === 'red'
                        ? 'bg-red-500'
                        : investigation.assignee.onlineStatus === 'yellow'
                        ? 'bg-yellow-500'
                        : 'bg-green-500'
                    }
                    ${investigation.assignee.onlineStatus === 'red' ? 'animate-ping' : ''}`}
                />
              </div>
              <span>{investigation.assignee.id === 'current-user-id' ? 'You' : investigation.assignee.name}</span>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleClaim(investigation.id);
              }}
              className="h-6 text-xs px-2 py-0"
            >
              Claim
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderMessageTask = (message: MessageTask) => {
    return (
      <div
        key={message.id}
        className={`w-full text-left p-3 transition-colors ${
          message.claimed && message.claimedBy !== 'current-user-id'
            ? 'bg-muted text-foreground hover:bg-accent hover:text-accent-foreground'
            : 'bg-background text-foreground hover:bg-accent hover:text-accent-foreground'
        }`}
      >
        <div className="flex justify-between items-start mb-1">
          <div className="flex items-center gap-2">
            <Avatar className="w-4 h-4">
              <AvatarImage src={`https://api.dicebear.com/9.x/avataaars/svg?seed=${message.author.name}`} alt={message.author.name} />
              <AvatarFallback>{message.author.name.charAt(0)}</AvatarFallback>
            </Avatar>
            <span className="text-sm">{message.author.name}</span>
          </div>
          {message.requiresReply && (
            <Badge variant="secondary" className="bg-purple-100 text-purple-700 border-purple-200">
              Requires Reply
            </Badge>
          )}
        </div>
        <div className="flex justify-between items-center mb-1">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="text-xs text-muted-foreground truncate max-w-[150px]">{message.preview}</p>
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-[200px]">{message.preview}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <span className="text-xs text-muted-foreground ml-2">{message.customer}</span>
        </div>
        <div className="flex justify-between items-center text-xs">
          <div className="flex items-center gap-2 text-muted-foreground">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="flex items-center">
                  <Clock className="w-3 h-3 mr-1" />
                  <span>{message.relativeTime}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{message.timestamp}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <span className="flex items-center">
              <MessageSquare className="w-3 h-3 mr-1" />
              {message.interactions.replies}
            </span>
            {message.interactions.threads > 0 && (
              <span className="flex items-center">
                <GitBranch className="w-3 h-3 mr-1" />
                {message.interactions.threads}
              </span>
            )}
          </div>
          {message.claimed ? (
            <div className="flex items-center">
              <div className="relative">
                <Avatar className="w-4 h-4 mr-1">
                  <AvatarImage src="https://api.dicebear.com/6.x/avataaars/svg?seed=current-user-id" alt="You" />
                  <AvatarFallback>Y</AvatarFallback>
                </Avatar>
                <span className="absolute bottom-0 right-0 w-1.5 h-1.5 rounded-full border border-background bg-green-500" />
              </div>
              <span>You</span>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                // Add claim functionality here
              }}
              className="h-6 text-xs px-2 py-0"
            >
              Claim
            </Button>
          )}
        </div>
      </div>
    );
  };

  const getOldestTaskAge = (tasks: InvestigationTask[]) => {
    if (tasks.length === 0) return 'N/A';
    const oldestTask = tasks.reduce((oldest, current) => {
      return new Date(oldest.timestamps.created) < new Date(current.timestamps.created) ? oldest : current;
    });
    const ageInDays = Math.floor((Date.now() - new Date(oldestTask.timestamps.created).getTime()) / (1000 * 60 * 60 * 24));
    return `${ageInDays}d`;
  };

  return (
    <div className="w-80 bg-background border-r border-border flex flex-col h-full overflow-hidden">
      <div className="flex items-center justify-between p-2 border-b border-border">
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'ongoing' | 'completed')} className="flex-1">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="ongoing">
              Ongoing
              <span className="ml-2 text-xs bg-primary text-primary-foreground rounded-full px-2 py-1">
                {investigations.filter((inv) => inv.status === 'new' || inv.status === 'in_progress').length}
              </span>
            </TabsTrigger>
            <TabsTrigger value="completed">Completed</TabsTrigger>
          </TabsList>
        </Tabs>
        <TeamConfigSheet />
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'ongoing' | 'completed')} className="flex-1 flex flex-col">
        <TabsContent value="ongoing" className="flex-1 p-0" hidden={activeTab !== 'ongoing'}>
          <ScrollArea className="h-[calc(100vh-64px)]">
            <div className="divide-y divide-border">
              {/* Claimed message tasks */}
              {messageTasks.filter((task) => task.claimed && task.claimedBy === 'current-user-id').map(renderMessageTask)}

              {/* Current user's tasks */}
              {sortedInvestigations.ongoing.currentUserTasks.map(renderInvestigationItem)}

              {/* Separator and summary for other users' tasks */}
              {sortedInvestigations.ongoing.otherUserTasks.length > 0 && (
                <div className="bg-muted py-2 px-3">
                  <div className="flex justify-between items-center text-xs text-muted-foreground mb-1">
                    <div className="flex items-center space-x-3">
                      <div className="flex items-center">
                        <MessageSquare className="w-3 h-3 mr-1" />
                        <span>{sortedInvestigations.ongoing.otherUserTasks.length}</span>
                      </div>
                      <div className="flex items-center">
                        <AlertCircle className="w-3 h-3 mr-1" />
                        <span>{sortedInvestigations.ongoing.otherUserTasks.reduce((acc, task) => acc + task.failures.total, 0)}</span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-3 h-3 mr-1" />
                      <span>{getOldestTaskAge(sortedInvestigations.ongoing.otherUserTasks)} ago</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <UserAggregator users={sampleUsers} />
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setIsOtherTasksExpanded(!isOtherTasksExpanded)}
                      className="h-6 text-xs px-2 py-0"
                    >
                      {isOtherTasksExpanded ? <ChevronUp className="h-3 w-3" /> : <ChevronDown className="h-3 w-3" />}
                    </Button>
                  </div>
                </div>
              )}

              {/* Expandable section for other users' tasks */}
              {isOtherTasksExpanded && sortedInvestigations.ongoing.otherUserTasks.map(renderInvestigationItem)}
              {isOtherTasksExpanded && messageTasks.filter((task) => task.claimed && task.claimedBy !== 'current-user-id').map(renderMessageTask)}

              {/* Message Tasks */}
              {messageTasks.filter((task) => !task.claimed).map(renderMessageTask)}

              {/* Unclaimed tasks */}
              {sortedInvestigations.ongoing.unclaimedTasks.map(renderInvestigationItem)}
            </div>
          </ScrollArea>
        </TabsContent>
        <TabsContent value="completed" className="flex-1 p-0" hidden={activeTab !== 'completed'}>
          <ScrollArea className="h-[calc(100vh-64px)]">
            <div className="divide-y divide-border">{sortedInvestigations.completed.map(renderInvestigationItem)}</div>
          </ScrollArea>
        </TabsContent>
      </Tabs>
    </div>
  );
}
