import { QAWTask } from '@/types';
import { PlanningTasksTable } from './PlanningTasksTable';
import { UseQueryResult } from '@tanstack/react-query';
import { PlanningContextType } from '../../../context/PlanningContext';
import TaskBlockersView from './TaskBlockersView';
import WolfLoader from '@/components/WolfLoader/WolfLoader';
import { Typography } from '@mui/material';

type ViewTabPanelProps = {
  tasksQuery: UseQueryResult<QAWTask[]>;
  selectedView: PlanningContextType['selectedView'];
  queryKey: string[];
};

export default function ViewTabPanel({ tasksQuery, selectedView, queryKey }: ViewTabPanelProps) {
  
  // If the selected view is testCoverageRequest or testMaintenance, return the PlanningTasksTable component
  if (selectedView === 'testCoverageRequest' || selectedView === 'testMaintenance') {
    return <PlanningTasksTable tasksQuery={tasksQuery} queryKey={queryKey} />;
  }

  // Handle loading and error states for blockers (task table states are handled in the PlanningTasksTable component)
  if (tasksQuery.isFetching) {
    return <WolfLoader customText="Loading task blockers..." customStyles={{ height: '100%', width: '100%', marginTop: '50px' }} />;
  }

  if (tasksQuery.isError) {
    return <Typography>Error loading blockers</Typography>;
  }

  return <TaskBlockersView tasksQuery={tasksQuery} />;
}
