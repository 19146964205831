import { Box } from '@mui/material';
import { getAllJobSources, getAllSubmissions, getAllCandidates } from './HiringUtils/api';
import HiringLoader from './HiringUtils/HiringLoader';
import { useQuery } from '@tanstack/react-query';

import Header from '../Layout/Header';
import Tabs from '../UtilityComponents/TabComponents/tabs';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { finalRoundStatusMap } from './HiringUtils/StatusConstants';
import HiringTable from './CandidateView/CandidateSubmissionTable';
import FinalRoundTable from './FinalRoundView/FinalRoundTable';
import MetricsView from './Metrics/MetricsView';
import ArchivedTable from './ArchivedView/ArchiveTable';

export default function HiringInternalDashboard() {
  // Define default tabs without tableData.
  const defaultTabs = [
    { type: "Candidate View", current: true },
    { type: "Final Round View", current: false },
    { type: "Metrics", current: false },
    { type: "Archived", current: false },
  ];

  // Initialize search params and tabs state.
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);
  const [tabs, setTabs] = useState(() =>
    paramsMap.activeTab
      ? defaultTabs.map((tab) => ({ ...tab, current: tab.type === paramsMap.activeTab }))
      : defaultTabs,
  );

  // Update search params whenever tabs change.
  useEffect(() => {
    const activeTabType = tabs.find((tab) => tab.current).type;
    searchParams.set('activeTab', activeTabType);
    // Remove date parameters if not on the Metrics tab.
    if (activeTabType !== 'Metrics') {
      searchParams.delete('gte');
      searchParams.delete('lte');
      searchParams.delete('source');
    }
    setSearchParams(searchParams);
  }, [tabs, searchParams, setSearchParams]);

  // Query function to fetch combined data.
  const fetchCombinedData = async () => {
    const [submission, jobSource, candidates] = await Promise.all([
      getAllSubmissions(),
      getAllJobSources(),
      getAllCandidates(),
    ]);
    return { submission, jobSource, candidates };
  };

  // Use React Query to fetch data with keepPreviousData enabled.
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['combinedData'],
    queryFn: fetchCombinedData,
    select: (data) => {
      const activeSubmissions = data.submission;
      const jobSources = data.jobSource;
      const allCandidates = data.candidates;

      // Attach the job source name to each submission.
      activeSubmissions.forEach((submission) => {
        const source = jobSources.find((item) => item.id === submission.jobSourceId);
        if (source) {
          submission.jobSourceName = source.sourceName;
        }
      });

      return { activeSubmissions, jobSources, allCandidates };
    },
    refetchInterval: 60 * 1000,
  });

  // Handle loading and error states.
  if (isLoading && !data) return <HiringLoader />;
  if (isError) {
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>Error loading client summary...</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  // Compute the filtered data for each view.
  const candidateData = {
    data: data.activeSubmissions.filter(
      (submission) =>
        !submission.isArchived && !finalRoundStatusMap.includes(submission.status),
    ),
    allCandidates: data.allCandidates,
    allSubmissions: data.activeSubmissions,
  };

  const finalRoundData = {
    allSubmissions: data.activeSubmissions.filter(
      (submission) =>
        finalRoundStatusMap.includes(submission.status) && !submission.isArchived,
    ),
  };

  const jobSourcesFormatted = data.jobSources.filter(
    (source) => source.activeStatus !== false,
  );

  const metricsData = {
    candidates: data.activeSubmissions.filter(
      (submission) => submission.dateSubmitted && submission.jobSourceId !== 9,
    ),
    jobSources: jobSourcesFormatted.map((source) => source.sourceName),
  };

  const archivedData = {
    allSubmissions: data.activeSubmissions.filter(
      (submission) => submission.isArchived,
    ),
  };

  // Determine the active tab.
  const activeTab = tabs.find((tab) => tab.current);

  const viewMap = {
    'Candidate View': (
      <HiringTable data={candidateData} />
    ),
    'Final Round View': (
      <FinalRoundTable data={finalRoundData} />
    ),
    'Metrics': (
      <MetricsView data={metricsData} />
    ),
    'Archived': (
      <ArchivedTable data={archivedData} />
    ),
  };

  return (
    <Box>
      <Header text={activeTab.type} />
      <Tabs tabs={tabs} setTabs={setTabs} activeTab={activeTab} />
      {/* <HiringOverview activeTab={activeTab} data={tableData} /> */}
      <div>{viewMap[activeTab.type]}</div>;
    </Box>
  );
}
