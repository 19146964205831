import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Paper } from '@mui/material';
import { stringToColor } from '../../../utils/colorUtils';

const BlockersTable = ({ blockers }) => {
  const [filteredBlockers, setFilteredBlockers] = useState(blockers);
  const [customerFilter, setCustomerFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');

  useEffect(() => {
    let updatedBlockers = [...blockers];

    // Filter by customer name
    if (customerFilter) {
      updatedBlockers = updatedBlockers.filter((blocker) => blocker.customerName.toLowerCase().includes(customerFilter.toLowerCase()));
    }

    // Sort by selected column
    updatedBlockers.sort((a, b) => {
      const valueA = orderBy === 'createdAt' ? new Date(a[orderBy]) : a[orderBy];
      const valueB = orderBy === 'createdAt' ? new Date(b[orderBy]) : b[orderBy];
      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    });

    setFilteredBlockers(updatedBlockers);
  }, [blockers, customerFilter, sortOrder, orderBy]);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper>
      <TextField
        variant="outlined"
        placeholder="Filter by customer name"
        value={customerFilter}
        onChange={(e) => setCustomerFilter(e.target.value)}
        style={{ margin: '16px', width: '300px' }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'customerName'}
                  direction={orderBy === 'customerName' ? sortOrder : 'asc'}
                  onClick={() => handleSortRequest('customerName')}
                >
                  Customer Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={orderBy === 'title' ? sortOrder : 'asc'}
                  onClick={() => handleSortRequest('title')}
                >
                  Blocker Title
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'description'}
                  direction={orderBy === 'description' ? sortOrder : 'asc'}
                  onClick={() => handleSortRequest('description')}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? sortOrder : 'asc'}
                  onClick={() => handleSortRequest('createdAt')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBlockers.map((blocker, index) => (
              <TableRow key={index}>
                <TableCell style={{ color: stringToColor(blocker.customerName), borderRight: '1px solid #ddd' }}>{blocker.customerName}</TableCell>
                <TableCell style={{ borderRight: '1px solid #ddd' }}>{blocker.title}</TableCell>
                <TableCell style={{ borderRight: '1px solid #ddd' }}>{blocker.description}</TableCell>
                <TableCell>{new Date(blocker.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default BlockersTable;
