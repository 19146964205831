import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import CytoscapeComponent from 'react-cytoscapejs';
import { sendGetRequest, sendPostRequest } from '../../utils/network';
import WolfLoader from '../WolfLoader/WolfLoader';
import { ElementDefinition } from 'cytoscape';
import cytoscape, { Core } from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';
import {
  transformToGraphElements,
  getBuildingBlocks,
  getAllBlockIds,
  calculateNodeStates,
  updateNodeClasses,
  highlightSequence,
  handleBlockSelection,
  handleWorkflowSelection,
  navigateWorkflowSequence,
  handleSequenceBlockSelection,
  getBlockReferences,
  generateCsvData,
  downloadCsv,
} from './helpers';
import { stylesheet } from './styles';
import { layout } from './layout';
import { Run } from './types';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import ConfirmationModal from './ConfirmDeletionModal';
import Toast from '../Layout/Toast';

// Register the layout
cytoscape.use(coseBilkent);

export default function FailureSignature() {
  const [suiteId, setSuiteId] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [workflowSearch, setWorkflowSearch] = useState('');
  const [blockSearch, setBlockSearch] = useState('');
  const [elements, setElements] = useState<ElementDefinition[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState<number>(0);
  const [activeView, setActiveView] = useState<'workflows' | 'blocks'>('workflows');
  const cyRef = useRef<Core | null>(null);
  const [toast, setToast] = useState<{
    title: string;
    message: string;
    isSuccess: boolean;
    key: number;
    show?: boolean;
    onDone?: () => void;
    content?: string;
  } | null>(null);
  const [isReprocessing, setIsReprocessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add default suiteId logic
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlSuiteId = params.get('suiteId');
    const defaultSuiteId = 'tommq1z3up28n98irdxl5pa4ogen';

    const initialSuiteId = urlSuiteId || defaultSuiteId;
    setSuiteId(initialSuiteId);
    setInputValue(initialSuiteId);
  }, []);

  const {
    data,
    isLoading,
    isError,
    error: queryError,
  } = useQuery({
    queryKey: ['failureSignatureData', suiteId],
    queryFn: async () => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const queryParams = new URLSearchParams();
      queryParams.set('suiteId', suiteId);
      if (user) queryParams.set('slackId', user.slackId);
      const response = await sendGetRequest(`/failure-signature?${queryParams.toString()}`);
      return response;
    },
    enabled: !!suiteId,
    retry: (failureCount) => {
      return failureCount < 3;
    },
  });

  // Show toast for any error
  useEffect(() => {
    if (isError && queryError) {
      setToast({
        title: 'Error',
        message: queryError.toString(),
        isSuccess: false,
        key: Date.now(),
      });
    }
  }, [isError, queryError]);

  // Ensure currentData is null if there's an error
  const currentData = isError ? null : data?.success ? data.data : null;

  const suiteName = currentData?.runs[0]?.suiteName;
  const environmentId = currentData?.runs[0]?.environmentId;
  const customerName = currentData?.runs[0]?.teamName;
  const customerSlug = currentData?.runs[0]?.teamSlug;
  const allBlockIds = getAllBlockIds(currentData?.runs || []);
  const buildingBlocks = getBuildingBlocks(currentData?.buildingBlockDictionary?.blocks, allBlockIds);

  useEffect(() => {
    if (currentData) {
      setElements(transformToGraphElements(currentData));
      // Run layout after elements are set
      setTimeout(() => handleRefreshLayout(), 50);
    }
  }, [currentData]);

  // Update node states whenever relevant data changes
  useEffect(() => {
    if (cyRef.current && currentData) {
      const nodeStates = calculateNodeStates(currentData);
      updateNodeClasses(cyRef.current, nodeStates, selectedWorkflow, selectedBlock, currentData);
    }
  }, [data, selectedWorkflow, selectedBlock, currentData?.runs]);

  // Add tooltip handling to Cytoscape initialization
  useEffect(() => {
    if (cyRef.current && currentData) {
      const cy = cyRef.current;

      // Add tooltip handling
      cy.on('mouseover', 'node', (event) => {
        const node = event.target;
        const tooltipText = node.data('tooltip');
        if (tooltipText) {
          // Store timeout ID in node data for cleanup
          const timeoutId = setTimeout(() => {
            const renderedNode = event.target.renderedPosition();
            const container = cy.container();

            if (container) {
              const tooltip = document.createElement('div');
              tooltip.className = 'cytoscape-tooltip';
              tooltip.textContent = tooltipText;
              tooltip.style.position = 'absolute';
              tooltip.style.left = `${renderedNode.x + container.offsetLeft}px`;
              tooltip.style.top = `${renderedNode.y + container.offsetTop - 40}px`;
              tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
              tooltip.style.color = 'white';
              tooltip.style.padding = '5px 10px';
              tooltip.style.borderRadius = '4px';
              tooltip.style.fontSize = '12px';
              tooltip.style.pointerEvents = 'none';
              tooltip.style.whiteSpace = 'pre-line';
              tooltip.style.zIndex = '10';

              container.appendChild(tooltip);

              node.data('tooltipElement', tooltip);
            }
          }, 500);

          // Store timeout ID for cleanup
          node.data('tooltipTimeout', timeoutId);
        }
      });

      cy.on('mouseout', 'node', (event) => {
        const node = event.target;
        // Clear timeout if it exists
        const timeoutId = node.data('tooltipTimeout');
        if (timeoutId) {
          clearTimeout(timeoutId);
          node.removeData('tooltipTimeout');
        }
        // Remove tooltip if it exists
        const tooltip = node.data('tooltipElement');
        if (tooltip) {
          tooltip.remove();
          node.removeData('tooltipElement');
        }
      });

      // Handle cleanup on node movement or zoom
      cy.on('viewport', () => {
        const tooltips = document.querySelectorAll('.cytoscape-tooltip');
        tooltips.forEach((tooltip) => tooltip.remove());
      });

      // Add node click handler
      cy.on('tap', 'node', (event) => {
        const node = event.target;
        const blockId = node.id();

        if (activeView === 'workflows' && !selectedWorkflow) {
          setActiveView('blocks');
        }

        // Always handle block selection first
        handleBlockSelection(
          cy,
          blockId,
          selectedBlock,
          setSelectedBlock,
          setSelectedWorkflow,
          setSelectedSequenceIndex,
          currentData,
          setActiveView,
          selectedWorkflow,
          true,
        );

        // If we're in workflows view or there's a selected workflow, handle workflow-specific logic
        if (selectedWorkflow) {
          const workflow = currentData?.runs?.find((run: Run) => run.workflow.id === selectedWorkflow);
          if (workflow) {
            const firstAttempt = workflow.attempts[0];
            if (firstAttempt.blockSequence.includes(blockId)) {
              const blockIndex = firstAttempt.blockSequence.indexOf(blockId);
              setSelectedSequenceIndex(blockIndex);
              // Pass false to prevent zooming when highlighting sequence
              highlightSequence(cy, firstAttempt.blockSequence, selectedWorkflow, currentData, false);
            }
          }
        }
      });

      return () => {
        // Clean up any remaining tooltips
        const tooltips = document.querySelectorAll('.cytoscape-tooltip');
        tooltips.forEach((tooltip) => tooltip.remove());
        cy.removeAllListeners();
      };
    }
  }, [data, suiteId, activeView, selectedWorkflow, selectedSequenceIndex, selectedBlock]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue === suiteId) {
      if (window.confirm('Are you sure you want to reload the same suite?')) {
        window.location.reload();
      }
    } else {
      setSuiteId(inputValue);
      const url = new URL(window.location.href);
      url.searchParams.set('suiteId', inputValue);
      window.history.pushState({}, '', url);
    }
  };

  const handleRefreshLayout = () => {
    if (cyRef.current) {
      cyRef.current.layout(layout).run();
    }
    // Update node styles as well
    const nodeStates = calculateNodeStates(currentData);
    updateNodeClasses(cyRef.current, nodeStates, selectedWorkflow, selectedBlock, currentData);
  };

  const blockReferences = selectedBlock ? getBlockReferences(selectedBlock, currentData) : null;

  const handleExport = () => {
    const csvContent = generateCsvData(currentData);
    if (csvContent) {
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      downloadCsv(csvContent, `failure-signature-report-${timestamp}.csv`);
    }
  };

  // Add effect to handle scrolling when activeView changes
  useEffect(() => {
    if (selectedWorkflow && activeView === 'workflows') {
      const element = document.querySelector(`[data-workflow-id="${selectedWorkflow}"]`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (selectedBlock && activeView === 'blocks') {
      const element = document.querySelector(`[data-block-id="${selectedBlock}"]`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeView, selectedWorkflow, selectedBlock]);

  const handleReprocess = async (options?: { deleteAll: boolean; rebuildDictionary: boolean }) => {
    if (!suiteId || !currentData?.runs[0]?.teamId) return;

    // If no options provided, show the modal
    if (!options) {
      setIsModalOpen(true);
      return;
    }

    setIsReprocessing(true);
    try {
      const response = await sendPostRequest('/failure-signature/delete', {
        suiteId,
        customerId: currentData.runs[0].teamId,
        deleteAll: options.deleteAll,
        rebuildDictionary: options.rebuildDictionary,
      });

      if (!response?.success) {
        throw new Error('Failed to delete suite');
      }

      setToast({
        title: 'Success',
        message: 'Suite reprocessing started. This page will refresh momentarily.',
        isSuccess: true,
        key: Date.now(),
      });

      // Refresh the page after 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      setToast({
        title: 'Error',
        message: 'Failed to reprocess suite. Please try again.',
        isSuccess: false,
        key: Date.now(),
      });
    } finally {
      setIsReprocessing(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-900">
      {toast && <Toast {...toast} show={true} onDone={() => setToast(null)} content={toast.message} />}

      <ConfirmationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleReprocess} isProcessing={isReprocessing} />

      {/* Main Content */}
      <div className="flex-1 min-w-0 overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-5">
            <div className="flex flex-wrap items-center gap-2">
              <h1 className="text-3xl font-bold text-gray-200">Failure Signature Graph:</h1>
              {(customerName || suiteName) && (
                <div className="flex items-center gap-2 min-w-fit">
                  {customerName && (
                    <>
                      <span className="text-3xl font-bold text-gray-200">{customerName}</span>
                    </>
                  )}
                  {suiteName && (
                    <>
                      <span className="text-3xl font-bold text-gray-200">-</span>
                      <span className="text-3xl font-bold text-gray-200">{suiteName}</span>
                      {customerSlug && environmentId && suiteId && (
                        <a
                          href={`https://app.qawolf.com/${customerSlug}/environments/${environmentId}/runs/${suiteId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                          title="Link to Suite"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                          </svg>
                        </a>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => handleReprocess()}
                disabled={isLoading || !currentData || isReprocessing}
                className={`px-4 py-2 ${
                  isLoading || !currentData || isReprocessing ? 'bg-blue-500 opacity-50 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center space-x-2`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ${isReprocessing ? 'animate-spin' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>{isReprocessing ? 'Reprocessing...' : 'Reprocess Suite'}</span>
              </button>
              <button
                onClick={handleExport}
                disabled={isLoading || !currentData}
                className={`px-4 py-2 ${
                  isLoading || !currentData ? 'bg-green-500 opacity-50 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'
                } text-white rounded focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center space-x-2`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Export Report</span>
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="mb-5 flex items-center justify-between">
            <div className="flex-shrink-0">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter Suite ID"
                size={32}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-blue-500 text-center"
              />
              <button
                type="submit"
                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Load Suite
              </button>
            </div>

            {suiteId && (
              <a
                href={`https://www.task-wolf.com/flow-explorer/${suiteId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors pr-4"
              >
                <ChartBarIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                Flow Explorer
              </a>
            )}
          </form>

          {isLoading ? (
            <WolfLoader />
          ) : isError ? (
            <div className="text-red-500">Error fetching data</div>
          ) : !currentData ? (
            <div className="text-gray-400 text-center py-8">Enter a Suite ID to view the failure signature graph</div>
          ) : (
            <div className="bg-gray-800 rounded-lg shadow-xl p-6 relative">
              <button
                onClick={handleRefreshLayout}
                className="absolute top-4 right-4 z-10 bg-gray-700 hover:bg-gray-600 text-gray-200 p-2 rounded-full shadow-lg transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                title="Refresh Layout"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {elements && elements.length > 0 && (
                <CytoscapeComponent
                  cy={(cy: Core) => {
                    cyRef.current = cy;
                  }}
                  elements={elements}
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 280px)',
                    backgroundColor: '#1f2937',
                  }}
                  layout={layout}
                  stylesheet={stylesheet}
                  boxSelectionEnabled={true}
                  autounselectify={false}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Side Table */}
      <div className={'w-96 transition-all duration-300 bg-gray-800 border-l border-gray-700 overflow-hidden shrink-0'}>
        <div className="p-6">
          {/* Navigation Toggle */}
          <div className="flex mb-6 bg-gray-700 rounded-lg p-1">
            <button
              onClick={() => setActiveView('workflows')}
              className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
                activeView === 'workflows' ? 'bg-blue-600 text-white' : 'text-gray-300 hover:text-white'
              }`}
            >
              Workflows
            </button>
            <button
              onClick={() => setActiveView('blocks')}
              className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors ${
                activeView === 'blocks' ? 'bg-blue-600 text-white' : 'text-gray-300 hover:text-white'
              }`}
            >
              Building Blocks
            </button>
          </div>

          {/* Search Bar */}
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                value={activeView === 'workflows' ? workflowSearch : blockSearch}
                onChange={(e) => (activeView === 'workflows' ? setWorkflowSearch(e.target.value) : setBlockSearch(e.target.value))}
                placeholder={`Search ${activeView === 'workflows' ? 'workflows' : 'building blocks'}...`}
                className="w-full px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-blue-500 pr-10"
              />
              {((activeView === 'workflows' && workflowSearch) || (activeView === 'blocks' && blockSearch)) && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button
                    onClick={() => (activeView === 'workflows' ? setWorkflowSearch('') : setBlockSearch(''))}
                    className="text-gray-400 hover:text-gray-200 focus:outline-none"
                    aria-label="Clear search"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-2xl font-bold text-gray-200 mb-2">{activeView === 'workflows' ? 'Workflows' : 'Building Blocks'}</h2>
            <div className="h-px bg-gray-700 w-full"></div>
          </div>

          <div className="space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 240px)' }}>
            {activeView === 'workflows' ? (
              currentData?.runs?.length > 0 ? (
                currentData.runs
                  .filter(
                    (run: Run) =>
                      run.workflow.name.toLowerCase().includes(workflowSearch.toLowerCase()) ||
                      run.workflow.id.toLowerCase().includes(workflowSearch.toLowerCase()),
                  )
                  .map((run: Run) => (
                    <div
                      key={run.workflow.id}
                      data-workflow-id={run.workflow.id}
                      className={`rounded-lg transition-all duration-200 cursor-pointer ${
                        selectedWorkflow === run.workflow.id ? 'bg-blue-600 shadow-lg scale-102' : 'bg-gray-700 hover:bg-gray-600'
                      }`}
                    >
                      <div
                        onClick={() =>
                          handleWorkflowSelection(
                            cyRef.current,
                            run,
                            selectedWorkflow,
                            setSelectedWorkflow,
                            setSelectedBlock,
                            setSelectedSequenceIndex,
                            currentData,
                          )
                        }
                        className="p-4 flex items-center justify-between"
                      >
                        <div className="font-medium text-gray-200 text-lg">{run.workflow.name}</div>
                      </div>
                      {selectedWorkflow === run.workflow.id && run.attempts.length > 0 && (
                        <div className="px-4 pb-4">
                          <div className="bg-gray-800 rounded-md p-3 space-y-3">
                            <div className="flex justify-between items-center">
                              <button
                                onClick={() =>
                                  navigateWorkflowSequence(
                                    cyRef.current,
                                    'prev',
                                    selectedWorkflow,
                                    selectedSequenceIndex,
                                    currentData,
                                    setSelectedSequenceIndex,
                                  )
                                }
                                disabled={selectedSequenceIndex === 0}
                                className={`p-2 rounded-full transition-colors ${
                                  selectedSequenceIndex === 0
                                    ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                                }`}
                              >
                                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                              </button>
                              <span className="text-gray-300 text-sm">
                                Block {selectedSequenceIndex + 1} of {run.attempts[0].blockSequence.length}
                              </span>
                              <button
                                onClick={() =>
                                  navigateWorkflowSequence(
                                    cyRef.current,
                                    'next',
                                    selectedWorkflow,
                                    selectedSequenceIndex,
                                    currentData,
                                    setSelectedSequenceIndex,
                                  )
                                }
                                disabled={selectedSequenceIndex === run.attempts[0].blockSequence.length - 1}
                                className={`p-2 rounded-full transition-colors ${
                                  selectedSequenceIndex === run.attempts[0].blockSequence.length - 1
                                    ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
                                }`}
                              >
                                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                              </button>
                            </div>

                            <div className="border-t border-gray-700 pt-3">
                              <div className="text-gray-300 text-sm mb-1">Current Block</div>
                              <div className="text-gray-200">{run.attempts[0].blockSequence[selectedSequenceIndex]}</div>
                            </div>
                            <div className="border-t border-gray-700 pt-3">
                              <div className="text-gray-300 text-sm mb-1">Sequence</div>
                              <div className="text-gray-400 text-sm space-y-1">
                                {run.attempts[0].blockSequence.map((id, index) => (
                                  <div
                                    key={id}
                                    className="flex items-center cursor-pointer hover:bg-gray-700 rounded px-2 py-1 transition-colors"
                                    onClick={() => handleSequenceBlockSelection(cyRef.current, id, index, setSelectedSequenceIndex)}
                                  >
                                    <span className={index === selectedSequenceIndex ? 'text-blue-400' : ''}>{' → ' + id}</span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
              ) : (
                <div className="text-gray-400 text-center py-8">No workflows found</div>
              )
            ) : buildingBlocks.length > 0 ? (
              buildingBlocks
                .filter((block) => block.name.toLowerCase().includes(blockSearch.toLowerCase()))
                .map((block) => (
                  <div
                    key={block.name}
                    data-block-id={block.name}
                    onClick={() =>
                      handleBlockSelection(
                        cyRef.current,
                        block.name,
                        selectedBlock,
                        setSelectedBlock,
                        setSelectedWorkflow,
                        setSelectedSequenceIndex,
                        currentData,
                        setActiveView,
                        selectedWorkflow,
                        false,
                      )
                    }
                    className={`rounded-lg transition-all duration-200 cursor-pointer ${
                      selectedBlock === block.name ? 'bg-blue-600 shadow-lg scale-102' : 'bg-gray-700 hover:bg-gray-600'
                    }`}
                  >
                    <div className="p-4">
                      <div className="font-medium text-gray-200 text-lg mb-1">{block.name}</div>
                      <div className="text-sm text-gray-400 mb-2">Type: {block.type}</div>
                      {selectedBlock === block.name && (
                        <>
                          {block.code && (
                            <div className="mt-3 bg-gray-900 p-3 rounded-md">
                              <pre className="text-sm font-mono text-gray-300 whitespace-pre-wrap break-words">{block.code.join('\n')}</pre>
                            </div>
                          )}
                          {blockReferences && (
                            <div className="mt-4 space-y-4">
                              <div className="border-t border-gray-600 pt-4">
                                <h3 className="text-gray-200 font-medium mb-2">Run References</h3>

                                <div className="space-y-3">
                                  <div>
                                    <h4 className="text-red-400 font-medium mb-1">Failed Runs ({blockReferences.failed.length})</h4>
                                    <div className="space-y-1">
                                      {blockReferences.failed.map((run) => (
                                        <div key={`${run.workflow.id}-${run.runId}`} className="text-sm text-gray-300 hover:text-white">
                                          <a
                                            href={`https://app.qawolf.com/runs/${run.runId}/attempt/${run.attempts[0].id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="hover:text-white"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              handleWorkflowSelection(
                                                cyRef.current,
                                                run,
                                                selectedWorkflow,
                                                setSelectedWorkflow,
                                                setSelectedBlock,
                                                setSelectedSequenceIndex,
                                                currentData,
                                              );
                                              window.open(`https://app.qawolf.com/runs/${run.runId}/attempt/${run.attempts[0].id}`, '_blank');
                                            }}
                                          >
                                            {run.workflow.name}
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  <div>
                                    <h4 className="text-green-400 font-medium mb-1">Passed Runs ({blockReferences.passed.length})</h4>
                                    <div className="space-y-1">
                                      {blockReferences.passed.map((run) => (
                                        <div key={`${run.workflow.id}-${run.runId}`} className="text-sm text-gray-300 hover:text-white">
                                          <a
                                            href={`https://app.qawolf.com/runs/${run.runId}/attempt/${run.attempts[0].id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="hover:text-white"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              handleWorkflowSelection(
                                                cyRef.current,
                                                run,
                                                selectedWorkflow,
                                                setSelectedWorkflow,
                                                setSelectedBlock,
                                                setSelectedSequenceIndex,
                                                currentData,
                                              );
                                              window.open(`https://app.qawolf.com/runs/${run.runId}/attempt/${run.attempts[0].id}`, '_blank');
                                            }}
                                          >
                                            {run.workflow.name}
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <div className="text-gray-400 text-center py-8">No building blocks found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
