import { useQuery } from '@tanstack/react-query';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { getTimeUtilizationInsights } from '../timeUtlilzationHelpers';
import { ActivityChart, TotalActivityPieChart } from '../charts';
import { TotalActivityTable } from '../tables';
import { QaeUtilizationCard } from '../cards';
import Box from '@mui/material/Box';
import { QaeUtilizationFilter, TeamUtilizationFilter } from '../timeUtilizationTypes';

export default function UtilizationView({ filters }: { filters: QaeUtilizationFilter | TeamUtilizationFilter }) {
  const {
    data: utilizationData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['utilization', filters],
    queryFn: () => getTimeUtilizationInsights(filters),
  });

  if (isLoading) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  if (!utilizationData || !utilizationData.events.rawData.length) {
    return <div className="text-center mt-6">No events found</div>;
  }

  return (
    <>
      <ActivityChart events={utilizationData?.events} />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <TotalActivityPieChart events={utilizationData?.events} />
        <TotalActivityTable events={utilizationData?.events} />
      </Box>
      <QaeUtilizationCard events={utilizationData?.events} data={utilizationData} />
    </>
  );
}
