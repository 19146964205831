import { useEffect, useState } from 'react';

import { QaeTimeUtilizationData, TeamTimeUtilizationData, UtilizationEvents, UtilizationTableData } from '../timeUtilizationTypes';

import QaeUtilizationSection from './QaeUtilizationSection';

export default function QaeUtilizationCard({ data, events }: { data: QaeTimeUtilizationData | TeamTimeUtilizationData; events: UtilizationEvents }) {
  const [tableData, setTableData] = useState<UtilizationTableData[]>([]);

  useEffect(() => {
    // If it's on the QAE tab, reshape the data to include the qae name
    // since the team and qae data has different properties
    // (QAE has a .qae property, Team has a .eventsByQae property)
    if (data?.qae) {
      setTableData([[data.qae.name, events]]);
    } else if (events?.eventsByQae) {
      setTableData(Object.entries(events.eventsByQae));
    }
  }, [data, events]);

  return (
    <div className="rounded-lg bg-gray-50 m-2 py-2 px-4 shadow overflow-hidden">
      {tableData.map(([qae, events], index) => (
        <QaeUtilizationSection qae={qae} events={events} key={index} />
      ))}
    </div>
  );
}
