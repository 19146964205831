import pristineStrawberry from '../HiringUtils/hiringLoader.gif';

const HiringLoader = ({ customStyles = {}, customText = 'Loading...' }) => {
  return (
    <div className="wolf-loader-container" style={customStyles}>
      <img src={pristineStrawberry} alt="Loading" className="wolf-loader" />
      <p>{customText}</p>
    </div>
  );
};

export default HiringLoader;
