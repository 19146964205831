import { useState, useRef } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '../../ui/button';
import { Command, CommandEmpty, CommandList, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { QATeam } from '../data/types';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { SavedViewState } from '../data/types';
import { useCommunications } from '../../context/CommunicationsContext';
import { Badge } from '@/components/ui/badge';

interface QaTeamDropdownProps {
  onSelectQaTeam: (qaTeamId: number) => void;
  selectedQaTeamId: number | null;
  qaTeams: QATeam[];
}

export function QaTeamDropdown({ onSelectQaTeam, selectedQaTeamId, qaTeams }: QaTeamDropdownProps) {
  const { conversations } = useCommunications();

  const [open, setOpen] = useState(false);
  const preventTooltipRef = useRef(false);

  // Load saved view state from localStorage
  const savedViewState: SavedViewState | null = localStorage.getItem('savedViewState')
    ? JSON.parse(localStorage.getItem('savedViewState') as string)
    : null;

  const savedQaTeamId = savedViewState?.qaTeamId;
  const selectedQaTeam = qaTeams?.find((qaTeam) => qaTeam.id === Number(selectedQaTeamId));
  const locallySavedQaTeam = qaTeams?.find((qaTeam) => String(qaTeam.id) === String(savedQaTeamId));
  const currentQaTeam = selectedQaTeam || locallySavedQaTeam;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-full overflow-x-auto justify-between cursor-pointer h-14">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center min-w-0">
              <Avatar className="w-6 h-6 flex-shrink-0">
                <AvatarImage src={selectedQaTeam?.imageUrl || ''} alt={selectedQaTeam?.name || ''} />
                <AvatarFallback>{selectedQaTeam?.name || ''}</AvatarFallback>
              </Avatar>
              <div className="ml-2 truncate">{currentQaTeam ? currentQaTeam.name : 'Select Team...'}</div>
            </div>
            <div className="flex items-center gap-2 ml-2">
              {conversations.filter((conv) => !conv.completed).length ? (
                <Badge variant="destructive">{conversations.filter((conv) => !conv.completed).length}</Badge>
              ) : null}
              <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <ScrollArea className="h-full">
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandInput placeholder="Search teams..." />
            <CommandEmpty>No team found.</CommandEmpty>

            <CommandList className="max-h-[75vh] min-w-full w-72 overflow-y-auto">
              {qaTeams
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((qaTeam: QATeam) => (
                  <CommandItem
                    className="min-w-full w-full p-0 cursor-pointer h-11"
                    key={qaTeam?.id}
                    onSelect={() => {
                      preventTooltipRef.current = true;
                      onSelectQaTeam(qaTeam?.id);
                      setOpen(false);
                    }}
                  >
                    <Check className={cn('mr-2 h-4 w-4', selectedQaTeamId === qaTeam.id ? 'opacity-100' : 'opacity-0')} />
                    <Avatar className="w-6 h-6 flex-shrink-0">
                      <AvatarImage src={qaTeam?.imageUrl || ''} alt={qaTeam?.name || ''} />
                      <AvatarFallback>{qaTeam?.name || ''}</AvatarFallback>
                    </Avatar>
                    {qaTeam?.name}
                  </CommandItem>
                ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </ScrollArea>
    </Popover>
  );
}
