import type React from 'react';
import { useState, useCallback } from 'react';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ChevronDown, ChevronUp, ExternalLink, Bug, CheckCircle, AlertTriangle } from 'lucide-react';
import type { Workflow } from '../types/investigation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';

interface BugReproductionCardProps {
  title: string;
  description: string;
  workflows: Workflow[];
  onOverrideBaseline: (workflowId: string) => void;
  onResolve: (workflowId: string) => void;
  onAllResolved: () => void;
}

export const BugReproductionCard: React.FC<BugReproductionCardProps> = ({
  title,
  description,
  workflows,
  onOverrideBaseline,
  onResolve,
  onAllResolved,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [localWorkflows, setLocalWorkflows] = useState(workflows);
  const [isPulsing, setIsPulsing] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | null>(null);
  const [isResolving, setIsResolving] = useState(false);

  const checkAllResolved = useCallback(() => {
    const allResolved = localWorkflows.every((workflow) => workflow.status === 'passed' || workflow.baselineOverride);
    if (allResolved && !isResolving) {
      setIsResolving(true);
      setIsPulsing(true);
      const timer = setTimeout(() => {
        setIsPulsing(false);
        onAllResolved();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [localWorkflows, isResolving, onAllResolved]);

  checkAllResolved();

  const handleOverrideBaseline = (workflowId: string) => {
    setLocalWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => (workflow.id === workflowId ? { ...workflow, baselineOverride: true, status: 'passed' } : workflow)),
    );
    onOverrideBaseline(workflowId);
    checkAllResolved();
  };

  const handleResolve = (workflowId: string) => {
    setLocalWorkflows((prevWorkflows) =>
      prevWorkflows.map((workflow) => (workflow.id === workflowId ? { ...workflow, status: 'passed' } : workflow)),
    );
    onResolve(workflowId);
    checkAllResolved();
  };

  return (
    <Card className={`mb-2 ${isPulsing ? 'animate-pulse' : ''}`}>
      <CardHeader className="py-3 px-4">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm font-medium flex items-center">
            {(isResolving || isPulsing) && <CheckCircle className="w-4 h-4 text-green-500 mr-2" />}
            {title}
          </CardTitle>
          <Button variant="ghost" size="sm" onClick={() => setIsExpanded(!isExpanded)} className="h-6 px-1">
            {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </Button>
        </div>
      </CardHeader>
      {isExpanded && (
        <CardContent className="py-1 px-3">
          <p className="text-xs text-gray-600 mb-1">{description}</p>
          <div className="space-y-0.5">
            {localWorkflows.map((workflow) => (
              <div key={workflow.id} className="flex items-center justify-between py-0.5 text-xs">
                <div className="flex items-center space-x-1.5">
                  <div className="rounded-full p-0.5 bg-red-200">
                    {workflow.status === 'passed' ? (
                      <CheckCircle className="w-3 h-3 text-green-600" />
                    ) : workflow.baselineOverride ? (
                      <AlertTriangle className="w-3 h-3 text-orange-600" />
                    ) : (
                      <Bug className="w-3 h-3 text-gray-800" />
                    )}
                  </div>
                  <span className="font-medium truncate max-w-[120px]">{workflow.name}</span>
                  <a href={workflow.failureLink} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                    <ExternalLink size={10} />
                  </a>
                </div>
                {workflow.status !== 'passed' && !workflow.baselineOverride ? (
                  <div className="flex space-x-2">
                    <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
                      <AlertDialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="h-5 px-1 py-0 text-xs"
                          onClick={() => {
                            setSelectedWorkflowId(workflow.id);
                            setIsAlertOpen(true);
                          }}
                        >
                          Override Baseline
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                          <AlertDialogDescription>
                            This action will override the baseline for this workflow. It cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => {
                              if (selectedWorkflowId) {
                                handleOverrideBaseline(selectedWorkflowId);
                              }
                              setIsAlertOpen(false);
                            }}
                          >
                            Continue
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                    <Button variant="outline" size="sm" className="h-5 px-1 py-0 text-xs" onClick={() => handleResolve(workflow.id)}>
                      Resolved
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center text-green-600">
                    <CheckCircle className="w-4 h-4 mr-1" />
                    <span className="text-xs">{workflow.baselineOverride ? 'Baseline Overridden' : 'Resolved'}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      )}
    </Card>
  );
};
