import { FormEvent, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { QAWTask } from '../../types';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { sendGetRequest, sendPutRequest } from '../../utils/tanstackNetwork';
import { Edit, Preview, CloseOutlined } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import { User } from '../Planning/Summary/types';
import dayjs from 'dayjs';

interface TaskBlocker {
  id: number;
  title: string;
  description: string;
  creatorId: string;
  nextFollowUpDate?: string;
  customerId: string;
}

interface RequestBody extends Omit<TaskBlocker, 'nextFollowUpDate'> {
  nextFollowUpDate: Date;
}

export default function BlockerEditorModal({
  blocker,
  open,
  setOpen,
}: {
  blocker: TaskBlocker | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const { data: filterValues } = useQuery({ queryKey: ['filterValues'], queryFn: () => sendGetRequest(`/filter-values`) });
  const users: User[] = filterValues?.users || [];

  const [title, setTitle] = useState(blocker?.title || '');
  const [blockerReason, setBlockerReason] = useState(blocker?.description || '');
  const [isPreview, setIsPreview] = useState(false);
  const [creatorId, setCreatorId] = useState(blocker?.creatorId || '');
  const [nextFollowUpDate, setNextFollowUpDate] = useState(dayjs(blocker?.nextFollowUpDate).format('YYYY-MM-DD'));

  const [reasonError, setReasonError] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const blockerMutation = useMutation({
    mutationKey: ['updateBlocker'],
    mutationFn: async (blockerData: RequestBody) => sendPutRequest(`/update-blocker`, blockerData),
    onSuccess: (updatedBlocker: TaskBlocker) => {
      setSnackbarMessage('Blocker updated successfully');
      setShowSnackbar(true);

      // Update the task cache (account for both main tasks page and planning hub page)
      queryClient.setQueryData(['allTasks'], (oldData: QAWTask[]) => updateCacheCallback(oldData, updatedBlocker));
      queryClient.setQueryData(['allTasks', updatedBlocker.customerId], (oldData: QAWTask[]) => updateCacheCallback(oldData, updatedBlocker));
    },
    onError: () => {
      setSnackbarMessage('Failed to update blocker');
      setShowSnackbar(true);
    },
  });

  const handleUpdateBlocker = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!blocker) return;

    if (!blockerReason) return setReasonError(true);
    blockerMutation.mutate({
      ...blocker,
      title: title,
      description: blockerReason,
      creatorId: creatorId,
      nextFollowUpDate: dayjs(nextFollowUpDate).toDate(),
    });
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper
          sx={{
            p: 3,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <form
            onSubmit={handleUpdateBlocker}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormControl size="small" fullWidth required>
                <TextField size="small" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
              </FormControl>
            </Box>

            <Box sx={{ position: 'relative' }}>
              <Tooltip title={isPreview ? 'Edit' : 'Preview'}>
                <span
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                >
                  <IconButton size="small" onClick={() => setIsPreview(!isPreview)} disableRipple>
                    {isPreview ? <Edit sx={{ height: 22, width: 22 }} /> : <Preview sx={{ height: 22, width: 22 }} />}
                  </IconButton>
                </span>
              </Tooltip>
              <MDEditor
                value={blockerReason}
                onChange={(val) => {
                  setBlockerReason(val || '');
                }}
                preview={isPreview ? 'preview' : 'edit'}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                  components: {
                    // Makes links open in new tab and allow Slack app communication
                    a: ({ children, ...props }) => (
                      <a {...props} target="_blank" rel="opener">
                        {children}
                      </a>
                    ),
                  },
                }}
                height={200}
                data-color-mode="light"
                textareaProps={{
                  placeholder: 'Enter detailed reason for blocked status',
                }}
                style={{
                  boxSizing: 'border-box',
                  outline: reasonError ? '1px solid red' : 'none',
                }}
                hideToolbar={isPreview}
              />
            </Box>
            <FormControl size="small" fullWidth required>
              <TextField
                label="Next Follow-up Date"
                size="small"
                type="date"
                fullWidth
                required
                value={nextFollowUpDate}
                onChange={(e) => {
                  setNextFollowUpDate(e.target.value);
                }}
                inputProps={{
                  min: dayjs().format('YYYY-MM-DD'),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl size="small" fullWidth required>
              <InputLabel id="assignee-label">Assignee</InputLabel>
              <Select
                size="small"
                label="Assignee"
                value={creatorId}
                onChange={(event) => setCreatorId(event.target.value)}
                labelId="assignee-label"
                renderValue={(selected) => {
                  const selectedUser = users.find((user) => user.qawId === selected);
                  return <Chip size="small" label={selectedUser?.name} avatar={<Avatar src={selectedUser?.avatar48} />} />;
                }}
                required
              >
                {users.map((user) => (
                  <MenuItem key={user.qawId} value={user.qawId}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar src={user.avatar48} />
                      <Typography variant="body1">{user.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" disableRipple disabled={!blockerReason || blockerMutation.isPending}>
              {blockerMutation.isPending ? 'Updating...' : 'Update Blocker'}
            </Button>
          </form>
          <Snackbar
            open={showSnackbar}
            message={snackbarMessage}
            onClose={() => setShowSnackbar(false)}
            ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)} disableRipple>
                <CloseOutlined fontSize="small" />
              </IconButton>
            }
          />
        </Paper>
      </Modal>
    </div>
  );
}

function updateCacheCallback(oldData: QAWTask[] | undefined, updatedBlocker: TaskBlocker) {
  // Don't update cache if oldData is undefined
  if (!oldData) return oldData;

  return oldData.map((cachedTask: QAWTask) => {
    // Skip tasks that don't have matching blockers
    if (!cachedTask.blocker?.id && !cachedTask.childTasks?.some((t) => t.blocker?.id === updatedBlocker?.id)) {
      return cachedTask;
    }

    return {
      ...cachedTask,
      childTasks: cachedTask.childTasks?.map((childTask) => {
        const oldBlocker = childTask.blocker;
        if (!oldBlocker) return childTask;
        return { ...childTask, blocker: { ...oldBlocker, ...updatedBlocker } };
      }),
    };
  });
}
