import { Box, MenuItem, Button, Dialog, DialogContent, DialogTitle, ListItemIcon, Typography, CircularProgress, lighten, Checkbox } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_GlobalFilterTextField } from "material-react-table";
import { useMemo, useState, useCallback, useEffect } from "react";
import ArchiveIcon from '@mui/icons-material/Archive'; // for some reason, importing from above errors

// Component and variable imports
import { statusMap } from '../HiringUtils/StatusConstants';
import EditableCell from '../HiringUtils/EditableCell';
import { useHandleCandidateInfoChange, useHandleSubmissionChange, handleArchiveSubmissions, highlightText } from '../HiringUtils/helperFunctions';
import { inviteExecsToFinalRoundTeam, initCandidateFinalRound } from '../HiringUtils/api';
import { useQueryClient } from '@tanstack/react-query';
import RenderFinalRoundDetailPanels from '../RenderDetailPanels/RenderFinalRoundDetailPanels';
import Toast from '../../Layout/Toast';
import { debounce } from 'lodash';

// Date and time picker imports
import dayjs from 'dayjs';

function FinalRoundTable({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedFinalRoundRowData, setSelectedFinalRoundRowData] = useState({});
  const [isLoadingSuperday, setIsLoadingSuperday] = useState(false);
  const [toastData, setToastData] = useState(null);
  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.allSubmissions.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );

  // Update the state whenever data changes.
  useEffect(() => {
    setValue(
      data.allSubmissions.reduce((acc, row) => {
        acc[row.id] = row || '';
        return acc;
      }, {}),
    );
  }, [data]);
  const tableData = useMemo(() => Object.values(value), [value]);


  // Convert the value object to an array for the table.
  const handleCandidateInfoChange = useHandleCandidateInfoChange(setValue);
  const handleSubmissionChange = useHandleSubmissionChange(setValue);


  const debouncedHandleChange = useCallback(
    debounce((submissionData) => {
      handleSubmissionChange(submissionData);
    }, 300), // Adjust the delay (in milliseconds) as needed
    [handleSubmissionChange],
  );

  const handleInitFinalRound = async (superday) => {
    setIsLoadingSuperday(true);

    const candidateData = {
      superday,
      candidateEmail: selectedFinalRoundRowData.original.candidate.email,
      candidateName: selectedFinalRoundRowData.original.candidate.firstName,
      submissionId: selectedFinalRoundRowData.original.id,
    };

    let result = await initCandidateFinalRound(candidateData);
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? 'Candidate superday created' : 'Something went wrong';

    setIsLoadingSuperday(false);
    setOpen(false);
    setToastData({
      title: title,
      message: message,
      isSuccess: success,
    });
  };

  const handleInviteExecToFinalRoundTeam = async (row) => {
    const {
      original: { finalRoundTeamId },
    } = row;

    let result = await inviteExecsToFinalRoundTeam({ teamId: finalRoundTeamId });
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? `Invitations to ${row.original.candidate.firstName}'s Final Round Team have been sent.` : 'Something went wrong!';

    setToastData({
      title: title,
      message: message,
      isSuccess: success,
    });
  };

  const columns = useMemo(() => {
    return [
      // ========================= CANDIDATE NAME =========================
      {
        id: 'name',
        header: 'Candidate Name',
        accessorKey: 'candidateName',
        accessorFn: (row) => {
          const firstName = value[row.id].candidate.firstName;
          const lastName = value[row.id].candidate.lastName;


          return `${firstName} ${lastName}`;
        },
        Cell: ({ cell, row }) => {
          const fullName = cell.getValue();
          const candidate = value[row.id].candidate;

          const globalFilter = table.getState().globalFilter;


          const handleSave = (newValue) => {
            const [newFirstName, newLastName] = newValue.split(/\s(.+)/);
            handleCandidateInfoChange({
              candidate: {
                id: candidate.id,
                firstName: newFirstName || '',
                lastName: newLastName || '',
              },
            });
          };

          return (
            <EditableCell
              initialValue={fullName}
              onSave={handleSave}
              placeholder="Candidate Name"
              globalFilter={globalFilter}
            />
          );
        },
      },
      // ========================= CANDIDATE EMAIL =========================
      {
        accessorFn: (row) => row?.candidate.email,
        id: 'email',
        header: 'Candidate Email',
        enableClickToCopy: true,
        Cell: ({ cell }) => {
          const initialValue = cell.getValue() || '';
          const globalFilter = table.getState().globalFilter;

          return (
            <p>{highlightText(initialValue, globalFilter)}</p>
          );
        },
      },
      // // ========================= INTERVIEW STATUS =========================
      {
        accessorFn: (row) => {
          const status = statusMap.find((s) => s.value === row.status);
          return status.label;
        },
        id: 'statusId',
        header: 'Status',

        Cell: ({ row }) => {
          const status = row.original.status;
          const submissionId = row.original.id;
          const matchedStatus = statusMap.find((s) => s.value === status);
          return (
            <>
              <select
                id="status"
                name="status"
                style={{
                  backgroundColor: matchedStatus?.color || 'blue',
                  color: matchedStatus?.textColor || 'white',
                  textAlign: 'center',
                  borderRadius: '20px',
                  padding: '5px',
                  width: '175px',
                  overflow: 'clip',
                  fontSize: '12px',
                }}
                value={status}
                onChange={(e) => handleSubmissionChange({
                  submissionId: submissionId,
                  newInfo: { status: e.target.value },
                  row,
                },


                )}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
      // // ========================= FINAL ROUND DATE =========================
      {
        accessorFn: (row) => ({
          submission: row,
          finalRoundDate: row.finalRoundDate,
        }),
        id: 'finalRoundDate',
        header: 'Final Round Date',
        Cell: ({ cell, row }) => {
          const { submission, finalRoundDate } = cell.getValue();
          const globalFilter = table.getState().globalFilter;


          const initialValue = finalRoundDate ? dayjs(finalRoundDate).format('MMM DD, YYYY h:mm A') : "Select a date";

          // Handler to save the updated date
          const handleSave = async (newValue) => {
            await handleCandidateInfoChange({
              candidate: {
                id: submission.candidateId,
                finalRoundCooldown: dayjs(newValue).add(12, 'month'),
              },
            });

            await handleSubmissionChange({
              submissionId: submission.id,
              newInfo: { finalRoundDate: newValue },
              row,
            });

          };

          return (
            <EditableCell
              initialValue={initialValue} // Initialize with the current date
              onSave={handleSave} // Save the new date when editing is finalized
              placeholder="Select a date"
              type="datepicker" // Enable the date picker
              globalFilter={globalFilter}
            />
          );
        },
        sortingFn: (a, b) => {
          const dateA = a.original.finalRoundDate ? new Date(a.original.finalRoundDate) : new Date(0);
          const dateB = b.original.finalRoundDate ? new Date(b.original.finalRoundDate) : new Date(0);
          return dateA - dateB;
        },
      },
      // // ========================= CANDIDATE LOCATION =========================
      {
        accessorFn: (row) => (row.candidate.location ? row.candidate.location : 'N/A'),
        id: 'location',
        header: 'Location',
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.location || '';
          const globalFilter = table.getState().globalFilter;

          // Handle saving the new location
          const handleSave = (newValue) => {
            handleCandidateInfoChange({
              candidate: {
                id: candidate.id,
                location: newValue,
              },
            });
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Location"
              globalFilter={globalFilter}
            />
          );
        },
      },
      // // ========================= FINAL ROUND SANDBOX =========================
      {
        header: 'Sandbox',
        accessorKey: 'removedFromSandbox',
        size: 50, 
        Cell: ({ row }) => {
          const globalFilter = table.getState().globalFilter;

          const handleSave = (newValue) => {
            handleSubmissionChange({
              submissionId: row.id,
              newInfo: { sandbox: newValue },
              row,
            });
          };

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox checked={value[row.id].removedFromSandbox} onChange={() => {
                debouncedHandleChange({
                  submissionId: row.id,
                  newInfo: { removedFromSandbox: !row.original.removedFromSandbox },
                  row,
                });
              }} />

              {
                !value[row.id].removedFromSandbox &&

                <EditableCell
                  initialValue={value[row.id].sandbox === null ? "" : value[row.id].sandbox}
                  onSave={handleSave}
                  placeholder="Enter Sandbox ID"
                  globalFilter={globalFilter}
                />
              }

            </Box>
          );
        },
      },
      {
        header: 'Final Round Team',
        accessorKey: 'finalRoundTeam',
        Cell: ({ cell, row }) => {
          const globalFilter = table.getState().globalFilter;

          const cellData = cell.getValue();

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {cellData && (
                <a
                  href={`https://app.qawolf.com/${cellData}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#6366f1',
                  }}
                >

                  {highlightText(`${row.original.candidate.firstName}'s Superday`, globalFilter)}
                </a>
              )}
            </Box>
          );
        },
      },
    ];
  }, [tableData, handleCandidateInfoChange, handleSubmissionChange]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50, //adjust the size of the row select column
        grow: false, 
      },
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      sorting: [{ id: 'finalRoundDate', desc: false }],
      showGlobalFilter: true,
    },
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableRowActions: true,
    autoResetPageIndex: false,
    enableExpanding: true,
    enableRowSelection: true,
    getRowId: (row) => row.id,

    renderBottomToolbarCustomActions: ({ table }) => {
      // Calculate total rows
      const rowCount = table.getRowModel().rows.length;
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {selectedFinalRoundRowData ? (
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <DialogTitle>Which Superday for {selectedFinalRoundRowData?.original?.candidate.firstName}</DialogTitle>
              <DialogContent>
                <div className="flex w-full justify-center align-center">
                  <Button onClick={() => handleInitFinalRound('superday1')}>Superday 1</Button>
                  <Button onClick={() => handleInitFinalRound('superday2')}>Superday 2</Button>
                  <Button onClick={() => handleInitFinalRound('superday3')}>Superday 3</Button>
                </div>

                {isLoadingSuperday && (
                  <div className="flex w-full justify-center align-center">
                    <CircularProgress />
                  </div>
                )}
              </DialogContent>
            </Dialog>
          ) : null}
          <Typography variant="subtitle1">Total Rows: {rowCount}</Typography>
        </Box>
      );
    },

    muiTableBodyCellProps: () => ({
      sx: {
        padding: '12px 6px', 
      },
    }),
    
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: useCallback(({ row }) => (
      <RenderFinalRoundDetailPanels row={row} data={data} />
    ), [data]),

    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}

            {/* ==================HEADER SEARCH BAR================== */}
            <MRT_GlobalFilterTextField table={table} />

            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            {/* ============HEADER TOGGLE FILTERS BUTTON============= */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}
            <button
              disabled={!table.getIsSomeRowsSelected()}
              style={{
                cursor: table.getIsSomeRowsSelected() ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                handleArchiveSubmissions(table.getSelectedRowModel().rows, table, setToastData, queryClient);
              }}
            >
              <ArchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>

          </Box>
        </Box>
      );
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        sx={{ m: 0 }}
        onClick={() => {
          handleInviteExecToFinalRoundTeam(row);
          closeMenu();
        }}
      >
        Invite Eric/Laura to Final Round Team
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setOpen(true);
          setSelectedFinalRoundRowData(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Init Final Round
      </MenuItem>,
    ],
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <Toast {...toastData} show={toastData !== null} onDone={() => setToastData(null)} />
    </div>
  );
}

export default FinalRoundTable;
