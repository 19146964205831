import { useState } from 'react';
import { UtilizationTable } from '../tables';
import { UtilizationEvents } from '../timeUtilizationTypes';

export default function QaeUtilizationSection({ qae, events }: { qae: string; events: UtilizationEvents }) {
  const [showBreakdown, setShowBreakdown] = useState(false);

  const handleShowBreakdown = () => {
    setShowBreakdown((prev) => !prev);
  };

  return (
    <div className="min-h-full px-1/2">
      <div className="flex rounded-md bg-white border justify-around items-center p-2 my-2">
        <div className="flex flex-col border rounded-md w-full px-4">
          <div className="flex items-center w-full my-1">
            <div className="flex flex-1 pl-1 items-center justify-start">
              <p className="pl-2 flex text-base font-semibold leading-6 text-gray-900 justify-start w-4/6">{qae}</p>
            </div>
            <div className="qae__button__container flex space-x-1 flex-1 justify-end">
              <button
                className="border bg-gray-50 shadow p-1 m-2 rounded-md flex justify-center items-center h-10 w-1/2"
                onClick={handleShowBreakdown}
              >
                {`${showBreakdown ? 'Hide' : 'Show'} Breakdown`}
              </button>
            </div>
          </div>
          {showBreakdown && (
            <div className="border bg-gray-50 rounded-md mb-2 overflow-auto max-h-[400px]">
              <UtilizationTable events={events} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
